<template>
  <v-container>
    <v-row>
      <v-col cols="12" style="margin-top: -80px"></v-col>
    </v-row>
    <v-row style="margin-top: -330px !important">
      <v-col cols="12">
        <v-img
          height="81.98"
          width="81.98"
          class="mx-auto"
          :src="this.cardsPremium.src"
        ></v-img>
      </v-col>

      <v-col
        cols="12"
        style="text-align: center; padding-top: 0; margin-top: -30px"
      >
        <h2
          style="
            color: #242424;
            font-family: 'Poppins', Sans-serif;
            font-size: 20px;
            font-weight: 600;
            line-height: 1em;
            letter-spacing: -0.7px;
            margin-top: 20px;
            margin-bottom: 10px;
          "
        >
          {{ this.cardsPremium.titulo }}
        </h2>
        <h2
          style="
            color: #525252;
            font-family: 'Raleway', Sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 1em;
            letter-spacing: 0px;
          "
        >
          {{ this.cardsPremium.subtitulo }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        style="background-color: #f3f3f3; height: 150px; margin-top: 12px"
      ></v-col>
      <v-col cols="12" style="margin-top: -150px">
        <v-flex>
          <v-carousel
            hide-delimiters
            style="box-shadow: 0px 0px"
            class="csscardsPremiumSM"
          >
            <v-carousel-item
              v-for="i in Math.ceil(cardsPremium.items.length / 2)"
              :key="i"
            >
              <v-layout row>
                <v-flex sm6 pl-2 pr-2>
                  <Cards1
                    v-if="i * 2 - 2 < cardsPremium.items.length"
                    :path="cardsPremium.items[i * 2 - 2].src"
                    :nombre="cardsPremium.items[i * 2 - 2].nombre"
                    :area="cardsPremium.items[i * 2 - 2].area"
                    :especialista="cardsPremium.items[i * 2 - 2].especialista"
                    :sesiones="cardsPremium.items[i * 2 - 2].sesiones"
                    :minutos="cardsPremium.items[i * 2 - 2].minutos"
                    :link="cardsPremium.items[i * 2 - 2].link"
                    :target="cardsPremium.items[i * 2 - 2].target"
                    :interna="cardsPremium.items[i * 2 - 2].interna"
                    :boton="cardsPremium.items[i * 2 - 2].boton"
                  ></Cards1>
                </v-flex>
                <v-flex sm6 pl-2 pr-2>
                  <Cards1
                    v-if="i * 2 - 1 < cardsPremium.items.length"
                    :path="cardsPremium.items[i * 2 - 1].src"
                    :nombre="cardsPremium.items[i * 2 - 1].nombre"
                    :area="cardsPremium.items[i * 2 - 1].area"
                    :especialista="cardsPremium.items[i * 2 - 1].especialista"
                    :sesiones="cardsPremium.items[i * 2 - 1].sesiones"
                    :minutos="cardsPremium.items[i * 2 - 1].minutos"
                    :link="cardsPremium.items[i * 2 - 1].link"
                    :target="cardsPremium.items[i * 2 - 1].target"
                    :interna="cardsPremium.items[i * 2 - 1].interna"
                    :boton="cardsPremium.items[i * 2 - 1].boton"
                  ></Cards1>
                </v-flex>
              </v-layout>
            </v-carousel-item>
          </v-carousel>
        </v-flex>
        <!-- <p
          style="
            margin-top: -60px;
            text-align: right;
            margin-right: 30%;
            color: #e6deb5;
            font-family: 'Poppins', Sans-serif;
            font-size: 18px;
            font-weight: 600;
            line-height: 1.2em;
            letter-spacing: -0.7px;
          "
        >
          {{ cardsPremium.cursosVistos }} / {{ cardsPremium.totCursos }}
        </p>
        <v-btn
          @click="btnCaruselPremium_click"
          style="margin-top: -100px !important; margin-left: 10px !important"
          class="btnPremiumSM"
          >{{ this.cardsPremium.boton }}</v-btn
        > -->
      </v-col>
    </v-row>
  </v-container>
</template>
<style>
.btnPremiumSM {
  font-family: "Raleway", Sans-serif !important;
  font-size: 18px !important;
  font-weight: 800 !important;
  letter-spacing: 0px !important;
  fill: #323232 !important;
  color: #323232 !important;
  background-color: #e6deb5 !important;
  border-radius: 5px 5px 5px 5px !important;
  padding: 20px 40px 17px 40px !important;
  width: 328.91px !important;
  height: 55px !important;
}
.v-autocomplete.v-input > .v-input__control > .v-input__slot {
  cursor: text;
  background-color: white;
}
.csscardsPremiumSM .v-window__prev {
  margin-top: 190px !important;
  margin-left: 75% !important;
}
.csscardsPremiumSM .v-window__next {
  margin-top: 190px !important;
  margin-right: 8% !important;
}
.csscardsPremiumSM .v-window__prev .v-btn {
  border-radius: 5px 5px 5px 5px;
  width: 53px !important;
  height: 53px !important;
  background-color: #272429 !important;
  border: solid #e6deb5 !important;
  color: #e6deb5 !important;
}
.csscardsPremiumSM .v-window__next .v-btn {
  border-radius: 5px 5px 5px 5px;
  width: 53px !important;
  height: 53px !important;
  background-color: #272429 !important;
  border: solid #e6deb5 !important;
  color: #e6deb5 !important;
}
.csscardsPremiumSM .v-window__next .v-btn span i{
	color:#e6deb5 !important;
	font-size: 36px !important;	  
}
.csscardsPremiumSM .v-window__prev .v-btn span i{
	color:#e6deb5 !important;
	font-size: 36px !important;	  
}
.csscardsPremiumSM .v-responsive__content .layout {
  padding-right: 35px !important;
  padding-left: 12px !important;
}
.csscardsPremiumSM .v-image{
	padding-top: 20px;
}
</style>
<script>
import Cards1 from "./CardsCarruselPremium.vue";
export default {
  data() {
    return {
      indice: 0,
      select: ["Fecha"],
      itemscombo: ["Fecha", "Minutos reproducidos"],
      cardsPremium: {
        src: "http://niufleex.com/wp-content/uploads/2021/04/isotipo.svg",
        titulo: "Últimos Cursos lanzados. Membresía PREMIUM",
        subtitulo:
          "Especialistas, Mentores y guías. Conoce a quienes te harán subir al próximo nivel.",
        link: "https://niufleex.com/",
        target: "_blank",
        interna: "0",
        boton: "Cursos Membresía PREMIUM",
        totCursos: 8,
        cursosVistos: 1,
        items:[]
      },
      itemsExample: [
          {
            src: "https://niufleex.com/wp-content/uploads/2022/01/foto-perfil.jpg",
            estudiantes: "1.456",
            puntuacion: "4.7",
            nombre: "CURSO 10 Cosas a la vez y no perder energía",
            area: "Area de Conocimiento",
            especialista: "Valentina Góngora",
            sesiones: "2",
            minutos: "137",
            link: "https://niufleex.com/",
            target: "_blank",
            interna: 0,
            boton: "¿qué vas a aprender?",
          },
          {
            src: "https://niufleex.com/wp-content/uploads/2021/07/foto-guevara-2.jpg",
            estudiantes: "2.584",
            puntuacion: "3.8",
            nombre: "CURSO Network Marketing para Networkers",
            area: "Area de Conocimiento",
            especialista: "Valentina Góngora",
            sesiones: "2",
            minutos: "137",
            link: "https://niufleex.com/",
            target: "_blank",
            interna: 0,
            boton: "¿qué vas a aprender?",
          },
          {
            src: "https://niufleex.com/wp-content/uploads/2022/01/Juanjo05.jpg",
            estudiantes: "842",
            puntuacion: "4.9",
            nombre: "CURSO Fumarse una cola sin quemarse los dedis",
            area: "Area de Conocimiento",
            especialista: "Valentina Góngora",
            sesiones: "2",
            minutos: "137",
            link: "https://niufleex.com/",
            target: "_blank",
            interna: 0,
            boton: "¿qué vas a aprender?",
          },
          {
            src: "https://niufleex.com/wp-content/uploads/2022/01/foto-perfil.jpg",
            estudiantes: "1.456",
            puntuacion: "4.7",
            nombre: "CURSO 10 Cosas a la vez y no perder energía 2",
            resenia: "La Neta del Planeta es la meta",
            especialista: "Valentina Góngora",
            sesiones: "2",
            minutos: "137",
            link: "https://niufleex.com/",
            target: "_blank",
            interna: 0,
            boton: "¿qué vas a aprender?",
          },
          {
            src: "https://niufleex.com/wp-content/uploads/2021/07/foto-guevara-2.jpg",
            estudiantes: "2.584",
            puntuacion: "3.8",
            nombre: "CURSO Network Marketing para Networkers 2",
            resenia: "El Arte de dejar los vistos",
            especialista: "Valentina Góngora",
            sesiones: "2",
            minutos: "137",
            link: "https://niufleex.com/",
            target: "_blank",
            interna: 0,
            boton: "¿qué vas a aprender?",
          },
          {
            src: "https://niufleex.com/wp-content/uploads/2022/01/Juanjo05.jpg",
            estudiantes: "2.584",
            puntuacion: "3.8",
            nombre: "CURSO Fumarse una cola sin quemarse los dedis 2",
            resenia: "El Arte de dejar los vistos",
            especialista: "Valentina Góngora",
            sesiones: "2",
            minutos: "137",
            link: "https://niufleex.com/",
            target: "_blank",
            interna: 0,
            boton: "¿qué vas a aprender?",
          },
          {
            src: "https://niufleex.com/wp-content/uploads/2022/01/foto-perfil.jpg",
            estudiantes: "2.584",
            puntuacion: "3.8",
            nombre: "MÓDULO 23",
            resenia: "El Arte de dejar los vistos",
            especialista: "Valentina Góngora",
            sesiones: "2",
            minutos: "137",
            link: "https://niufleex.com/",
            target: "_blank",
            interna: 0,
            boton: "¿qué vas a aprender?",
          },
        ],
    };
  },
  components: {
    Cards1,
  },
  async mounted() {
		try {
			this.cardsPremium = {
				...this.cardsPremium,
				...(await this.$http.get("/listacursos/ultimoscursos")).data[0],
			};
 
			// eslint-disable-next-line no-empty
		} catch (error) {}
		// eslint-disable-next-line no-console
		console.log(this.cardsPremium);
	},
  methods: {
    contador(i) {
      if (this.indice < i) return parseInt(this.indice++);
      else return this.indice;
    },
    btnCaruselPremium_click() {
      let routeData = this.$router.resolve({
        path: this.cardsPremium.link,
      });
      if (this.cardsPremium.interna == 1) {
        window.open(routeData.href, this.cardsPremium.target);
      } else {
        window.open(this.cardsPremium.link, this.cardsPremium.target);
      }
    },
  },
};
</script>