<template>
  <v-container style="padding-top: 0; padding-bottom: 0; height: 425.34px">
    <v-row style="padding-left: 15px; padding-right: 40px">
      <v-col
        cols="12"
        style="
          background-color: transparent;
          background-image: linear-gradient(150deg, #272429 10%, #2e272e 56%);
          border-radius: 15px;
          padding: 0px 0px 0px 80px;
          height: 425.34px;
        "
      >
        <v-row style="height: 425.33px; margin-top:0; margin-bottom:0px;">
          <v-col cols="7">
            <img
              src="https://niufleex.com/wp-content/uploads/2021/04/isotipo.svg"
              style="margin-top: 35px; width: 63.97px; height: 63.97px"
              alt=""
            />
            <h2
              style="
                color: #e6deb5;
                font-family: Raleway, Sans-serif;
                font-size: 32px;
                font-weight: 800;
                line-height: 1.1em;
                letter-spacing: -0.7px;
                margin-top: 20px;
              "
            >
              {{ niufleexPremium.titulo }}
            </h2>
            <h2
              style="
                color: #ffffff;
                font-family: Raleway, Sans-serif;
                font-size: 18px;
                font-weight: 500;
                line-height: 1em;
                letter-spacing: 0px;
                margin-bottom: 20px;
                margin-top: 20px;
              "
            >
              {{ niufleexPremium.subtitulo }}
            </h2>
            <v-btn
              v-if="niufleexPremium.boton"
              class="btniraniutfleex"
              @click="btnPremium_click"
              >{{ niufleexPremium.boton }}</v-btn
            >
          </v-col>
          <v-col cols="5" class="bannerPremium" style="height: 425.33px; padding-top: 0;">
            <img class="" :src="niufleexPremium.src" style="height: 425.33px;"/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="scss" scoped>
.bannerPremium img {
    background-size: contain !important;
    height: 92% !important;
    margin-top: 44px !important;
}
.btniraniutfleex {
  font-family: "Raleway", Sans-serif;
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 0px;
  fill: #323232 !important;
  color: #323232 !important;
  background-color: #e6deb5 !important;
  border-radius: 5px 5px 5px 5px !important;
  padding: 20px 40px 17px 40px !important;
  height: auto !important;
}
.btniraniutfleex:hover {
  color: #ffffff !important;
  background-color: #8208b1 !important;
}
</style>
<script>
export default {
  data() {
    return {
      niufleexPremium: {
        src: "http://niufleex.com/wp-content/uploads/2021/11/sergio-p.png",
        titulo:
          "¡No dejes pasar la oportunidad y aprende de los que HACEN y saben hacer!",
        subtitulo:
          " ",
        boton: "Consigue tu membresía Premium",
        link: "https://backoffice.teomacorp.com/login?ReturnUrl=%2f",
        target: "_blank",
        interna: 0,
      },
      niufleexPremiumExample: {
        src: "http://niufleex.com/wp-content/uploads/2021/11/sergio-p.png",
        titulo:
          "¡No dejes pasar la oportunidad y aprende de los que HACEN y saben hacer!",
        subtitulo:
          "Bajada de texto al titular que empuje la compra. Bajada de texto al titular que empuje la compra.",
        boton: "¡IR A NIUFLEEX PREMIUM!",
        link: "https://niufleex.com",
        target: "_blank",
        interna: 0,
      },
    };
  },
  methods: {
    btnPremium_click() {
      let routeData = this.$router.resolve({
        path: this.niufleexPremium.link,
      });
      if (this.niufleexPremium.interna == 1) {
        window.open(routeData.href, this.niufleexPremium.target);
      } else {
        window.open(this.niufleexPremium.link, this.niufleexPremium.target);
      }
    },
  },
};
</script>