<template>
	<v-card
		class="mx-auto carddisponibles rounded"
		max-width="400"
		style="
			border-radius: 0px;
			width: 100%;
			max-width: 100%;
			min-height: 404px;
			height: 404px;
			box-shadow: 0px 4px 12px 0px rgb(0 0 0 / 12%);
			padding: 12px 0;
			margin-left: 5px !important;
		"
	>
		<v-img class="white--text align-end rounded-t" height="156px" :src="path">
			<!-- <v-card-title style="padding-left: 0; padding-top: 0">
				<v-card-title style="padding-left: 0; z-index: 2; padding-top: 0">
					<v-chip class="ma-2 chipcontinuar" label> CURSO </v-chip>
				</v-card-title>
			</v-card-title> -->
			<v-card-title style="padding-bottom: 5px; margin-top: 50px">
				<v-row>
					<v-col cols="9" style="padding-bottom: 0; padding-top: 30px">
						<v-icon v-if="estudiantes" left dark class="cardiconcr">
							mdi-account-multiple
						</v-icon>
						<span v-if="estudiantes" class="cardiconcr"
							>{{ estudiantes }} estudiantes</span
						>
					</v-col>
					<v-col
						cols="3"
						style="padding-bottom: 0; padding: 0; padding-top: 30px"
					>
						<v-icon v-if="puntuacion" left dark class="cardiconcr v-icon-left2">
							mdi-star
						</v-icon>
						<span v-if="puntuacion" class="cardiconcr">{{ puntuacion }}</span>
					</v-col>
				</v-row>
			</v-card-title>
		</v-img>

		<v-card-subtitle class="pb-0" style="margin-top: 0px"
			><h2 class="h2Cards ellipsis" style="color: #fb950c">{{ nombre }}</h2>
		</v-card-subtitle>
		<v-card-subtitle class="pb-0" style="padding-top: 0px; padding-bottom: 0px">
			<h2 class="h2Cardsresenia block-ellipsis">{{ resenia }}</h2>
		</v-card-subtitle>

		<v-card-text class="text--primary" style="position: absolute; ">
			<div style="margin: 0px 0px 0px 0px; padding: 10px 15px 0px 0px">
				<h2
					style="
						color: #525252;
						font-family: Raleway, Sans-serif;
						font-size: 15px;
						font-weight: 500;
						letter-spacing: 0px;
					"
				>
					Por: {{ especialista }}
				</h2>
			</div>

			<v-row style="margin-bottom: 20px !important">
				<v-col cols="12" style="padding-bottom: 0; padding-top: 30px">
					<v-icon
						v-if="sesiones"
						left
						light
						style="color: #525252; font-size: 15px;"
						class="fa fa-list-alt"
					>
					</v-icon>
					<span
						style="
							font-family: Poppins, Sans-serif;
							font-size: 15px;
							font-weight: 400;
							color: #525252;
							padding-left: 5px;
						"
						>{{ sesiones }} Sesiones</span
					>
					<v-icon
						style="color: #525252; font-size: 15px; margin-left: 20px"
						left
						light
						class="fa fa-stopwatch"
					>
					</v-icon>
					<span
						style="
							font-family: 'Poppins', Sans-serif;
							font-size: 14px;
							font-weight: 400;
							letter-spacing: 0.2px;
						"
						>{{ minutos }} min.</span
					>
				</v-col>
			</v-row>
		</v-card-text>
		<v-card-actions style="bottom: 0; position: absolute; width: 100%">
			<v-btn :to="link" text class="btnircurso">
				<span>{{ boton }}</span>
			</v-btn>
		</v-card-actions>
	</v-card>
</template>
<style>
.carddisponibles .v-responsive__content {
	margin-left: 0 !important;
	height: 203px !important;
}
.cardicon {
	font-family: "Raleway", sans-serif !important;
	font-size: 14px !important;
	font-weight: 600 !important;
	letter-spacing: 0.2px !important;
}
.v-icon-left2 {
	margin-right: 5px !important;
}
.h2Cards {
	color: #fb950c;
	font-family: "Poppins", Sans-serif;
	font-size: 17px;
	font-weight: 600;
	line-height: 1.2em;
	letter-spacing: -0.3px;
}
.h2Cardsresenia {
	color: #525252;
	font-family: "Poppins", Sans-serif;
	font-size: 17px;
	font-weight: 600;
	line-height: 1.2em;
	letter-spacing: -0.3px;
}
.btnircurso {
	font-family: "Raleway", Sans-serif !important;
	font-size: 17px !important;
	font-weight: 600 !important;
	letter-spacing: 0px !important;
	fill: #8208b1 !important;
	height: 60px !important;
	width: 100% !important;
	color: #8208b1 !important;
	background-color: #ffffff !important;
	border-style: solid !important;
	border-width: 3px 3px 3px 3px !important;
	border-color: #8208b1 !important;
	border-radius: 3px 3px 3px 3px !important;
	padding: 10px 15px 7px 15px !important;
	width: 100%;
	transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s,
		-webkit-box-shadow 0.3s;
	display: inline-block;
	line-height: 0;
}
.btnircurso > span {
	font-family: "Raleway", Sans-serif;
	font-size: 17px;
	font-weight: 600;
	letter-spacing: 0px;
	fill: #8208b1;
	line-height: 2;
	color: #8208b1;
	fill: #ffffff !important;
}
.btnircurso:hover {
	color: #ffffff !important;
	background-color: #8208b1 !important;
	border-color: #8208b1 !important;
}
.btnircurso:hover > span {
	color: #ffffff !important;
}
</style>
<script>
import { mapState } from "vuex";
export default {
	computed: {
		...mapState(["premium"]),
	},
	props: {
		path: {
			type: [Number, String],
			default: "",
		},
		estudiantes: {
			type: [Number, String],
			default: "",
		},
		puntuacion: {
			type: [Number, String],
			default: "",
		},
		nombre: {
			type: [Number, String],
			default: "",
		},
		resenia: {
			type: [Number, String],
			default: "",
		},
		especialista: {
			type: [Number, String],
			default: "",
		},
		sesiones: {
			type: [Number, String],
			default: "",
		},
		minutos: {
			type: [Number, String],
			default: "",
		},
		titulo: {
			type: [Number, String],
			default: "",
		},
		subtitulo: {
			type: [Number, String],
			default: "",
		},
		link: {
			type: [Number, String],
			default: "",
		},
		target: {
			type: [Number, String],
			default: "",
		},
		interna: {
			type: [Number, String],
			default: "",
		},
		boton: {
			type: [Number, String],
			default: "",
		},
	},
	mounted() {
		// eslint-disable-next-line no-console
		console.log(this.link);
	},
	methods: {
		btnIrCurso_click() {
			this.$router.push(`/${this.link}`);
			// let routeData = this.$router.resolve({
			//   path: this.link,
			// });
			// if (this.interna == 1) {
			//   window.open(routeData.href, this.target);
			// } else {
			//   window.open(this.link, this.target);
			// }
			// this.$router.push(this.link);
			// console.log(this.target, this.link, this.interna);
		},
	},
};
</script>