var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$vuetify.breakpoint.name == 'xs')?_c('v-container',{staticStyle:{"background-color":"#f3f3f3","padding":"0","max-width":"100% !important","overflow-x":"hidden !important","overflow-y":"hidden !important","margin-top":"-71px"},attrs:{"fluid":"","dense":""}},[_c('v-card',{staticClass:"mx-auto overflow-hidden",staticStyle:{"border-radius":"0"}},[_c('section',{class:{
				'bg-seccion-banner': !_vm.premium,
				'bg-seccion-banner-premium': _vm.premium,
			}},[_c('CarouselPrincipalXS',{attrs:{"bienvenida":_vm.bievenida()}})],1),(!_vm.closeSeccionVideo)?_c('section',{staticStyle:{"background-color":"#f3f3f3","padding-bottom":"40px"}},[_c('SeccionVideoXS',{attrs:{"closeSeccionVideo":_vm.closeSeccionVideo},on:{"btnCloseSeccionVideo":_vm.btnCloseSeccionVideo}})],1):_vm._e(),_c('section',{staticStyle:{"padding":"20px"},style:({ backgroundColor: _vm.theme.backcontinuar })},[_c('CarruselContinuarReproduciendoXS')],1),_c('span',{attrs:{"id":"cursos"}}),_vm._l((_vm.cardsCursosFree),function(items,index){return _c('section',{key:index + 'premium',staticStyle:{"padding-top":"20px","background-color":"rgb(243, 243, 243)"},style:({
				paddingBottom: _vm.cardsCursosFree.length == index + 1 ? '335px' : '0px',
			})},[(items.items.length > 0)?_c('CarruselCursosFreeXS',{attrs:{"cardsCursosFree":items,"nombremetodo":"cardsCursosFree"}}):_vm._e()],1)}),_c('section',{staticStyle:{"background-color":"#272429"}},[_c('CarruselPremiumXS')],1),_c('section',{staticStyle:{"margin-bottom":"0px","height":"800px","background-color":"rgb(243 243 243)","padding-top":"50px"},attrs:{"id":"especialistas"}},[_c('CarruselEspecialistasXS')],1),_c('section',{staticStyle:{"margin-bottom":"0px","background-color":"rgb(243 243 243)"}},[_c('NiufleexLiveXS')],1),_c('section',{staticStyle:{"margin-bottom":"20px"}},[_c('FiveStar')],1)],2)],1):(
		_vm.$vuetify.breakpoint.name == 'sm' || _vm.$vuetify.breakpoint.name == 'md'
	)?_c('v-container',{staticStyle:{"padding":"0","max-width":"100% !important","overflow-x":"hidden !important","overflow-y":"hidden !important","margin-top":"-71px","background-color":"#f3f3f3"},attrs:{"fluid":""}},[_c('v-card',{staticClass:"mx-auto overflow-hidden",staticStyle:{"border-radius":"0"},attrs:{"light":""}},[_c('section',{class:{
				'bg-seccion-banner': !_vm.premium,
				'bg-seccion-banner-premium': _vm.premium,
			}},[_c('CarouselPrincipalXS',{attrs:{"bienvenida":_vm.bievenida()}})],1),(!_vm.closeSeccionVideo)?_c('section',{staticStyle:{"background-color":"#f3f3f3","text-align":"center","padding":"10%"}},[_c('SeccionVideoXS',{attrs:{"closeSeccionVideo":_vm.closeSeccionVideo},on:{"btnCloseSeccionVideo":_vm.btnCloseSeccionVideo}})],1):_vm._e(),_c('section',{staticStyle:{"padding-bottom":"20px","height":"950px"},style:({ backgroundColor: _vm.theme.backcontinuar })},[_c('CarruselContinuarReproduciendoSM')],1),_c('span',{attrs:{"id":"cursos"}}),_vm._l((_vm.cardsCursosFree),function(items,index){return _c('section',{key:index + 'premium',staticStyle:{"margin-bottom":"0px","padding-top":"20px","background-color":"#f3f3f3"},style:({
				paddingBottom: _vm.cardsCursosFree.length == index + 1 ? '335px' : '0px',
			})},[(items.items.length > 0)?_c('CarruselCursosFreeSM',{attrs:{"cardsCursosFree":items,"nombremetodo":"cardsCursosFree"}}):_vm._e()],1)}),_c('section',{staticStyle:{"background-color":"#272429"}},[_c('CarruselPremiumSM')],1),_c('section',{staticStyle:{"margin-bottom":"0px","padding-bottom":"100px","padding-top":"80px","background-color":"#f3f3f3"},attrs:{"id":"especialistas"}},[_c('CarruselEspecialistasSM')],1),_c('section',{staticStyle:{"background-color":"#f3f3f3","padding-top":"60px"}},[_c('NiufleexLiveXS')],1),_c('section',{staticStyle:{"margin-bottom":"20px","background-color":"#f3f3f3"}},[_c('FiveStar')],1)],2)],1):_c('v-container',{staticStyle:{"padding":"0px","max-width":"100% !important","overflow-x":"hidden !important","overflow-y":"hidden !important","position":"relative","background-color":"#fff"},attrs:{"fluid":""}},[_c('v-card',{staticClass:"mx-auto overflow-hidden",staticStyle:{"border-radius":"0","background-color":"#fff !important"}},[_c('section',{class:{
				'bg-seccion-banner': !_vm.premium,
				'bg-seccion-banner-premium': _vm.premium,
			},staticStyle:{"padding":"30px 0"}},[_c('CarouselPrincipalMD',{attrs:{"bienvenida":_vm.bievenida()}})],1),(!_vm.closeSeccionVideo)?_c('section',{staticStyle:{"background-color":"#f3f3f3","padding":"20px 0","min-height":"326.25px"}},[_c('SeccionVideo',{attrs:{"closeSeccionVideo":_vm.closeSeccionVideo},on:{"btnCloseSeccionVideo":_vm.btnCloseSeccionVideo}})],1):_vm._e(),_c('section',{staticStyle:{"padding-bottom":"50px"},style:({ backgroundColor: _vm.theme.backcontinuar })},[_c('CarruselContinuarReproduciendoMD')],1),_c('span',{attrs:{"id":"cursos"}}),_vm._l((_vm.cardsCursosFree),function(items,index){return _c('section',{key:index + 'premium',staticStyle:{"margin-bottom":"0px","padding-top":"30px","background-color":"#f3f3f3"},style:({
				paddingBottom: _vm.cardsCursosFree.length == index + 1 ? '335px' : '0px',
			})},[(items.items.length > 0)?_c('CarruselCursosFreeMD',{attrs:{"cardsCursosFree":items,"nombremetodo":"cardsCursosFree"}}):_vm._e()],1)}),_c('section',{staticStyle:{"background-color":"#272429"}},[_c('CarruselPremiumMD')],1),_c('section',{staticStyle:{"padding-top":"30px","background-color":"rgb(243 243 243)","height":"800px"},attrs:{"id":"especialistas"}},[_c('CarruselEspecialistas')],1),_c('section',{staticStyle:{"padding-bottom":"80px","background-color":"rgb(243 243 243)"}},[_c('NiufleexLiveMD')],1),_c('section',{staticStyle:{"margin-bottom":"20px","background-color":"rgb(243 243 243)"}},[_c('FiveStar')],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }