<template>
	<v-container>
		<v-row style="margin-bottom: 20px">
			<v-col cols="12" style="padding-bottom: 10px; text-align: center">
				<h2
					style="
						font-family: 'Poppins', Sans-serif;
						font-size: 30px;
						font-weight: 600;
						line-height: 1em;
						letter-spacing: -0.7px;
					"
					:style="{
						color: theme.title,
					}"
				>
					{{ cardsCursosFree.titulo }}
				</h2>
			</v-col>
			<v-col cols="12" style="padding-top: 0; text-align: center">
				<h2
					style="
						color: #525252;
						font-family: 'Raleway', Sans-serif;
						font-size: 18px;
						font-weight: 500;
						line-height: 1em;
						letter-spacing: 0px;
					"
				>
					{{ cardsCursosFree.subtitulo }}
				</h2>
			</v-col>
		</v-row>
		<v-row
			v-if="
				cardsCursosFree &&
				cardsCursosFree.items &&
				cardsCursosFree.items.length > 0
			"
		>
			<v-flex>
				<v-carousel
					hide-delimiters
					style="
						box-shadow: 0px 0px;
						padding-bottom: 50px;
						padding-left: 25px;
						padding-right: 15px;
						min-height: 550px !important;
						height: 550px !important;
					"
					class="csscards2XS"
				>
					<v-carousel-item
						v-for="(i, indice) in cardsCursosFree.items.length"
						:key="i"
					>
						<v-layout row>
							<v-flex v-for="j in 1" :key="j" pl-2 pr-2>
								<Cards2
									accion="curso"
									:path="cardsCursosFree.items[indice].src"
									:estudiantes="cardsCursosFree.items[indice].estudiantes"
									:puntuacion="cardsCursosFree.items[indice].puntuacion"
									:nombre="cardsCursosFree.items[indice].nombre"
									:resenia="cardsCursosFree.items[indice].resenia"
									:especialista="cardsCursosFree.items[indice].especialista"
									:sesiones="cardsCursosFree.items[indice].sesiones"
									:minutos="cardsCursosFree.items[indice].minutos"
									:link="cardsCursosFree.items[indice].link"
									:target="cardsCursosFree.items[indice].target"
									:interna="cardsCursosFree.items[indice].interna"
									:boton="cardsCursosFree.items[indice].boton"
								></Cards2>
								<p style="display: none">
									{{ contador(cardsCursosFree.items.length) }}
								</p>
							</v-flex>
						</v-layout>
					</v-carousel-item>
				</v-carousel>
				<!-- <p
					style="
						margin-top: -135px;
						text-align: center;
						color: #525252;
						font-family: 'Poppins', Sans-serif;
						font-size: 15px;
						font-weight: 600;
						line-height: 1.2em;
						letter-spacing: -0.7px;
					"
				>
					{{ cardsCursosFree.cantVistos }} de
					{{ cardsCursosFree.totCursos }} Cursos
				</p> -->
			</v-flex>
		</v-row>
	</v-container>
</template>
<style>
.csscards2XS .v-image{
	padding-top: 20px;
}
.csscards2XS .v-window__prev {
	margin-top: 200px !important;
	margin-left: 30% !important;	
}
.csscards2XS .v-window__next {
	margin-top: 200px !important;
	margin-right: 30% !important;
}
.csscards2XS .v-window__prev .v-btn {
	font-size: 17px !important;
    padding: 15px !important;
    border-width: 3px 3px 3px 3px !important;
    border-radius: 5px 5px 5px 5px !important;
    color: #272429 !important;
    border-color: #272429 !important;
    height: 53px;
    width: 53px;
	background-color: #f3f3f3;
    border: solid;
}
.csscards2XS .v-window__next .v-btn {
	font-size: 17px !important;
    padding: 15px !important;
    border-width: 3px 3px 3px 3px !important;
    border-radius: 5px 5px 5px 5px !important;
    color: #272429 !important;
    border-color: #272429 !important;
    height: 53px;
    width: 53px;
	background-color: #f3f3f3;
    border: solid;
}
.csscards2XS .v-window__next .v-btn span i{
	color:#272429 !important;
	font-size: 36px !important;		
}
.csscards2XS .v-window__prev .v-btn span i{
	color:#272429 !important;
}
.csscards2XS .v-window-item .v-image{
	padding-right: 15px !important;
	font-size: 36px !important;	
}

</style>
<script>
import { mapState } from "vuex";
import Cards2 from "../ContinuarReproduciendoPremium/CardsContinuarReproduciendo.vue";
export default {
	data() {
		return {
			indice: 0,
		};
	},
	components: {
		Cards2,
	},
	props: {
		cardsCursosFree: {
			type: Object,
			default: () => {
				return {
					titulo: "Cursos FREE disponibles para ti",
					subtitulo:
						"Especialistas, Mentores y guías. Conoce a quienes te harán subir al próximo nivel.",
					link_general: "https://niufleex.com/",
					target_general: "_blank",
					interna_general: "0",
					boton_general: "ver todo el contenido",
					boton_general_xs: "ver todo",
					totCursos: 10,
					cantVistos: 3,
					items: [
						{
							src: "https://niufleex.com/wp-content/uploads/2022/01/s3.jpg",
							estudiantes: "1.456",
							puntuacion: "4.7",
							nombre: "MÓDULO 1",
							resenia: "La Neta del Planeta es la meta",
							especialista: "Valentina Góngora",
							sesiones: "2",
							minutos: "137",
							area: "EMPRENDIMIENTO Y NEGOCIOS",
							link: "https://niufleex.com/",
							target: "_blank",
							interna: "0",
							boton: "ir al curso",
						},
						{
							src: "https://niufleex.com/wp-content/uploads/2021/12/2.PORTADA-VENTAS-POR-WHATSAPP.png",
							estudiantes: "2.584",
							puntuacion: "3.8",
							nombre: "MÓDULO 4",
							resenia: "El Arte de dejar los vistos",
							especialista: "Valentina Góngora",
							sesiones: "2",
							minutos: "137",
							area: "LIDERAZGO",
							link: "https://niufleex.com/",
							target: "_blank",
							interna: "0",
							boton: "ir al curso",
						},
						{
							src: "https://niufleex.com/wp-content/uploads/2021/12/CLIENTES-POTENCIALES.jpg",
							estudiantes: "842",
							puntuacion: "4.9",
							nombre: "MÓDULO 9",
							resenia: "Funcionalidades de WhatsApp Business",
							especialista: "Valentina Góngora",
							sesiones: "2",
							minutos: "137",
							area: "LIDERAZGO",
							link: "https://niufleex.com/",
							target: "_blank",
							interna: "0",
							boton: "ir al curso",
						},
						{
							src: "https://niufleex.com/wp-content/uploads/2022/01/s3.jpg",
							estudiantes: "1.456",
							puntuacion: "4.7",
							nombre: "MÓDULO 20",
							resenia: "La Neta del Planeta es la meta",
							especialista: "Valentina Góngora",
							sesiones: "2",
							minutos: "137",
							area: "LIDERAZGO",
							link: "https://niufleex.com/",
							target: "_blank",
							interna: "0",
							boton: "ir al curso",
						},
						{
							src: "https://niufleex.com/wp-content/uploads/2021/12/2.PORTADA-VENTAS-POR-WHATSAPP.png",
							estudiantes: "2.584",
							puntuacion: "3.8",
							nombre: "MÓDULO 21",
							resenia: "El Arte de dejar los vistos",
							especialista: "Valentina Góngora",
							sesiones: "2",
							minutos: "137",
							area: "LIDERAZGO",
							link: "https://niufleex.com/",
							target: "_blank",
							interna: "0",
							boton: "ir al curso",
						},
						{
							src: "https://niufleex.com/wp-content/uploads/2021/12/CLIENTES-POTENCIALES.jpg",
							estudiantes: "842",
							puntuacion: "4.9",
							nombre: "MÓDULO 22",
							resenia: "Funcionalidades de WhatsApp Business",
							especialista: "Valentina Góngora",
							sesiones: "2",
							minutos: "137",
							area: "CONOCIMIENTO TÉCNICO",
							link: "https://niufleex.com/",
							target: "_blank",
							interna: "0",
							boton: "ir al curso",
						},
					],
				};
			},
		},
	},
	async mounted() {
		// try {
		// 	this.cardsCursosFree = (
		// 		await this.$http.get("/listacursos/free")
		// 	).data[0];
		// 	// eslint-disable-next-line no-console
		// 	console.log(this.cardsCursosFree);
		// } catch (error) {
		// 	this.cardsCursosFree = null;
		// }
	},
	computed: {
		...mapState(["theme"]),
	},
	methods: {
		contador(i) {
			if (this.indice < i) return parseInt(this.indice++);
			else return this.indice;
		},
		btnVerContenido_click() {
			let routeData = this.$router.resolve({
				path: this.cardsCursosFree.link_general,
			});
			if (this.interna_general == 1) {
				window.open(routeData.href, this.target_general);
			} else {
				window.open(this.cardsCursosFree.link_general, this.target_general);
			}
		},
	},
};
</script>