<template>
	<!-- PAGINA MOBILE XS -->
	<v-container
		fluid
		style="
			background-color: #f3f3f3;
			padding: 0;
			max-width: 100% !important;
			overflow-x: hidden !important;
			overflow-y: hidden !important;
			margin-top: -17px;
		"
		dense
		v-if="$vuetify.breakpoint.name == 'xs'"
	>
		<v-card class="mx-auto overflow-hidden" style="border-radius: 0">
			<section
				v-if="!premium"
				class="flex-grow-0"
				style="
					background-color: rgb(39, 36, 41);
					padding-top: 0;
					padding-bottom: 0;
				"
			>
				<ComprarAhoraXS></ComprarAhoraXS>
			</section>
			<section class="bg-seccion-banner">
				<CarouselPrincipalXS :bienvenida="bievenida()"></CarouselPrincipalXS>
			</section>
			<section style="background-color: #f3f3f3; padding-bottom:40px;" v-if="!closeSeccionVideo">
				<SeccionVideoXS
					@btnCloseSeccionVideo="btnCloseSeccionVideo"
					:closeSeccionVideo="closeSeccionVideo"
				></SeccionVideoXS>
			</section>
			<section
				style="background-color: #6a289f; padding: 40px;"
			>
				<CarruselContinuarReproduciendoXS></CarruselContinuarReproduciendoXS>
			</section>
			<span id="cursos"></span>
			<section
				style="margin-bottom: 0px; padding-top:20px; background-color: #f3f3f3;"
				v-for="(free, index) of cardsCursosFree"
				:key="'free' + index"
			>
				<CarruselCursosFreeXS
					v-if="free.items.length > 0"
					:cardsCursosFree="free"
				></CarruselCursosFreeXS>
			</section>
			<section
				style="margin-bottom: 0px; background-color: #f3f3f3;"
				v-for="(teams, index) of cardsCursosTeams"
				:key="'team' + index"
			>
				<CarruselCursosFreeXS
					v-if="teams.items.length > 0"
					:cardsCursosFree="teams"
				></CarruselCursosFreeXS>
			</section>
			<section style="padding-bottom: 30px; padding-top: 0; background-color: #f3f3f3;">
				<NiufleexPremiumXS></NiufleexPremiumXS>
			</section>
			<section
				id="especialistas"
				style="padding-bottom: 260px; background-color: #f3f3f3;"
			>
				<CarruselEspecialistasXS></CarruselEspecialistasXS>
			</section>
			<section style="background-color: #272429;">
				<CarruselPremiumXS></CarruselPremiumXS>
			</section>
			<section style="padding-bottom: 40px; background-color: #f3f3f3; padding-top:60px;">
				<NiufleexLiveXS></NiufleexLiveXS>
			</section>
			<section style="margin-bottom: 20px">
				<FiveStar></FiveStar>
			</section>
		</v-card>
	</v-container>
	<!-- PAGINA MOBILE SM / MD -->
	<v-container
		fluid
		style="
			padding: 0;
			max-width: 100% !important;
			overflow-x: hidden !important;
			overflow-y: hidden !important;
			margin-top: -17px;
		"
		v-else-if="
			$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md'
		"
	>
		<v-card class="mx-auto overflow-hidden" style="border-radius: 0">
			<section
				v-if="!premium"
				style="
					background-color: rgb(39, 36, 41);
					padding-top: 0;
					padding-bottom: 0;
				"
			>
				<ComprarAhoraXS></ComprarAhoraXS>
			</section>
			<section class="bg-seccion-banner">
				<CarouselPrincipalXS :bienvenida="bievenida()"></CarouselPrincipalXS>
			</section>
			<section
				style="
					background-color: #f3f3f3;
					text-align: center;
					padding: 10%;
				"
				v-if="!closeSeccionVideo"
			>
				<SeccionVideoXS
					@btnCloseSeccionVideo="btnCloseSeccionVideo"
					:closeSeccionVideo="closeSeccionVideo"
				></SeccionVideoXS>
			</section>
			<section style="background-color: #6a289f; padding-bottom: 80px">
				<CarruselContinuarReproduciendoSM></CarruselContinuarReproduciendoSM>
			</section>
			<span id="cursos"></span>
			<section
				style="background-color:#f3f3f3; padding-top: 40px;"
				v-for="(free, index) of cardsCursosFree"
				:key="'free' + index"
			>
				<CarruselCursosFreeSM
					v-if="free.items.length > 0"
					:cardsCursosFree="free"
				></CarruselCursosFreeSM>
			</section>
			<section
				style="background-color:#f3f3f3;"
				v-for="(teams, index) of cardsCursosTeams"
				:key="'team' + index"
			>
				<CarruselCursosFreeSM
					v-if="teams.items.length > 0"
					:cardsCursosFree="teams"
				></CarruselCursosFreeSM>
			</section>
			<section style="padding-top: 0; background-color:#f3f3f3;">
				<NiufleexPremiumXS></NiufleexPremiumXS>
			</section>

			<section
				id="especialistas"
				style="padding-bottom: 300px; background-color:#f3f3f3; padding-top:40px;"
			>
				<CarruselEspecialistasSM></CarruselEspecialistasSM>
			</section>

			<section style="background-color: #272429; padding-bottom: 20px">
				<CarruselPremiumSM></CarruselPremiumSM>
			</section>

			<section style=" background-color:#f3f3f3; padding-top: 80px; padding-bottom:60px;">
				<NiufleexLiveXS></NiufleexLiveXS>
			</section>
			<section style="margin-bottom: 20px">
				<FiveStar></FiveStar>
			</section>
		</v-card>
	</v-container>
	<!-- PAGINA MOBILE LG / XL -->
	<v-container
		transition="fab-transition"
		v-else
		fluid
		style="
			padding: 0px;
			max-width: 100% !important;
			overflow-x: hidden !important;
			overflow-y: hidden !important;
		"
		:style="{
			marginTop: premium && $router.currentRoute.name == 'free' ? 0 : '-72px',
		}"
	>
		<v-card class="mx-auto overflow-hidden" style="border-radius: 0">
			<section
				style="
					background-color: rgb(39, 36, 41);
					padding-top: 0;
					padding-bottom: 0;
				"
				v-if="!premium"
			>
				<ComprarAhoraMD></ComprarAhoraMD>
			</section>
			<section class="bg-seccion-banner" style="padding-top:10px;">
				<CarouselPrincipalMD :bienvenida="bievenida()"></CarouselPrincipalMD>
			</section>
			<section
				style="background-color: #f3f3f3; padding: 20px 0; min-height: 326.25px"
				v-if="!closeSeccionVideo"
			>
				<SeccionVideo
					@btnCloseSeccionVideo="btnCloseSeccionVideo"
					:closeSeccionVideo="closeSeccionVideo"
				></SeccionVideo>
			</section>
			<section style="background-color: #4b0b7f; padding-bottom: 20px">
				<CarruselContinuarReproduciendoMD></CarruselContinuarReproduciendoMD>
			</section>
			<span id="cursos"></span>
			<section
				style="margin-bottom: 0px; padding-top: 40px; background-color:#f3f3f3;"
				v-for="(free, index) of cardsCursosFree"
				:key="'free' + index"
			>
				<CarruselCursosFreeMD
					v-if="free.items.length > 0"
					:cardsCursosFree="free"
					nombremetodo="cardsCursosFree"
				></CarruselCursosFreeMD>
			</section>
			<section
				style="margin-bottom: 0px; padding-top: 20px; background-color:#f3f3f3;"
				v-for="(teams, index) of cardsCursosTeams"
				:key="'team' + index"
			>
				<CarruselCursosFreeMD
					v-if="teams.items.length > 0"
					:cardsCursosFree="teams"
					nombremetodo="cardsCursosFree"
				></CarruselCursosFreeMD>
			</section>
			<section style="background-color: #f3f3f3; padding-top: 80px; margin-bottom: 20px; padding-bottom: 375px">
				<NiufleexPremium></NiufleexPremium>
			</section>

			<section style="background-color: #272429;">
				<CarruselPremiumMD></CarruselPremiumMD>
			</section>
			<section id="especialistas" style="background-color: #f3f3f3; padding-top:60px;">
				<CarruselEspecialistas></CarruselEspecialistas>
			</section>
			<section style="padding-bottom: 80px; padding-top:80px; background-color: #f3f3f3;">
				<NiufleexLiveMD></NiufleexLiveMD>
			</section>
			<section style="margin-bottom: 20px">
				<FiveStar></FiveStar>
			</section>
		</v-card>
	</v-container>
</template>
<style>
* {
	position: static;
}
.v-banner__wrapper{
	border:none !important;
}
.mnuxs .v-navigation-drawer__content {
	background-color: rgb(247, 124, 10);
	background: none !important;
}
.iface {
	font-size: 17px;
	padding-left: 12px;
	padding-top: 6px;
	border-width: 3px 3px 3px 3px;
	border-radius: 50%;
	color: white;
	border-color: white;
	width: 43px;
	height: 43px;
	border: solid;
	margin-right: 40px;
}
.iface:hover {
	color: #fb950c;
	border-color: #fb950c;
}
.btnmenu {
	background-color: transparent !important;
	color: white !important;
	font-family: "Raleway", Sans-serif !important;
	font-size: 18px !important;
	font-weight: 600 !important;
}
.btnmenu:hover {
	color: #fb950c !important;
}
.btnmenu:hover::before {
	opacity: 0 !important;
}
.bg-seccion-banner {
	/* height: 616.59px; */
	background: rgb(106, 40, 159);
	background: radial-gradient(
		circle,
		rgba(106, 40, 159, 1) 42%,
		rgba(75, 11, 127, 1) 68%
	);
}
</style> 
<script>
//BANNER SUPERIOR COMPRAR AHORA
import ComprarAhoraMD from "../components/shared/comprarahora.vue";
import ComprarAhoraXS from "../components/shared/comprarAhoraXS.vue";
//CARRUSEL PUBLICITARIO PRINCIPAL
import CarouselPrincipalMD from "../components/shared/CarruselesPromocion/CarruselPrinsipalMD.vue";
import CarouselPrincipalXS from "../components/shared/CarruselesPromocion/CarruselPrincipalXS.vue";
//VIDEO PROMOCIONAL
import SeccionVideo from "../components/explora_free/SeccionVideo.vue";
import SeccionVideoXS from "../components/explora_free/SeccionVideoXS.vue";
//CARRUSEL CURSOS CONTINUAR REPRODUCIENDO
import CarruselContinuarReproduciendoMD from "../components/shared/CarruselesCursos/ContinuarReproduciendo/CarruselCuntinuarReproduciendoMD.vue";
import CarruselContinuarReproduciendoSM from "../components/shared/CarruselesCursos/ContinuarReproduciendo/CarruselContinuarReproduciendoSM.vue";
import CarruselContinuarReproduciendoXS from "../components/shared/CarruselesCursos/ContinuarReproduciendo/CarruselContinuarReproduciendoXS.vue";
//CARRUSEL CURSOS FREE
import CarruselCursosFreeMD from "../components/shared/CarruselesCursos/CursosFree/CarouselCardsFreeMD.vue";
import CarruselCursosFreeSM from "../components/shared/CarruselesCursos/CursosFree/CarouselCardsFreeSM.vue";
import CarruselCursosFreeXS from "../components/shared/CarruselesCursos/CursosFree/CarruselCardsFreeXS.vue";
//BANNER PUBLICITARIO NIUFLIX PREMIUM
import NiufleexPremium from "../components/shared/Banners/NiufleexPremium.vue";
import NiufleexPremiumXS from "../components/shared/Banners/NiufleexPremiumXS.vue";
//CARRUSEL ESPECIALISTAS
import CarruselEspecialistas from "../components/shared/CarruselesCursos/Especialistas/CarouselCardsEspecialistasMD.vue";
import CarruselEspecialistasSM from "../components/shared/CarruselesCursos/Especialistas/CarouselCardsEspecialistasSM.vue";
import CarruselEspecialistasXS from "../components/shared/CarruselesCursos/Especialistas/CarruselEspecialistasXS.vue";
//CARRUSEL CURSOS PREMIUM
import CarruselPremiumMD from "../components/shared/CarruselesCursos/CursosPremium/CarruselPremiumMD.vue";
import CarruselPremiumSM from "../components/shared/CarruselesCursos/CursosPremium/CarruselPremiumSM.vue";
import CarruselPremiumXS from "../components/shared/CarruselesCursos/CursosPremium/CarruselPremiumXS.vue";

//BANNER PUBLICITARIO LIVES
import NiufleexLiveMD from "../components/shared/Banners/NiufleexLive.vue";
import NiufleexLiveXS from "../components/shared/Banners/NiufleexLiveXS.vue";
//CALIFICACION FIVE STAR
import FiveStar from "../components/shared/FiveStar.vue";
//SECCION FOOTER
import SoporteNiufleex from "../components/shared/Footer/SoporteNiufleex.vue";
import FooterUno from "../components/shared/Footer/FooterUno.vue";
import FooterDos from "../components/shared/Footer/FooterDos.vue";

//SECCION MENU
import Menu from "../components/shared/Menu/MenuLG.vue";
import MenuMDSM from "../components/shared/Menu/MenuSM_MD.vue";
import MenuXS from "../components/shared/Menu/MenuXS.vue";

import { mapState } from "vuex";
export default {
	name: "explora_free",
	data: () => {
		return {
			closeSeccionVideo: false,
			cardsCursosTeams: [
				{
					titulo: "Cursos FREE disponibles para ti",
					subtitulo:
						"Especialistas, Mentores y guías. Conoce a quienes te harán subir al próximo nivel.",
					link_general: "https://niufleex.com/",
					target_general: "_blank",
					interna_general: "0",
					boton_general: "ver todo el contenido",
					boton_general_xs: "ver todo",
					totCursos: 10,
					cantVistos: 3,
					items: [],
				},
			],
			cardsCursosFree: [
				{
					titulo: "Cursos FREE disponibles para ti",
					subtitulo:
						"Especialistas, Mentores y guías. Conoce a quienes te harán subir al próximo nivel.",
					link_general: "https://niufleex.com/",
					target_general: "_blank",
					interna_general: "0",
					boton_general: "ver todo el contenido",
					boton_general_xs: "ver todo",
					totCursos: 10,
					cantVistos: 3,
					items: [],
				},
			],
			drawer: false,
			drawer2: false,
			group: null,
			group2: null,
			logos: {
				logo1: {
					src: "https://dsm01pap008files.storage.live.com/y4meHSrzhJvABIWJfxS8l97K6Hzc-C9ZN2VlrL0Pkyl0ElyynDnwq8Y9oyG6eD05t9A9iblEPd5GqJiNygQwK2RO2ru0LpSxBeeGpfJXCS2L6H7JZ33IJxUkbdAQ3ABgiiQnfqk4n10KJcsdT_2r9voeKiPO9M2WuOtyVjridPY7C5b0jWDCJzawVoYvk2Q2LPr?width=660&height=167&cropmode=none",
					link: "/explora/free",
					target: "_self",
				},
				logo2: {
					src: "https://niufleex.com/wp-content/uploads/2021/12/Sin-ti%CC%81tulo-1.png",
					link: "/explora/free",
					target: "_self",
				},
				avatar: {
					src: "",
				},
			},
			items: [
				{
					boton: "Explora Free",
					link: "/explora/free",
					target: "_self",
					interna: 0,
					subitems: [],
					membresia: [0, 1, 2],
					teams: 0,
				},
				{
					boton: "Explora Premium",
					link: "/newexplora",
					target: "_self",
					interna: 1,
					subitems: [],
					membresia: [2],
				},
				// {
				// 	boton: "Elite",
				// 	link: "/explora/free",
				// 	target: "_self",
				// 	interna: 1,
				// 	subitems: [],
				// 	membresia: [0, 1, 2],
				// 	teams: 1,
				// 	nameteam: 'elite',
				// },
				// {
				// 	boton: "Teoma Chile",
				// 	link: "/explora/free",
				// 	target: "_self",
				// 	interna: 1,
				// 	subitems: [],
				// 	membresia: [0, 1, 2],
				// 	teams: 1,
				// 	nameteam: 'teomachile',
				// },
				// {
				// 	boton: "Cursos",
				// 	link: "https://niufleex.com",
				// 	target: "_blank",
				// 	interna: 0,
				// 	subitems: [
				// 		{
				// 			boton: "Área de Conocimiento 1",
				// 			link: "https://niufleex.com",
				// 			target: "_blank",
				// 			interna: 0,
				// 		},
				// 		{
				// 			boton: "Área de Conocimiento 2",
				// 			link: "https://niufleex.com",
				// 			target: "_blank",
				// 			interna: 0,
				// 		},
				// 		{
				// 			boton: "Área de Conocimiento 3",
				// 			link: "https://niufleex.com",
				// 			target: "_blank",
				// 			interna: 0,
				// 		},
				// 	],
				// },
				// {
				// 	boton: "Especialistas",
				// 	link: "https://niufleex.com",
				// 	target: "_blank",
				// 	interna: 0,
				// 	subitems: [],
				// },
				{
					boton: "Live",
					link: "/live",
					target: "_self",
					interna: 1,
					subitems: [],
					membresia: [0, 1, 2],
				},
			],
		};
	},
	components: {
		ComprarAhoraMD,
		ComprarAhoraXS,
		CarouselPrincipalMD,
		CarouselPrincipalXS,
		SeccionVideo,
		SeccionVideoXS,
		CarruselContinuarReproduciendoMD,
		CarruselContinuarReproduciendoSM,
		CarruselContinuarReproduciendoXS,
		CarruselCursosFreeMD,
		CarruselCursosFreeSM,
		CarruselCursosFreeXS,
		NiufleexPremium,
		NiufleexPremiumXS,
		CarruselEspecialistas,
		CarruselEspecialistasSM,
		CarruselEspecialistasXS,
		CarruselPremiumMD,
		CarruselPremiumSM,
		CarruselPremiumXS,
		NiufleexLiveMD,
		NiufleexLiveXS,
		FiveStar,
		SoporteNiufleex,
		FooterUno,
		FooterDos,
		Menu,
		MenuMDSM,
		MenuXS,
	},
	computed: {
		...mapState(["premium"]),
	},
	created() {
		this.$vuetify.theme.dark = false;
	},
	updated() {
		if (this.$vuetify.theme.dark != false) {
			this.$vuetify.theme.dark = false;
		}
	},
	destroyed() {
		this.$vuetify.theme.dark = true;
	},
	async mounted() {
		try {
			this.cardsCursosFree = [
				...this.cardsCursosFree,
				...(await this.$http.get("/listacursos/free")).data,
			];
			this.cardsCursosTeams = (await this.$http.get("/listacursos/team")).data;

			// eslint-disable-next-line no-empty
		} catch (error) {}
		// eslint-disable-next-line no-console
		console.log(this.cardsCursosFree);
		// eslint-disable-next-line no-console
		console.log("cardsCursosTeams", this.cardsCursosTeams);
		try {
			this.closeSeccionVideo = (
				await this.$http.get("/settings/presentation")
			).data[0].is_presentation;
			// eslint-disable-next-line no-console
			console.log(this.closeSeccionVideo);
			// eslint-disable-next-line no-empty
		} catch (error) {}
	},
	methods: {
		btnCloseSeccionVideo() {
			this.closeSeccionVideo = true;
		},
		bievenida() {
			return {
				nombre: `${this.firstname()} ${this.firstsurname()}`,
				genero: `${this.genero()}`,
			};
		},
		firstname() {
			try {
				let data = this.$storeNiu.getObjectValue("data");

				if (Object.prototype.hasOwnProperty.call(data, "firstname")) {
					return data.firstname;
				}
				return "";
				//eslint-disable-next-line
			} catch (error) {
				return "";
			}
		},
		firstsurname() {
			try {
				let data = this.$storeNiu.getObjectValue("data");

				if (Object.prototype.hasOwnProperty.call(data, "firstsurname")) {
					return data.firstsurname;
				}
				return "";
				//eslint-disable-next-line
			} catch (error) {
				return "";
			}
		},
		genero() {
			try {
				let data = this.$storeNiu.getObjectValue("data");
				// this.showButton();
				// this.encuesta();
				if (Object.prototype.hasOwnProperty.call(data, "genero")) {
					return data.genero;
				}
				return "";
				//eslint-disable-next-line
			} catch (error) {
				return "";
			}
		},
	},
};
</script>