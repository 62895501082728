<template>
	<v-container style="padding-top: 40px; padding-bottom: 0">
		<v-row>
			<v-col
				v-if="bienvenida.nombre"
				style="padding-bottom: 2px; text-align: center"
			>
				<h2
					style="
						color: #ffffff;
						font-family: 'Raleway', Sans-serif;
						font-size: 20px;
						font-weight: 400;
						line-height: 1.1em;
						letter-spacing: -0.7px;
					"
				>
					Hola <strong>{{ bienvenida.nombre }}</strong>
				</h2>
			</v-col>
			<v-col cols="12" style="padding-top: 0px; text-align: center">
				<h2
					style="
						color: #ffffff;
						font-family: 'Raleway', Sans-serif;
						font-size: 20px;
						font-weight: 400;
						line-height: 1.1em;
						letter-spacing: -0.7px;
					"
				>
					¡Te damos la bienvenida!
				</h2>
			</v-col>
			<v-col cols="12" style="padding-top: 8px; padding-bottom: 0">
				<v-flex>
					<v-carousel
						hide-delimiters
						style="box-shadow: 0px 0px"
						class="carouselprincipal"
						height="700px"
					>
						<v-carousel-item
							v-for="(i, indice) in carruselPrincipal.items.length"
							:key="i"
							height="600px"
						>
							<v-layout row>
								<v-flex v-for="j in 1" :key="j" pl-2 pr-2>
									<CardsCarouselPrincipalXS
										:path="carruselPrincipal.items[indice].src_movil"
										:titulo1="carruselPrincipal.items[indice].titulo1"
										:img="carruselPrincipal.items[indice].img"
										:titulo2="carruselPrincipal.items[indice].titulo2"
										:subtitulo="carruselPrincipal.items[indice].subtitulo"
										:link="carruselPrincipal.items[indice].link"
										:target="carruselPrincipal.items[indice].target"
										:interna="carruselPrincipal.items[indice].interna"
										:boton="carruselPrincipal.items[indice].boton"
									></CardsCarouselPrincipalXS>
								</v-flex>
							</v-layout>
						</v-carousel-item>
					</v-carousel>
				</v-flex>
			</v-col>
		</v-row>
	</v-container>
</template>
<style>
.carouselprincipal .v-carousel__controls {
	background-color: transparent !important;
}
.carouselprincipal .v-window__prev {
	margin-top: -7%;
	background: transparent !important;
}
.carouselprincipal .v-window__next {
	margin-top: -7%;
	background: transparent !important;
}
.carouselprincipal .v-window__prev .v-btn {
	width: 53px;
	height: 53px;
	background-color: transparent !important;
	color: #4b0b7f !important;
	font-size: 30px;
}
.carouselprincipal .v-window__next .v-btn {
	width: 53px;
	height: 53px;
	background-color: transparent !important;
	color: #4b0b7f !important;
	font-size: 30px;
}
</style>
<script>
import CardsCarouselPrincipalXS from "./CardsCarruselPrincipalXS.vue";
export default {
	data: () => ({
		indice: 0,
		carruselPrincipal: {
			nombre: "",
			items: [
				// {
				// 	src: "https://niufleex.com/wp-content/uploads/2022/02/top-banner-app-elite-banner.jpg",
				// 	src_movil:
				// 		"https://niufleex.com/wp-content/uploads/2022/02/top-banner-movil-elite-400.jpg",
				// 	titulo1: "",
				// 	subtitulo: "",
				// 	img: "",
				// 	boton: "",
				// 	link: "#",
				// 	target: "_self",
				// 	interna: 1,
				// 	posicionimagen: "I",
				// },
				{
					src: "https://niufleex.com/wp-content/uploads/2022/11/top-banner-app-escritorio-sin-boton-min.png",
					src_movil:
						"https://niufleex.com/wp-content/uploads/2022/11/app-masterclass-con-boton-min.png",
					titulo1: "<br/> <br/>",
					subtitulo: "<br/> <br/>",
					img: "",
					boton: "",
					link: "/curso/a210590e-2a8e-43e1-bb61-dd786f61b8a0#title-curso",
					target: "",
					interna: 1,
					posicionimagen: "D",
				},
				{
					src: "https://niufleex.com/wp-content/uploads/2022/10/top-banner-app-escritorio-min-1.png",
					src_movil:
						"https://niufleex.com/wp-content/uploads/2022/10/app-con-boton-min-1.png",
					titulo1: "<br/> <br/>",
					subtitulo: "<br/> <br/>",
					img: "",
					boton: "",
					link: "/curso/8b270d11-14eb-4170-ae3b-eb2f39528217#title-curso",
					target: "",
					interna: 1,
					posicionimagen: "D",
				},
				{
					src: "https://niufleex.com/wp-content/uploads/2022/08/top-banner-app-escritorio-con-boton-curso-disponible-facebook-ads-min.png",
					src_movil:
						"https://niufleex.com/wp-content/uploads/2022/08/top-banner-APP-movil-con-boton-curso-disponible-facebook-ads-min.png",
					titulo1: "<br/> <br/>",
					subtitulo: "<br/> <br/>",
					img: "",
					boton: "",
					link: "/curso/7617fe31-50eb-4e4f-8de3-537adb523834#title-curso",
					target: "",
					interna: 1,
					posicionimagen: "D",
				},
				{
					src: "https://niufleex.com/wp-content/uploads/2022/06/top-banner-app-sin-boton-el-por-que.png",
					src_movil:
						"https://niufleex.com/wp-content/uploads/2022/06/top-banner-APP-movil-sin-boton-tu-por-que.png",
					titulo1: "<br/> <br/>",
					subtitulo: "<br/> <br/>",
					img: "",
					boton: "",
					link: "",
					target: "",
					interna: 1,
					posicionimagen: "D",
				},
				{
					src: "https://niufleex.com/wp-content/uploads/2022/05/top-banner-app-sin-boton-tiktok.png",
					src_movil:
						"https://niufleex.com/wp-content/uploads/2022/05/top-banner-APP-movil-sin-boton-tiktok.png",
					titulo1: "<br/> <br/>",
					subtitulo: "<br/> <br/>",
					img: "",
					boton: "",
					link: "",
					target: "",
					interna: 1,
					posicionimagen: "D",
				},
				{
					src: "https://niufleex.com/wp-content/uploads/2022/05/TOP-BANNER-SIN-BOTON.png",
					src_movil:
						"https://niufleex.com/wp-content/uploads/2022/05/top-banner-movil-sin-boton.png",
					titulo1: "<br/> <br/>",
					subtitulo: "<br/> <br/>",
					img: "",
					boton: "",
					link: "",
					target: "",
					interna: 1,
					posicionimagen: "D",
				},
				{
					src: "../../../images/base-top-lives.png",
					src_movil: "",

					titulo1:
						'<h2 style="color: white; font-family: Raleway, Sans-serif; font-size: 24px; font-weight: 800; line-height: 1.1em; letter-spacing: -0.7px; margin-bottom:10px;word-break: break-word;">NIUFLEEX Comunidad.</h2><h2 style="color: white; font-family: Poppins, Sans-serif; font-size: 24px; font-weight: bold;""> Aprende y Emprende </h2>',
					subtitulo:
						'<h2 style="color: #968AA0; font-family: Raleway, Sans-serif; font-size: 18px; font-weight: 500; line-height: 1em; letter-spacing: 0px;"> </h2>',
					img: "../../../images/isologoD.png",
					boton: "programación semanal",
					link: "/live",
					target: "_self",
					interna: 1,
					posicionimagen: "D",
				},
			],
		},
	}),
	components: {
		CardsCarouselPrincipalXS,
	},
	props: {
		bienvenida: {
			type: Object,
			default: () => {
				return {
					nombre: "",
					genero: "",
				};
			},
		},
	},
	mounted() {
		// eslint-disable-next-line no-console
		console.log("principalxs", this.carruselPrincipal.items);
	},
	methods: {
		next() {
			this.onboarding =
				this.onboarding + 1 === this.length ? 0 : this.onboarding + 1;
		},
		prev() {
			this.onboarding =
				this.onboarding - 1 < 0 ? this.length - 1 : this.onboarding - 1;
		},

		contador(i) {
			if (this.indice < i) return parseInt(this.indice++);
			else return this.indice;
		},
	},
};
</script>