<template>
	<v-container>
		<v-row>
			<v-col cols="12" style="padding-bottom: 10px">
				<h2
					style="
						font-family: 'Poppins', Sans-serif;
						font-size: 30px;
						font-weight: 600;
						line-height: 1em;
						letter-spacing: -0.7px;
						text-align: center;
					"
					:style="{
						color: theme.title,
					}"
				>
					{{ cardsEspecialistas.titulo }}
				</h2>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" style="padding-top: 0">
				<h2
					style="
						color: #525252;
						font-family: 'Raleway', Sans-serif;
						font-size: 18px;
						font-weight: 500;
						line-height: 1em;
						letter-spacing: 0px;
						text-align: center;
					"
				>
					{{ cardsEspecialistas.subtitulo }}
				</h2>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-carousel
					hide-delimiters
					style="box-shadow: 0px 0px; min-height: 550px"
					class="csscardsEspecialistasSM"
					@change="changeSlide"
				>
					<v-carousel-item
						v-for="i in Math.ceil(cardsEspecialistas.items.length / 2)"
						:key="i"
					>
						<v-layout row>
							<v-flex sm6 pl-2 pr-2>
								<CardsEspecialistas
									v-if="i * 2 - 2 < cardsEspecialistas.items.length"
									:nombre="cardsEspecialistas.items[i * 2 - 2].nombre"
									:facebook="cardsEspecialistas.items[i * 2 - 2].facebook"
									:instagram="cardsEspecialistas.items[i * 2 - 2].instagram"
									:especialidad="
										cardsEspecialistas.items[i * 2 - 2].especialidad
									"
									:resenia="cardsEspecialistas.items[i * 2 - 2].resenia"
									:avatar="cardsEspecialistas.items[i * 2 - 2].avatar"
									:link="cardsEspecialistas.items[i * 2 - 2].link"
									:target="cardsEspecialistas.items[i * 2 - 2].target"
									:interna="cardsEspecialistas.items[i * 2 - 2].interna"
									:boton="cardsEspecialistas.items[i * 2 - 2].boton"
									:tiktok="cardsEspecialistas.items[i * 2 - 2].tiktok"
									:youtube="cardsEspecialistas.items[i * 2 - 2].youtube"
								></CardsEspecialistas>
							</v-flex>
							<v-flex sm6 pl-2 pr-2>
								<CardsEspecialistas
									v-if="i * 2 - 1 < cardsEspecialistas.items.length"
									:nombre="cardsEspecialistas.items[i * 2 - 1].nombre"
									:facebook="cardsEspecialistas.items[i * 2 - 1].facebook"
									:instagram="cardsEspecialistas.items[i * 2 - 1].instagram"
									:especialidad="
										cardsEspecialistas.items[i * 2 - 1].especialidad
									"
									:resenia="cardsEspecialistas.items[i * 2 - 1].resenia"
									:avatar="cardsEspecialistas.items[i * 2 - 1].avatar"
									:link="cardsEspecialistas.items[i * 2 - 1].link"
									:target="cardsEspecialistas.items[i * 2 - 1].target"
									:interna="cardsEspecialistas.items[i * 2 - 1].interna"
									:boton="cardsEspecialistas.items[i * 2 - 1].boton"
									:tiktok="cardsEspecialistas.items[i * 2 - 1].tiktok"
									:youtube="cardsEspecialistas.items[i * 2 - 1].youtube"
								></CardsEspecialistas>
							</v-flex>
						</v-layout>
					</v-carousel-item>
				</v-carousel>

				<!--<p
					style="
						margin-top: -130px;
						text-align: right;
						margin-right: 30%;
						color: #525252;
						font-family: 'Poppins', Sans-serif;
						font-size: 18px;
						font-weight: 600;
						line-height: 1.2em;
						letter-spacing: -0.7px;
					"
				>
					{{ slide }} de {{ Math.ceil(cardsEspecialistas.items.length / 4) }}
				</p>-->
			</v-col>
		</v-row>
	</v-container>
</template>
<style>
.btntodocontenido {
	background-color: #fac903 !important;
	font-family: "Raleway", Sans-serif !important;
	font-size: 18px !important;
	font-weight: 800 !important;
	letter-spacing: 0px !important;
	fill: #541983 !important;
	color: #541983 !important;
	background-color: #fac903 !important;
	border-radius: 5px 5px 5px 5px !important;
	padding: 20px 40px 17px 40px !important;
	height: 55px !important;
}
.btntodocontenido:hover {
	color: #ffffff !important;
	background-color: #8208b1 !important;
}
.csscardsEspecialistasSM .v-window__prev {
	margin-top: 210px;
	margin-left: 75% !important;
}
.csscardsEspecialistasSM .v-window__next {
	margin-top: 210px;
	margin-right: 8% !important;
}
.csscardsEspecialistasSM .v-window__prev .v-btn {
  font-size: 17px !important;
  padding: 15px !important;
  border-width: 3px 3px 3px 3px !important;
  border-radius: 5px 5px 5px 5px !important;
  color: #272429 !important;
  border-color: #272429 !important;
  height: 53px !important;
  width: 53px !important;
  background-color: #f3f3f3 !important;
  border: solid !important;
}
.csscardsEspecialistasSM .v-window__next .v-btn {
  font-size: 17px !important;
  padding: 15px !important;
  border-width: 3px 3px 3px 3px !important;
  border-radius: 5px 5px 5px 5px !important;
  color: #272429 !important;
  border-color: #272429 !important;
  height: 53px !important;
  width: 53px !important;
  background-color: #f3f3f3 !important;
  border: solid !important;
}
.csscardsEspecialistasSM .v-window__next .v-btn span i{
	color:#272429 !important;
	font-size: 36px !important;		
}
.csscardsEspecialistasSM .v-window__prev .v-btn span i{
	color:#272429 !important;
	font-size: 36px !important;		
}
.csscardsEspecialistasSM .v-responsive__content .layout {
	padding-right: 35px;
	padding-left: 12px !important;
}

</style>
<script>
import { mapState } from "vuex";
import CardsEspecialistas from "./CardsEspecialistas.vue";
export default {
	name: "CardsEspecialistasSM",
	data() {
		return {
			indice: 0,
			cardsEspecialistas: {
				titulo: "Nuestros especialistas",
				subtitulo:
					"Especialistas, Mentores y guías. Conoce a quienes te harán subir al próximo nivel.",
				totEspecialistas: 5,
				cant: 3,
				items: [],
			},
			itemsExample: [
				{
					facebook: "",
					instagram: "",
					nombre: "Jaun Perez 1",
					especialidad: "Network Marketing",
					resenia: "Líder absoluto del mundo mundial",
					avatar: "https://cdn.vuetifyjs.com/images/john.jpg",
					link: "https://niufleex.com/",
					target: "_blank",
					interna: 0,
					boton: "ver contenido del especialista",
				},
				{
					facebook: "",
					instagram: "",
					nombre: "Jaun Perez 2",
					especialidad: "Network Marketing",
					resenia: "Líder absoluto del mundo mundial",
					avatar: "https://cdn.vuetifyjs.com/images/john.jpg",
					link: "https://niufleex.com/",
					target: "_blank",
					interna: 0,
					boton: "ver contenido del especialista",
				},
				{
					facebook: "",
					instagram: "",
					nombre: "Jaun Perez 3",
					especialidad: "Network Marketing",
					resenia: "Líder absoluto del mundo mundial",
					avatar: "https://cdn.vuetifyjs.com/images/john.jpg",
					link: "https://niufleex.com/",
					target: "_blank",
					interna: 0,
					boton: "ver contenido del especialista",
				},
				{
					facebook: "",
					instagram: "",
					nombre: "Jaun Perez 4",
					especialidad: "CONOCIMIENTO TÉCNICO",
					resenia: "Líder absoluto del mundo mundial",
					avatar: "https://cdn.vuetifyjs.com/images/john.jpg",
					link: "https://niufleex.com/",
					target: "_blank",
					interna: 0,
					boton: "ver contenido del especialista",
				},
				{
					facebook: "",
					instagram: "",
					nombre: "Jaun Perez 5",
					especialidad: "LIDERAZGO",
					resenia: "Líder absoluto del mundo mundial",
					avatar: "https://cdn.vuetifyjs.com/images/john.jpg",
					link: "https://niufleex.com/",
					target: "_blank",
					interna: 0,
					boton: "ver contenido del especialista",
				},
				{
					facebook: "",
					instagram: "",
					nombre: "Jaun Perez 6",
					especialidad: "EMPRENDIMIENTO Y NEGOCIOS",
					resenia: "Líder absoluto del mundo mundial",
					avatar: "https://cdn.vuetifyjs.com/images/john.jpg",
					link: "https://niufleex.com/",
					target: "_blank",
					interna: 0,
					boton: "ver contenido del especialista",
				},
			],
			slide: 0,
		};
	},
	computed: {
		...mapState(["theme"]),
	},
	components: {
		CardsEspecialistas,
	},
	async mounted() {
		try {
			let result = (await this.$http.get("especialistas/list")).data;
			this.cardsEspecialistas.items = result;
			// eslint-disable-next-line no-empty
		} catch (error) {}
	},
	methods: {
		changeSlide(event) {
			// eslint-disable-next-line no-console
			console.log(event);
			this.slide = event + 1;
		},
		contador(i) {
			if (this.indice < i) parseInt(this.indice++);
			else this.indice = 0;
		},
	},
};
</script>