var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.cardsContRep.items.length > 0)?_c('v-container',{staticStyle:{"padding-top":"100px"},attrs:{"light":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticStyle:{"text-align":"center","padding-right":"0px"},attrs:{"cols":"3"}},[_c('v-icon',{staticStyle:{"width":"53px !important","margin-top":"50px","height":"53px !important","margin-bottom":"20px !important","border-radius":"50% !important"},style:({
						backgroundColor: _vm.premium
							? '#272429 !important'
							: 'rgb(106 40 159) !important',
						color: _vm.premium
							? '#E6DEB5 !important'
							: 'rgb(251, 149, 12) !important',
						border: _vm.premium
							? 'solid #E6DEB5 !important'
							: 'solid #fb950c !important',
					})},[_vm._v("mdi-bookmark")]),_c('h2',{staticStyle:{"color":"#ffffff !important","font-family":"'Poppins', Sans-serif !important","font-size":"30px !important","font-weight":"600 !important","line-height":"1em !important","margin-bottom":"20px !important","letter-spacing":"-0.7px !important"}},[_vm._v(" Continuar reproduciendo ")]),_c('p',{staticStyle:{"color":"#E6DEB5","font-family":"Raleway, Sans-serif","font-size":"17px","font-weight":"500","line-height":"1em","letter-spacing":"0px"}},[_vm._v(" Ordenar por ")]),_c('v-combobox',{attrs:{"items":_vm.itemscombo,"light":"","outlined":"","dense":""},model:{value:(_vm.select),callback:function ($$v) {_vm.select=$$v},expression:"select"}}),_c('v-btn',{staticStyle:{"background-color":"#E6DEB5","color":"#3E3E3E","font-weight":"700"},on:{"click":_vm.orderby}},[_vm._v("VER")])],1),_c('v-col',{attrs:{"cols":"9"}},[_c('v-flex',[_c('v-carousel',{staticClass:"csscontinuarpremiummd",staticStyle:{"box-shadow":"0px 0px"},attrs:{"light":"","hide-delimiters":"","height":"600px"},on:{"change":_vm.changeSlide}},_vm._l((Math.ceil(_vm.cardsContRep.items.length / 3)),function(i){return _c('v-carousel-item',{key:i,attrs:{"light":"","height":"600px"}},[_c('v-layout',{staticStyle:{"padding":"0 10px","height":"600px"},attrs:{"row":""}},[(
										i * 3 - 3 < _vm.cardsContRep.items.length &&
										_vm.cardsContRep.items[i * 3 - 3].src
									)?_c('v-flex',{attrs:{"sm4":"","pl-2":"","pr-2":"","mt-5":""}},[(i * 3 - 3 < _vm.cardsContRep.items.length)?_c('Cards1',{attrs:{"accion":"continuar","boton":"continuar","path":_vm.cardsContRep.items[i * 3 - 3].src,"estudiantes":_vm.cardsContRep.items[i * 3 - 3].estudiantes,"puntuacion":_vm.cardsContRep.items[i * 3 - 3].puntuacion,"nombre":_vm.cardsContRep.items[i * 3 - 3].nombre,"resenia":_vm.cardsContRep.items[i * 3 - 3].resenia,"especialista":_vm.cardsContRep.items[i * 3 - 3].especialista,"sesiones":_vm.cardsContRep.items[i * 3 - 3].sesiones,"minutos":_vm.cardsContRep.items[i * 3 - 3].minutos,"video_type_track_ruta":_vm.cardsContRep.items[i * 3 - 3].video_type_track_ruta,"trackCode":_vm.cardsContRep.items[i * 3 - 3].trackCode,"video_id":_vm.cardsContRep.items[i * 3 - 3].video_id,"area":_vm.cardsContRep.items[i * 3 - 3].area}}):_vm._e()],1):_vm._e(),(
										i * 3 - 2 < _vm.cardsContRep.items.length &&
										_vm.cardsContRep.items[i * 3 - 2].src
									)?_c('v-flex',{attrs:{"sm4":"","pl-2":"","pr-2":"","mt-5":""}},[(i * 3 - 2 < _vm.cardsContRep.items.length)?_c('Cards1',{attrs:{"accion":"continuar","boton":"continuar","path":_vm.cardsContRep.items[i * 3 - 2].src,"estudiantes":_vm.cardsContRep.items[i * 3 - 2].estudiantes,"puntuacion":_vm.cardsContRep.items[i * 3 - 2].puntuacion,"nombre":_vm.cardsContRep.items[i * 3 - 2].nombre,"resenia":_vm.cardsContRep.items[i * 3 - 2].resenia,"especialista":_vm.cardsContRep.items[i * 3 - 2].especialista,"sesiones":_vm.cardsContRep.items[i * 3 - 2].sesiones,"minutos":_vm.cardsContRep.items[i * 3 - 2].minutos,"video_type_track_ruta":_vm.cardsContRep.items[i * 3 - 2].video_type_track_ruta,"trackCode":_vm.cardsContRep.items[i * 3 - 2].trackCode,"video_id":_vm.cardsContRep.items[i * 3 - 2].video_id,"area":_vm.cardsContRep.items[i * 3 - 2].area}}):_vm._e()],1):_vm._e(),(
										i * 3 - 1 < _vm.cardsContRep.items.length &&
										_vm.cardsContRep.items[i * 3 - 1].src
									)?_c('v-flex',{attrs:{"sm4":"","pl-2":"","pr-2":"","mt-5":""}},[(i * 3 - 1 < _vm.cardsContRep.items.length)?_c('Cards1',{attrs:{"accion":"continuar","boton":"continuar","path":_vm.cardsContRep.items[i * 3 - 1].src,"estudiantes":_vm.cardsContRep.items[i * 3 - 1].estudiantes,"puntuacion":_vm.cardsContRep.items[i * 3 - 1].puntuacion,"nombre":_vm.cardsContRep.items[i * 3 - 1].nombre,"resenia":_vm.cardsContRep.items[i * 3 - 1].resenia,"especialista":_vm.cardsContRep.items[i * 3 - 1].especialista,"sesiones":_vm.cardsContRep.items[i * 3 - 1].sesiones,"minutos":_vm.cardsContRep.items[i * 3 - 1].minutos,"video_type_track_ruta":_vm.cardsContRep.items[i * 3 - 1].video_type_track_ruta,"trackCode":_vm.cardsContRep.items[i * 3 - 1].trackCode,"video_id":_vm.cardsContRep.items[i * 3 - 1].video_id,"area":_vm.cardsContRep.items[i * 3 - 1].area}}):_vm._e()],1):_vm._e()],1)],1)}),1),_c('p',{staticStyle:{"margin-top":"-125px","text-align":"right","margin-right":"30%","color":"#E6DEB5 !important","font-family":"'Poppins', Sans-serif","font-size":"18px","font-weight":"600","line-height":"1.2em","letter-spacing":"-0.7px"}},[_vm._v(" "+_vm._s(_vm.slide)+" de "+_vm._s(Math.ceil(_vm.cardsContRep.items.length / 3))+" Módulos pendientes ")])],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }