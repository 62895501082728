<template>
	<v-container>
		<v-row>
			<v-col cols="12" style="padding-bottom: 10px; padding-top:50px;">
				<h2
					style="
						font-family: 'Poppins', Sans-serif;
						font-size: 30px;
						font-weight: 600;
						line-height: 1em;
						letter-spacing: -0.7px;
					"
					:style="{
						color: theme.title,
					}"
				>
					{{ cardsEspecialistas.titulo }}
				</h2>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" style="padding-top: 0">
				<h2
					style="
						color: #525252;
						font-family: 'Raleway', Sans-serif;
						font-size: 18px;
						font-weight: 500;
						line-height: 1em;
						letter-spacing: 0px;
					"
				>
					{{ cardsEspecialistas.subtitulo }}
				</h2>
			</v-col>
		</v-row>
		<v-row style="display: none">
			<v-col clos="12">
				<v-btn
					v-for="p in areas2.length"
					:key="p"
					style="margin-left: 0; margin-right: 20px"
					@click="filtroCards2(areas2[p - 1], p)"
					:id="'btnes-' + p"
					class="btnfiltro1ES"
					>{{ areas2[p - 1] }}
				</v-btn>
				<v-btn
					@click="filtroCards()"
					id="btnTodasEspActiveES"
					style="color: #ffffff; background-color: #fb950c"
					class="btnfiltro1ES"
					>Todas las Areas
				</v-btn>
				<v-btn
					@click="filtroCards()"
					id="btnTodasEspNoActiveES"
					style="color: black; background-color: #f3f3f3"
					class="btnfiltro1ES"
					>Todas las Areas
				</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-carousel
					hide-delimiters
					style="box-shadow: 0px 0px"
					:style="
						$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
							? 'min-height: 450px !important;'
							: 'min-height: 550px !important;'
					"
					:height="
						$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
							? '500px'
							: '600px'
					"
					class="csscardsEspecialistas"
					@change="changeSlide"
				>
					<v-carousel-item
						v-for="i in Math.ceil(itemsfilters.length / 4)"
						:key="i"
					>
						<v-layout row>
							<v-flex sm3 pl-2 pr-1>
								<CardsEspecialistas
									v-if="i * 4 - 4 < itemsfilters.length"
									:nombre="itemsfilters[i * 4 - 4].nombre"
									:facebook="itemsfilters[i * 4 - 4].facebook"
									:instagram="itemsfilters[i * 4 - 4].instagram"
									:especialidad="itemsfilters[i * 4 - 4].especialidad"
									:resenia="itemsfilters[i * 4 - 4].resenia"
									:avatar="itemsfilters[i * 4 - 4].avatar"
									:link="itemsfilters[i * 4 - 4].link"
									:target="itemsfilters[i * 4 - 4].target"
									:interna="itemsfilters[i * 4 - 4].interna"
									:boton="itemsfilters[i * 4 - 4].boton"
									:tiktok="itemsfilters[i * 4 - 4].tiktok"
									:youtube="itemsfilters[i * 4 - 4].youtube"
								></CardsEspecialistas>
							</v-flex>
							<v-flex sm3 pl-2 pr-2>
								<CardsEspecialistas
									v-if="i * 4 - 3 < itemsfilters.length"
									:nombre="itemsfilters[i * 4 - 3].nombre"
									:facebook="itemsfilters[i * 4 - 3].facebook"
									:instagram="itemsfilters[i * 4 - 3].instagram"
									:especialidad="itemsfilters[i * 4 - 3].especialidad"
									:resenia="itemsfilters[i * 4 - 3].resenia"
									:avatar="itemsfilters[i * 4 - 3].avatar"
									:link="itemsfilters[i * 4 - 3].link"
									:target="itemsfilters[i * 4 - 3].target"
									:interna="itemsfilters[i * 4 - 3].interna"
									:boton="itemsfilters[i * 4 - 3].boton"
									:tiktok="itemsfilters[i * 4 - 3].tiktok"
									:youtube="itemsfilters[i * 4 - 3].youtube"
								></CardsEspecialistas>
							</v-flex>
							<v-flex sm3 pl-2 pr-2>
								<CardsEspecialistas
									v-if="i * 4 - 2 < itemsfilters.length"
									:nombre="itemsfilters[i * 4 - 2].nombre"
									:facebook="itemsfilters[i * 4 - 2].facebook"
									:instagram="itemsfilters[i * 4 - 2].instagram"
									:especialidad="itemsfilters[i * 4 - 2].especialidad"
									:resenia="itemsfilters[i * 4 - 2].resenia"
									:avatar="itemsfilters[i * 4 - 2].avatar"
									:link="itemsfilters[i * 4 - 2].link"
									:target="itemsfilters[i * 4 - 2].target"
									:interna="itemsfilters[i * 4 - 2].interna"
									:boton="itemsfilters[i * 4 - 2].boton"
									:tiktok="itemsfilters[i * 4 - 2].tiktok"
									:youtube="itemsfilters[i * 4 - 2].youtube"
								></CardsEspecialistas>
							</v-flex>
							<v-flex sm3 pl-2 pr-2>
								<CardsEspecialistas
									v-if="i * 4 - 1 < itemsfilters.length"
									:nombre="itemsfilters[i * 4 - 1].nombre"
									:facebook="itemsfilters[i * 4 - 1].facebook"
									:instagram="itemsfilters[i * 4 - 1].instagram"
									:especialidad="itemsfilters[i * 4 - 1].especialidad"
									:resenia="itemsfilters[i * 4 - 1].resenia"
									:avatar="itemsfilters[i * 4 - 1].avatar"
									:link="itemsfilters[i * 4 - 1].link"
									:target="itemsfilters[i * 4 - 1].target"
									:interna="itemsfilters[i * 4 - 1].interna"
									:boton="itemsfilters[i * 4 - 1].boton"
									:tiktok="itemsfilters[i * 4 - 1].tiktok"
									:youtube="itemsfilters[i * 4 - 1].youtube"
								></CardsEspecialistas>
							</v-flex>
						</v-layout>
					</v-carousel-item>
				</v-carousel>

				<p
					style="
						margin-top: -114px;
						text-align: right;
						margin-right: 23%;
						color: #525252;
						font-family: 'Poppins', Sans-serif;
						font-size: 18px;
						font-weight: 600;
						line-height: 1.2em;
						letter-spacing: -0.7px;
					"
				>
					{{ slide }} de {{ Math.ceil(itemsfilters.length / 4) }} Especialistas
				</p>
			</v-col>
		</v-row>
	</v-container>
</template>
<style>
.btnfiltro1ES {
	font-family: "Raleway", Sans-serif !important;
	font-size: 13px !important;
	font-weight: 600 !important;
	letter-spacing: 0px !important;
	fill: #a2a0a0;
	color: #a2a0a0;
	background-color: #f3f3f3;
	border-style: solid !important;
	border-width: 1px 1px 1px 1px !important;
	border-color: #a2a0a0 !important;
	border-radius: 30px 30px 30px 30px !important;
	padding: 17px 14px 17px 15px !important;
	margin-left: 50px;
}
.btnfiltro1ES:hover {
	color: #ffffff !important;
	background-color: #fb950c !important;
}
.btnfiltro1activeES {
	color: #ffffff !important;
	background-color: #fb950c !important;

	font-family: "Raleway", Sans-serif !important;
	font-size: 13px !important;
	font-weight: 600 !important;
	letter-spacing: 0px !important;
	border-style: solid !important;
	border-width: 1px 1px 1px 1px !important;
	border-color: #a2a0a0 !important;
	border-radius: 30px 30px 30px 30px !important;
	padding: 17px 14px 17px 15px !important;
	margin-left: 50px;
}
.btntodocontenido {
	background-color: #fac903 !important;
	font-family: "Raleway", Sans-serif !important;
	font-size: 18px !important;
	font-weight: 800 !important;
	letter-spacing: 0px !important;
	fill: #541983 !important;
	color: #541983 !important;
	background-color: #fac903 !important;
	border-radius: 5px 5px 5px 5px !important;
	padding: 20px 40px 17px 40px !important;
	height: 55px !important;
}
.btntodocontenido:hover {
	color: #ffffff !important;
	background-color: #8208b1 !important;
}
.csscardsEspecialistas .v-window__prev {
	margin-top: 190px !important;
	margin-left: 80% !important;
}
.csscardsEspecialistas .v-window__next {
	margin-top: 190px !important;
	margin-right: 7% !important;
}
.csscardsEspecialistas .v-window__prev .v-btn {
	font-size: 17px !important;
    padding: 15px !important;
    border-width: 3px 3px 3px 3px !important;
    border-radius: 5px 5px 5px 5px !important;
    color: #272429 !important;
    border-color: #272429 !important;
    height: 53px;
    width: 53px;
	background-color: #f3f3f3;
    border: solid;
}
.csscardsEspecialistas .v-window__next .v-btn {
	font-size: 17px !important;
    padding: 15px !important;
    border-width: 3px 3px 3px 3px !important;
    border-radius: 5px 5px 5px 5px !important;
    color: #272429 !important;
    border-color: #272429 !important;
    height: 53px;
    width: 53px;
	background-color: #f3f3f3;
    border: solid;
}
.csscardsEspecialistas .v-window__next .v-btn span i{
	color:#272429 !important;
	font-size: 36px !important;		
}
.csscardsEspecialistas .v-window__prev .v-btn span i{
	color:#272429 !important;
	font-size: 36px !important;		
}
.csscardsEspecialistas .v-responsive__content .layout {
	padding-right: 35px;
	padding-top: 20px;
}
.csscardsEspecialistas .v-carousel__item {
	padding-right: 10px !important;
}
</style>
<script>
import CardsEspecialistas from "./CardsEspecialistas.vue";
import { mapState } from "vuex";
export default {
	data() {
		return {
			indice: 0,
			areas2: [],
			itemsfilters: [],
			cardsEspecialistas: {
				titulo: "Nuestros especialistas",
				subtitulo:
					"Especialistas, Mentores y guías. Conoce a quienes te harán subir al próximo nivel.",
				totEspecialistas: 5,
				cant: 3,
				items: [],
			},
			itemsExample: [
				{
					facebook: "",
					instagram: "",
					nombre: "Jaun Perez 1",
					especialidad: "Network Marketing",
					resenia: "Líder absoluto del mundo mundial",
					avatar: "https://cdn.vuetifyjs.com/images/john.jpg",
					link: "https://niufleex.com/",
					target: "_blank",
					interna: 0,
					boton: "ver contenido del especialista",
				},
				{
					facebook: "",
					instagram: "",
					nombre: "Jaun Perez 2",
					especialidad: "Network Marketing",
					resenia: "Líder absoluto del mundo mundial",
					avatar: "https://cdn.vuetifyjs.com/images/john.jpg",
					link: "https://niufleex.com/",
					target: "_blank",
					interna: 0,
					boton: "ver contenido del especialista",
				},
				{
					facebook: "",
					instagram: "",
					nombre: "Jaun Perez 3",
					especialidad: "Network Marketing",
					resenia: "Líder absoluto del mundo mundial",
					avatar: "https://cdn.vuetifyjs.com/images/john.jpg",
					link: "https://niufleex.com/",
					target: "_blank",
					interna: 0,
					boton: "ver contenido del especialista",
				},
				{
					facebook: "",
					instagram: "",
					nombre: "Jaun Perez 4",
					especialidad: "CONOCIMIENTO TÉCNICO",
					resenia: "Líder absoluto del mundo mundial",
					avatar: "https://cdn.vuetifyjs.com/images/john.jpg",
					link: "https://niufleex.com/",
					target: "_blank",
					interna: 0,
					boton: "ver contenido del especialista",
				},
				{
					facebook: "",
					instagram: "",
					nombre: "Jaun Perez 5",
					especialidad: "LIDERAZGO",
					resenia: "Líder absoluto del mundo mundial",
					avatar: "https://cdn.vuetifyjs.com/images/john.jpg",
					link: "https://niufleex.com/",
					target: "_blank",
					interna: 0,
					boton: "ver contenido del especialista",
				},
				{
					facebook: "",
					instagram: "",
					nombre: "Jaun Perez 6",
					especialidad: "EMPRENDIMIENTO Y NEGOCIOS",
					resenia: "Líder absoluto del mundo mundial",
					avatar: "https://cdn.vuetifyjs.com/images/john.jpg",
					link: "https://niufleex.com/",
					target: "_blank",
					interna: 0,
					boton: "ver contenido del especialista",
				},
			],
			slide: 0,
		};
	},
	computed: {
		...mapState(["theme"]),
	},
	components: {
		CardsEspecialistas,
	},
	methods: {
		changeSlide(event) {
			// eslint-disable-next-line no-console
			this.slide = event + 1;
		},
		contador(i) {
			if (this.indice < i) parseInt(this.indice++);
			else this.indice = 0;
		},
		filtroCards2(nombre, p) {
			this.itemsfilters.splice(0, 100);
			if (nombre == "Todas las Areas") {
				this.cardsEspecialistas.items.forEach((element) => {
					this.itemsfilters.push(element);
				});
			} else {
				this.cardsEspecialistas.items.forEach((element) => {
					if (element.especialidad == nombre) {
						this.itemsfilters.push(element);
					}
				});
			}
			for (let index = 1; index < this.areas2.length + 1; index++) {
				document.getElementById("btnes-" + index).style.backgroundColor =
					"#ffffff";
				document.getElementById("btnes-" + index).style.color = "black";
			}
			document.getElementById("btnes-" + p).style.backgroundColor = "#fb950c";
			document.getElementById("btnes-" + p).style.color = "white";
			document.getElementById("btnTodasEspActiveES").style.display = "none";
			document.getElementById("btnTodasEspNoActiveES").style.display =
				"inline-flex";
		},
		filtroCards() {
			this.itemsfilters.splice(0, 100);

			this.cardsEspecialistas.items.forEach((element) => {
				this.itemsfilters.push(element);
			});

			for (let index = 1; index < this.areas2.length + 1; index++) {
				document.getElementById("btnes-" + index).style.backgroundColor =
					"#ffffff";
				document.getElementById("btnes-" + index).style.color = "black";
			}

			document.getElementById("btnTodasEspActiveES").style.display =
				"inline-flex";
			document.getElementById("btnTodasEspNoActiveES").style.display = "none";
		},
	},
	async mounted() {
		try {
			let result = (await this.$http.get("/especialistas/list")).data;
			this.cardsEspecialistas.items = result;
			// eslint-disable-next-line no-empty
		} catch (error) {}
		for (let index = 0; index < this.cardsEspecialistas.items.length; index++) {
			this.areas2.push(this.cardsEspecialistas.items[index].especialidad);
		}
		this.areas2 = [...new Set(this.areas2)];
		for (let index = 0; index < this.cardsEspecialistas.items.length; index++) {
			this.itemsfilters.push(this.cardsEspecialistas.items[index]);
		}

		document.getElementById("btnTodasEspNoActiveES").style.display = "none";
	},
};
</script>