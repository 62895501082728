var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.modulos),function(item,i){return _c('v-card',{key:i,staticClass:"mx-auto",staticStyle:{"margin-top":"15px"},style:({
			backgroundColor: _vm.theme.backCardModulos,
		})},[_c('v-card-title',{staticStyle:{"padding-bottom":"0px","heigh":"45px"}},[_c('p',[_c('span',{staticStyle:{"color":"#fb950c","font-family":"Poppins, Sans-serif","font-size":"14px","font-weight":"600","line-height":"1.2em","letter-spacing":"-0.3px","word-break":"break-word"}},[_vm._v("Módulo "+_vm._s(item.modulo_orden)+":")]),_c('span',{staticStyle:{"text-align":"left","color":"#ffffff","font-family":"Poppins, Sans-serif","font-size":"14px","font-weight":"600","line-height":"1.2em","letter-spacing":"-0.3px","word-break":"break-word"}},[_vm._v(" "+_vm._s(item.modulo_title))])])]),_vm._l((item.sesiones),function(sesionitem,j){return _c('div',{key:j},[_c('v-card-subtitle',{staticClass:"session",class:{
					active: _vm.hash() == sesionitem.video_id,
				},attrs:{"id":sesionitem.video_id},on:{"click":function($event){return _vm.btnAccion_click(sesionitem)}}},[_c('i',{staticClass:"fas fa-play hijos",staticStyle:{"color":"white","font-size":"14px"}}),_c('strong',{staticStyle:{"align-self":"center !important","font-family":"Raleway, Sans-serif !important","font-size":"15px !important","font-weight":"600 !important"}},[_c('span',{staticStyle:{"padding-left":"5px !important","color":"#fb950c","font-family":"Raleway, Sans-serif !important"}},[_vm._v(" Sesión "+_vm._s(sesionitem.video_orden)+":")]),_c('span',{staticClass:"hijos",staticStyle:{"padding-left":"5px !important","color":"white","font-family":"Raleway, Sans-serif !important","word-break":"break-word"}},[_vm._v(_vm._s(sesionitem.video_title))])])]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticStyle:{"vertical-align":"middle","display":"inline-flex"},attrs:{"cols":"12"}},[(
								!(
									sesionitem.contenido_complementario &&
									sesionitem.contenido_complementario.length > 0
								)
							)?_c('a',{attrs:{"href":"#"}},[_c('span',{staticClass:"far fa-arrow-alt-circle-down icon-recursos inactive",attrs:{"title":"NO SE INCLUYEN RECURSOS"}},[_c('span',{staticClass:"recursos inactive"},[_vm._v("   RECURSOS")])])]):_c('v-menu',{attrs:{"transition":"slide-x-transition","bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
							var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"plain":""}},'v-btn',attrs,false),on),[_c('span',{staticClass:"\n\t\t\t\t\t\t\t\t\t\t\tfar\n\t\t\t\t\t\t\t\t\t\t\tfa-arrow-alt-circle-down\n\t\t\t\t\t\t\t\t\t\t\ticon-recursos\n\t\t\t\t\t\t\t\t\t\t\tinactive\n\t\t\t\t\t\t\t\t\t\t"},[_c('span',{staticClass:"recursos"},[_vm._v("   RECURSOS")])])])]}}],null,true)},[_c('v-list',_vm._l((sesionitem.contenido_complementario),function(resource,index){return _c('v-list-item',{key:'resource' + index},[_c('v-list-item-title',[_c('v-btn',{attrs:{"to":resource.contenido}},[_c('v-icon',{domProps:{"textContent":_vm._s(resource.icon)}}),_vm._v(" "+_vm._s(resource.name)+" ")],1)],1)],1)}),1)],1),(sesionitem.tiempo)?_c('h2',{staticStyle:{"color":"#908d9a","font-family":"Raleway, Sans-serif","font-size":"16px","font-weight":"700","line-height":"1em","letter-spacing":"0px","margin-top":"10px","margin-left":"25px"}},[_vm._v(" "+_vm._s(sesionitem.tiempo)+" ")]):_vm._e(),(sesionitem.avance)?_c('h2',{staticStyle:{"color":"#908d9a","font-family":"Raleway, Sans-serif","font-size":"16px","font-weight":"700","line-height":"1em","letter-spacing":"0px","margin-top":"10px","margin-left":"25px"}},[_vm._v(" "+_vm._s(sesionitem.avance)+" ")]):_vm._e()],1)],1)],1)],1)})],2)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }