<template>
	<v-row>
		<v-col cols="12" style="text-align: center">
			<v-icon
				style="
					width: 53px !important;
					height: 53px !important;
					margin-bottom: 20px !important;
					background-color: rgb(106 40 159) !important;
					color: rgb(251, 149, 12) !important;
					border-radius: 50% !important;
					border: solid #fb950c !important;
				"
				>mdi-bookmark</v-icon
			>
			<h2
				style="
					color: #ffffff !important;
					font-family: 'Poppins', Sans-serif !important;
					font-size: 30px !important;
					font-weight: 600 !important;
					line-height: 1em !important;
					margin-bottom: 20px !important;
					letter-spacing: -0.7px !important;
				"
			>
				Continuar reproduciendo
			</h2>
				<p
					style="
						color: #fb950c;
						font-family: 'Raleway', Sans-serif;
						font-size: 17px;
						font-weight: 500;
						line-height: 1em;
						letter-spacing: 0px;
						margin-bottom: 20px !important;
					"
				>
					Ordenar por
				</p>
				<v-combobox
					v-model="select"
					:items="itemscombo"
					light
					outlined
					dense
					
				></v-combobox>
				<v-btn style="background-color: #fb950c; color: #ffffff" @click="orderby">VER</v-btn>
		</v-col>
		<v-col cols="12">
			<v-flex>
				<v-carousel
					hide-delimiters
					style="box-shadow: 0px 0px; height: 600px;"
					class="csscontinuarpremiumxs"
					v-if="
						cardsContRep &&
						cardsContRep.items &&
						cardsContRep.items.length > 0 &&
						cardsContRep.items[0].src
					"
				>
					<v-carousel-item v-for="i in cardsContRep.items.length" :key="i">
						<v-layout row>
							<v-flex v-for="j in 1" :key="j" pl-2 pr-2>
								<Cards1
									accion="continuar"
									boton="continuar"
									:path="cardsContRep.items[indice].src"
									:estudiantes="cardsContRep.items[indice].estudiantes"
									:puntuacion="cardsContRep.items[indice].puntuacion"
									:nombre="cardsContRep.items[indice].nombre"
									:resenia="cardsContRep.items[indice].resenia"
									:especialista="cardsContRep.items[indice].especialista"
									:sesiones="cardsContRep.items[indice].sesiones"
									:minutos="cardsContRep.items[indice].minutos"
									:video_type_track_ruta="
										cardsContRep.items[indice].video_type_track_ruta
									"
									:trackCode="cardsContRep.items[indice].trackCode"
									:video_id="cardsContRep.items[indice].video_id"
								></Cards1>
								<p style="display: none">
									{{ contador(cardsContRep.items.length) }}
								</p>
							</v-flex>
						</v-layout>
					</v-carousel-item>
				</v-carousel>
			</v-flex>
		</v-col>
		<!-- <v-col style="margin-top: -155px">
      <p
        style="
          text-align: center;
          color: #ffffff;
          font-family: 'Poppins', Sans-serif;
          font-size: 18px;
          font-weight: 600;
          line-height: 1.2em;
          letter-spacing: -0.7px;
        "
      >
          {{ this.cardsContRep.cantPendientes }} de {{ this.cardsContRep.totCurso }} Módulos pendientes
      </p>
    </v-col> -->
	</v-row>
</template>
<style>
.csscontinuarpremiumxs .v-window__next .v-btn span i{
	color:#E6DEB5 !important;
	font-size: 36px !important;	
}
.csscontinuarpremiumxs .v-window__prev .v-btn span i{
	color:#E6DEB5 !important;
	font-size: 36px !important;	
}
.csscontinuarpremiumxs .v-image{
	padding-top: 20px;
}
.csscontinuarpremiumxs .v-window__prev {
	margin-top: 180px !important;
	margin-left: 30% !important;
}
.csscontinuarpremiumxs .v-window__next {
	margin-top: 180px !important;
	margin-right: 30% !important;
}
.csscontinuarpremiumxs .v-window__prev .v-btn {
	font-size: 17px !important;
    padding: 15px !important;
    border-width: 3px 3px 3px 3px !important;
    border-radius: 5px 5px 5px 5px !important;
    color: #E6DEB5 !important;
    border-color: #E6DEB5 !important;
    height: 53px;
    width: 53px;
	background-color: #272429;
    border: solid;
}
.csscontinuarpremiumxs .v-window__next .v-btn {
	font-size: 17px !important;
    padding: 15px !important;
    border-width: 3px 3px 3px 3px !important;
    border-radius: 5px 5px 5px 5px !important;
    color: #E6DEB5 !important;
    border-color: #E6DEB5 !important;
    height: 53px;
    width: 53px;
	background-color: #272429;
    border: solid;
}
.csscontinuarpremiumxs .v-responsive__content .layout {
	padding-right: 20px !important;
	padding-left: 3px;
}
</style>
<script>
import apiVimeo from "../../../../services/apiVimeo";
import Cards1 from "./CardsContinuarReproduciendo.vue";
export default {
	data() {
		return {
			indice: 0,
			cardsContRep: {
				items: null,
			},
			select: "Descendente",
			itemscombo: ["Ascendente", "Descendente"],
			cardsContRepExample: {
				totCurso: "10",
				cantPendientes: "3",
				items: [
					{
						src: "https://niufleex.com/wp-content/uploads/2022/01/s3.jpg",
						estudiantes: "1.456",
						puntuacion: "4.7",
						nombre: "MÓDULO 1",
						resenia: "La Neta del Planeta es la meta",
						especialista: "Valentina Góngora",
						sesiones: "2",
						minutos: "137",
					},
					{
						src: "https://niufleex.com/wp-content/uploads/2021/12/2.PORTADA-VENTAS-POR-WHATSAPP.png",
						estudiantes: "2.584",
						puntuacion: "3.8",
						nombre: "MÓDULO 4",
						resenia: "El Arte de dejar los vistos",
						especialista: "Valentina Góngora",
						sesiones: "2",
						minutos: "137",
					},
					{
						src: "https://niufleex.com/wp-content/uploads/2021/12/CLIENTES-POTENCIALES.jpg",
						estudiantes: "842",
						puntuacion: "4.9",
						nombre: "MÓDULO 9",
						resenia: "Funcionalidades de WhatsApp Business",
						especialista: "Valentina Góngora",
						sesiones: "2",
						minutos: "137",
					},
					{
						src: "https://niufleex.com/wp-content/uploads/2022/01/s3.jpg",
						estudiantes: "1.456",
						puntuacion: "4.7",
						nombre: "MÓDULO 20",
						resenia: "La Neta del Planeta es la meta",
						especialista: "Valentina Góngora",
						sesiones: "2",
						minutos: "137",
					},
					{
						src: "https://niufleex.com/wp-content/uploads/2021/12/2.PORTADA-VENTAS-POR-WHATSAPP.png",
						estudiantes: "2.584",
						puntuacion: "3.8",
						nombre: "MÓDULO 21",
						resenia: "El Arte de dejar los vistos",
						especialista: "Valentina Góngora",
						sesiones: "2",
						minutos: "137",
					},
					{
						src: "https://niufleex.com/wp-content/uploads/2021/12/CLIENTES-POTENCIALES.jpg",
						estudiantes: "842",
						puntuacion: "4.9",
						nombre: "MÓDULO 22",
						resenia: "Funcionalidades de WhatsApp Business",
						especialista: "Valentina Góngora",
						sesiones: "2",
						minutos: "137",
					},
				],
			},
		};
	},
	components: {
		Cards1,
	},
	async mounted() { 
		try {
			this.cardsContRep = {
			...this.cardsContRep,
			...(await this.$http.get(`/videos/newlastviews`)).data,
		};
		// eslint-disable-next-line no-empty
		} catch (error) {
			
		}
		for (const element of this.cardsContRep.items) {
			let uno = (await apiVimeo(element.idvideo)).data;
			element.src = uno
				.find((ele) => ele.active == true)
				.sizes.find((ele) => ele.width == 1280).link;
		}
		// eslint-disable-next-line no-console
		console.log(this.cardsContRep);
		window.cars = this.cardsContRep;
	},
	methods: {
		orderby() {
			// eslint-disable-next-line no-console
			console.log(this.select);
			this.sortContinuar = this.select == "Ascendente";
			if (this.sortContinuar) {
				this.cardsContRep.items.sort((a, b) => {
					return Date.parse(a.videos_modified) - Date.parse(b.videos_modified);
				});
				this.sortContinuar = false;
			} else {
				this.cardsContRep.items.sort((a, b) => {
					return Date.parse(b.videos_modified) - Date.parse(a.videos_modified);
				});
				this.sortContinuar = true;
			}
		},
		contador(i) {
			if (this.indice < i) return parseInt(this.indice++);
			else return this.indice;
		},
	},
};
</script>