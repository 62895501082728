<template>
  <v-card
    class="mx-auto fill-height"
    max-width="400"
    style="
      border-radius: 15px;
      width: 100%;
      max-width: 100%;
      min-height: 345px;
      margin-left: 10px !important;
      height: 330.17px;
    "
    light
  >
    <v-img
      class="white--text align-end over"
      height="160px"
      :src="path"
      style="margin-top: 20px"
      @click="ruta()"
      light
    >
      <!-- <v-card-title style="padding-left: 0">
        <v-chip
          class="ma-2"
          label
          style="
            margin-left: 7px !important;
            margin-top: -75px !important;
            color: #541983;
            background-color: #fac903;
            border-radius: 5px 5px 5px 5px;
            font-family: 'Raleway', Sans-serif;
            font-size: 13px;
            font-weight: 600;
            height: 25px;
            letter-spacing: 0px;
            padding: 7px 10px 3px 10px;
          "
        >
          Más vistos
        </v-chip>
      </v-card-title> -->
      <v-card-title style="padding-bottom: 5px" light>
        <v-row style="padding-right: 15px">
          <v-col cols="9" style="padding-bottom: 0">
            <v-icon
              v-if="estudiantes"
              large
              left
              dark
              class="cardicon v-icon-left2"
            >
              mdi-account-multiple
            </v-icon>
            <span v-if="estudiantes" class="cardicon"
              >{{ estudiantes }} estudiantes</span
            >
          </v-col>
          <v-col
            light
            cols="3"
            style="padding-bottom: 0; padding: 0; padding-top: 24px"
          >
            <v-icon
              light
              v-if="puntuacion"
              large
              left
              dark
              class="cardicon v-icon-left2"
            >
              mdi-diamond
            </v-icon>
            <span light v-if="puntuacion" class="cardicon">{{
              puntuacion
            }}</span>
          </v-col>
        </v-row>
      </v-card-title>
    </v-img>

    <v-card-subtitle class="pb-0" light
      ><h2 class="h2CardsFree" style="color: #fb950c">{{ nombre }}</h2>
      <h2 class="h2CardsFree">{{ resenia }}</h2>
    </v-card-subtitle>

    <v-card-text class="text--primary" style="position: absolute; bottom: 0px">
      <div
        style="
          margin: 0px 0px 0px 0px;
          padding: 20px 15px 0px 0px;
          margin-bottom: 10px;
        "
      >
        <h2
          v-if="especialista"
          style="
            color: #525252;
            font-family: 'Raleway', Sans-serif;
            font-size: 15px;
            font-weight: 500;
            line-height: 1em;
            letter-spacing: 0px;
          "
        >
          Por: {{ especialista }}
        </h2>
      </div>

      <v-row light style="margin-bottom: 20px !important">
        <v-col cols="7" style="padding-bottom: 0; padding-top: 15px">
          <v-icon
            v-if="sesiones"
            style="color: #525252 !important"
            large
            left
            light
            class="cardicon v-icon-left2"
          >
            mdi-format-list-bulleted
          </v-icon>
          <span
            v-if="sesiones"
            style="
              font-family: 'Poppins', Sans-serif;
              font-size: 14px;
              font-weight: 400;
              letter-spacing: 0.2px;
              color: #525252 !important;
            "
            >{{ sesiones }} Sesiones</span
          >
        </v-col>
        <v-col
          cols="5"
          style="padding-bottom: 0; padding: 0; padding-top: 15px"
          light
        >
          <v-icon
            large
            left
            light
            class="cardicon v-icon-left2"
            style="color: #525252 !important"
          >
            mdi-clock
          </v-icon>
          <span
            style="
              font-family: 'Poppins', Sans-serif;
              font-size: 14px;
              font-weight: 400;
              letter-spacing: 0.2px;
              color: #525252 !important;
            "
            >{{ minutos }} min.</span
          >
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<style>
.over {
  opacity: 1;
}
.over:hover {
  opacity: 0.5;
}
.theme--light.v-card > .v-card__text,
.theme--light.v-card > .v-card__subtitle {
  opacity: 1 !important;
}
.cardicon {
  font-family: "Raleway", sans-serif !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  letter-spacing: 0.2px !important;
}
.v-icon-left2 {
  margin-right: 5px !important;
}
.h2CardsFree {
  color: #525252;
  font-family: "Poppins", Sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 1.2em;
  letter-spacing: -0.3px;
  text-transform: uppercase !important;
}
</style>
<script>
export default {
  props: {
    path: {
      type: [Number, String],
      default: "",
    },
    estudiantes: {
      type: [Number, String],
      default: "",
    },
    puntuacion: {
      type: [Number, String],
      default: "",
    },
    nombre: {
      type: [Number, String],
      default: "",
    },
    resenia: {
      type: [Number, String],
      default: "",
    },
    especialista: {
      type: [Number, String],
      default: "",
    },
    sesiones: {
      type: [Number, String],
      default: "",
    },
    minutos: {
      type: [Number, String],
      default: "",
    },
    video_type_track_ruta: {
      type: Number,
      default: 0,
    },
    trackCode: {
      type: String,
      default: "",
    },
    video_id: {
      type: String,
      default: "",
    },
  },
  methods: {
    ruta() {
      let ruta = this.video_type_track_ruta == 1 ? "miruta" : "play";
      let url = `/${ruta}/${this.trackCode}#${this.video_id}`;
      // eslint-disable-next-line no-console
      // console.log(url)
      this.$router.push(url);
    },
  },
};
</script>