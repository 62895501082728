<template>
	<v-card
		class="mx-auto"
		max-width="400"
		style="
			border-radius: 15px;
			width: 100%;
			max-width: 100%;
			min-height: 344px;
			margin-left: 10px !important;
			background-color: #272429;
		"
	>
		<v-img
			class="white--text align-end fill-height ultimoscursos"
			height="341.78px"
			:src="path"
			:aspect-ratio="16 / 9"
		>
			<v-card-title
				style="
					padding-left: 0px;
					top: 0;
					position: absolute;
					padding-top: 0;
					margin-top: 0;
				"
			>
        <v-chip class="ma-2 chipcontinuarpremium" label>
          {{ area }}
        </v-chip>
			</v-card-title>
			<v-card-title style="bottom: 0px; position: absolute">
				<v-row>
					<v-col cols="12" style="padding-bottom: 0; padding-top: 5px">
						<h2
							style="
								color: #ffffff;
								font-family: 'Poppins', Sans-serif;
								font-size: 17px;
								font-weight: 600;
								line-height: 1.2em;
								letter-spacing: -0.3px;
							"
						>
							{{ resenia }}
						</h2>
					</v-col>
					<v-col cols="12" style="padding-bottom: 0; padding-top: 5px">
						<h2
							style="
								color: #ffffff;
								font-family: 'Raleway', Sans-serif;
								font-size: 15px;
								font-weight: 500;
								line-height: 1em;
								letter-spacing: 0px;
							"
						>
							Por: {{ especialista }}
						</h2>
					</v-col>
					<v-col cols="7" style="padding-bottom: 0; padding-top: 5px">
						<v-icon large left dark class="cardicon v-icon-left2">
							mdi-format-list-bulleted
						</v-icon>
						<span
							style="
								font-family: 'Poppins', Sans-serif;
								font-size: 14px;
								font-weight: 400;
								letter-spacing: 0.2px;
							"
							>{{ sesiones }} Sesiones</span
						>
					</v-col>
					<v-col
						cols="5"
						style="padding-bottom: 0; padding: 0; padding-top: 5px"
					>
						<v-icon large left dark class="cardicon v-icon-left2">
							mdi-clock
						</v-icon>
						<span
							style="
								font-family: 'Poppins', Sans-serif;
								font-size: 14px;
								font-weight: 400;
								letter-spacing: 0.2px;
							"
							>{{ minutos }} min.</span
						>
					</v-col>
				</v-row>
			</v-card-title>
		</v-img>
	</v-card>
</template>
<style>
.ultimoscursos .v-responsive__content {
	height: 100%;
	background-color: transparent;
	background-image: linear-gradient(180deg, #25252500 37%, #000000 100%);
	opacity: 1;
}
.gradient-card {
	background-color: transparent;
	background-image: linear-gradient(180deg, #25252500 37%, #000000 100%);
	opacity: 1;
}
.btninfoPremiumMd {
	font-family: "Raleway", Sans-serif !important;
	font-size: 14px !important;
	font-weight: 700 !important;
	letter-spacing: 0px !important;
	fill: #363535 !important;
	color: #363535 !important;
	background-color: #e6deb5 !important;
	border-radius: 5px 5px 5px 5px !important;
	padding: 8px 15px 6px 15px !important;
}
.btninfoPremiumMd:hover {
	color: #ffffff !important;
	background-color: #8208b1 !important;
}
.cardicon {
	font-family: "Raleway", sans-serif !important;
	font-size: 14px !important;
	font-weight: 600 !important;
	letter-spacing: 0.2px !important;
}
.v-icon-left2 {
	margin-right: 5px !important;
}
.h2Cards {
	color: #525252;
	font-family: "Poppins", Sans-serif;
	font-size: 17px;
	font-weight: 600;
	line-height: 1.2em;
	letter-spacing: -0.3px;
}
</style>
<script>
export default {
	props: {
		path: {
			type: [Number, String],
			default: "",
		},
		area: {
			type: [Number, String],
			default: "",
		},
		nombre: {
			type: [Number, String],
			default: "",
		},
		especialista: {
			type: [Number, String],
			default: "",
		},
		sesiones: {
			type: [Number, String],
			default: "",
		},
		minutos: {
			type: [Number, String],
			default: "",
		},
		link: {
			type: [Number, String],
			default: "",
		},
		target: {
			type: [Number, String],
			default: "",
		},
		interna: {
			type: [Number, String],
			default: "",
		},
		boton: {
			type: [Number, String],
			default: "",
		},
		resenia: {
			type: [Number, String],
			default: "",
		},
	},
	methods: {
		btnCardsPremium_click() {
			let routeData = this.$router.resolve({
				path: this.link,
			});
			if (this.interna == 1) {
				window.open(routeData.href, this.target);
			} else {
				window.open(this.link, this.target);
			}
		},
	},
};
</script>