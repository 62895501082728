<template>
	<v-container style="padding-top: 0; padding-bottom: 0">
		<v-row>
			<v-col cols="12" style="padding-top: 60px">
				<div style="color: #3e026e; border-color: #3e026e">
					<div @click="close" style="display:inline-block;" class="pa-0 ma-0" >
						<i
							aria-hidden="true"
							class="far fa-window-close"
							style="
								width: 1em;
								height: 1em;
								position: relative;
								color: #3e026e;
								font-size: 25px;
								margin-right: 20px;
							"
						></i>
					</div>
					<i
						aria-hidden="true"
						class="far fa-window-maximize"
						style="
							width: 1em;
							height: 1em;
							position: relative;
							color: #3e026e;
							font-size: 25px;
						"
					></i>
				</div>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<div style="padding: 56.25% 0 0 0; position: relative">
					<iframe
						:src="`https://player.vimeo.com/video/${seccionvideo.idvideo}?h=f319b7d279&color=a52882&title=0&byline=0&portrait=0`"
						style="
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
						"
						frameborder="0"
						allow="autoplay; fullscreen; picture-in-picture"
						allowfullscreen
					></iframe>
				</div>
			</v-col>
			<v-col cols="12">
				<h2
					style="
						color: #3e026e;
						font-family: 'Poppins', Sans-serif;
						font-size: 30px;
						font-weight: 600;
						line-height: 1em;
						letter-spacing: -0.7px;
						margin-bottom: 25px;
					"
					v-html="seccionvideo.titulo"
				></h2>
				<h2
					style="
						color: #525252;
						font-family: 'Raleway', Sans-serif;
						font-size: 18px;
						font-weight: 500;
						line-height: 1.1em;
						letter-spacing: 0px;
					"
					v-html="seccionvideo.subtitulo"
				></h2>
			</v-col>
		</v-row>
	</v-container>
</template>
<style>
.btnmod {
	font-family: "Raleway", Sans-serif !important;
	font-size: 18px !important;
	font-weight: 800 !important;
	letter-spacing: 0px !important;
	fill: #541983 !important;
	color: #541983 !important;
	background-color: #fac903 !important;
	border-radius: 5px 5px 5px 5px !important;
	padding: 20px 40px 17px 40px !important;
	margin-bottom: 40px !important;
	height: 55px !important;
}
.btnmod:hover {
	color: #ffffff !important;
	background-color: #8208b1 !important;
}
</style>
<script>
export default {
	props: {
		closeSeccionVideo: false,
		seccionvideo: {
			type: Object,
			default: () => {
				return {
					titulo: `En 30 segundos te enamorarás de NIUFLEEX.`,
					subtitulo: `Este es nuestro propósito y deseamos compartirlo contigo y hacerte parte de él`,
					idvideo: "445730196",
					icono: "mdi-bookmark",
					boton: "ir a Cursos",
					link: "https://niufleex.com",
					target: "_blank",
					interna: 0,
				};
			},
		},
	},
	data() {
		return {};
	},
	methods: {
		btnConprarAhora_click() {
			let routeData = this.$router.resolve({
				path: this.seccionvideo.link,
			});
			if (this.seccionvideo.interna == 1) {
				window.open(routeData.href, this.seccionvideo.target);
			} else {
				window.open(this.seccionvideo.link, this.seccionvideo.target);
			}
		},
		async close() {
			let result = (
				await this.$http.post("/settings/presentation", { is_presentation: 1 })
			).data;
			if (result) {
				this.$emit("btnCloseSeccionVideo", true);
			}
		},
	},
};
</script>
