<template>
	<!-- PAGINA MOBILE XS -->
	<v-container
		fluid
		style="
			background-color: #f3f3f3;
			padding: 0;
			max-width: 100% !important;
			overflow-x: hidden !important;
			overflow-y: hidden !important;
			margin-top: -71px;
		"
		dense
		v-if="$vuetify.breakpoint.name == 'xs'"
	>
		<v-card class="mx-auto overflow-hidden" style="border-radius: 0">
			<section
				:class="{
					'bg-seccion-banner': !premium,
					'bg-seccion-banner-premium': premium,
				}"
			>
				<CarouselPrincipalXS :bienvenida="bievenida()"></CarouselPrincipalXS>
			</section>
			<section
				style="background-color: #f3f3f3; padding-bottom: 40px"
				v-if="!closeSeccionVideo"
			>
				<SeccionVideoXS
					@btnCloseSeccionVideo="btnCloseSeccionVideo"
					:closeSeccionVideo="closeSeccionVideo"
				></SeccionVideoXS>
			</section>
			<section
				style="padding: 20px"
				:style="{ backgroundColor: theme.backcontinuar }"
			>
				<CarruselContinuarReproduciendoXS></CarruselContinuarReproduciendoXS>
			</section>
			<span id="cursos"></span>

			<section
				v-for="(items, index) of cardsCursosFree"
				style="padding-top: 20px; background-color: rgb(243, 243, 243)"
				:key="index + 'premium'"
				:style="{
					paddingBottom: cardsCursosFree.length == index + 1 ? '335px' : '0px',
				}"
			>
				<CarruselCursosFreeXS
					v-if="items.items.length > 0"
					:cardsCursosFree="items"
					nombremetodo="cardsCursosFree"
				></CarruselCursosFreeXS>
			</section>
			<section style="background-color: #272429">
				<CarruselPremiumXS></CarruselPremiumXS>
			</section>
			<section
				id="especialistas"
				style="
					margin-bottom: 0px;
					height: 800px;
					background-color: rgb(243 243 243);
					padding-top: 50px;
				"
			>
				<CarruselEspecialistasXS></CarruselEspecialistasXS>
			</section>

			<section style="margin-bottom: 0px; background-color: rgb(243 243 243)">
				<NiufleexLiveXS></NiufleexLiveXS>
			</section>
			<section style="margin-bottom: 20px">
				<FiveStar></FiveStar>
			</section>
		</v-card>
	</v-container>
	<!-- PAGINA MOBILE SM / MD -->
	<v-container
		fluid
		style="
			padding: 0;
			max-width: 100% !important;
			overflow-x: hidden !important;
			overflow-y: hidden !important;
			margin-top: -71px;
			background-color: #f3f3f3;
		"
		v-else-if="
			$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md'
		"
	>
		<v-card class="mx-auto overflow-hidden" light style="border-radius: 0">
			<section
				:class="{
					'bg-seccion-banner': !premium,
					'bg-seccion-banner-premium': premium,
				}"
			>
				<CarouselPrincipalXS :bienvenida="bievenida()"></CarouselPrincipalXS>
			</section>
			<section
				style="background-color: #f3f3f3; text-align: center; padding: 10%"
				v-if="!closeSeccionVideo"
			>
				<SeccionVideoXS
					@btnCloseSeccionVideo="btnCloseSeccionVideo"
					:closeSeccionVideo="closeSeccionVideo"
				></SeccionVideoXS>
			</section>
			<section
				style="padding-bottom: 20px; height: 950px"
				:style="{ backgroundColor: theme.backcontinuar }"
			>
				<CarruselContinuarReproduciendoSM></CarruselContinuarReproduciendoSM>
			</section>
			<span id="cursos"></span>

			<section
				v-for="(items, index) of cardsCursosFree"
				style="margin-bottom: 0px; padding-top: 20px; background-color: #f3f3f3"
				:key="index + 'premium'"
				:style="{
					paddingBottom: cardsCursosFree.length == index + 1 ? '335px' : '0px',
				}"
			>
				<CarruselCursosFreeSM
					v-if="items.items.length > 0"
					:cardsCursosFree="items"
					nombremetodo="cardsCursosFree"
				></CarruselCursosFreeSM>
			</section>
			<section style="background-color: #272429">
				<CarruselPremiumSM></CarruselPremiumSM>
			</section>
			<!-- <section style="margin-bottom: 20px">
				<CarruselCursosFreeSM
					v-if="cardsCursosTeams.items.length > 0"
					:cardsCursosFree="cardsCursosTeams"
				></CarruselCursosFreeSM>
			</section> -->

			<section
				id="especialistas"
				style="
					margin-bottom: 0px;
					padding-bottom: 100px;
					padding-top: 80px;
					background-color: #f3f3f3;
				"
			>
				<CarruselEspecialistasSM></CarruselEspecialistasSM>
			</section>

			<section
				style="
					background-color: #f3f3f3;
					padding-top: 60px;
					background-color: #f3f3f3;
				"
			>
				<NiufleexLiveXS></NiufleexLiveXS>
			</section>
			<section style="margin-bottom: 20px; background-color: #f3f3f3">
				<FiveStar></FiveStar>
			</section>
		</v-card>
	</v-container>
	<!-- PAGINA MOBILE LG / XL -->
	<v-container
		v-else
		fluid
		style="
			padding: 0px;
			max-width: 100% !important;
			overflow-x: hidden !important;
			overflow-y: hidden !important;
			position: relative;
			background-color: #fff;
		"
	>
		<v-card
			class="mx-auto overflow-hidden"
			style="border-radius: 0; background-color: #fff !important"
		>
			<!-- <section
				style="
					background-color: rgb(39, 36, 41);
					padding-top: 0;
					padding-bottom: 0;
				"
			>
				<ComprarAhoraMD></ComprarAhoraMD>
			</section> -->
			<section
				:class="{
					'bg-seccion-banner': !premium,
					'bg-seccion-banner-premium': premium,
				}"
				style="padding: 30px 0"
			>
				<CarouselPrincipalMD :bienvenida="bievenida()"></CarouselPrincipalMD>
			</section>
			<section
				style="background-color: #f3f3f3; padding: 20px 0; min-height: 326.25px"
				v-if="!closeSeccionVideo"
			>
				<SeccionVideo
					@btnCloseSeccionVideo="btnCloseSeccionVideo"
					:closeSeccionVideo="closeSeccionVideo"
				></SeccionVideo>
			</section>
			<section
				style="padding-bottom: 50px"
				:style="{ backgroundColor: theme.backcontinuar }"
			>
				<CarruselContinuarReproduciendoMD></CarruselContinuarReproduciendoMD>
			</section>
			<span id="cursos"></span>

			<section
				v-for="(items, index) of cardsCursosFree"
				style="margin-bottom: 0px; padding-top: 30px; background-color: #f3f3f3"
				:key="index + 'premium'"
				:style="{
					paddingBottom: cardsCursosFree.length == index + 1 ? '335px' : '0px',
				}"
			>
				<CarruselCursosFreeMD
					v-if="items.items.length > 0"
					:cardsCursosFree="items"
					nombremetodo="cardsCursosFree"
				></CarruselCursosFreeMD>
			</section>

			<!-- <section
				style="
					margin-bottom: 0px;
					padding-top: 20px;
					background-color: #fff;
					padding-bottom: 260px;
				"
			>
				<CarruselCursosFreeMD
					v-if="cardsCursosTeams.items.length > 0"
					:cardsCursosFree="cardsCursosTeams"
					nombremetodo="cardsCursosFree"
				></CarruselCursosFreeMD>
			</section> -->

			<section style="background-color: #272429">
				<CarruselPremiumMD></CarruselPremiumMD>
			</section>
			<section
				id="especialistas"
				style="
					padding-top: 30px;
					background-color: rgb(243 243 243);
					height: 800px;
				"
			>
				<CarruselEspecialistas></CarruselEspecialistas>
			</section>
			<section style="padding-bottom: 80px; background-color: rgb(243 243 243)">
				<NiufleexLiveMD></NiufleexLiveMD>
			</section>
			<section style="margin-bottom: 20px; background-color: rgb(243 243 243)">
				<FiveStar></FiveStar>
			</section>
		</v-card>
	</v-container>
</template>
<style>
* {
	position: static;
}
.mnuxs .v-navigation-drawer__content {
	background-color: rgb(247, 124, 10);
	background: none !important;
}
.iface {
	font-size: 17px;
	padding-left: 12px;
	padding-top: 6px;
	border-width: 3px 3px 3px 3px;
	border-radius: 50%;
	color: white;
	border-color: white;
	width: 43px;
	height: 43px;
	border: solid;
	margin-right: 40px;
}
.iface:hover {
	color: #fb950c;
	border-color: #fb950c;
}
.btnmenu {
	background-color: transparent !important;
	color: white !important;
	font-family: "Raleway", Sans-serif !important;
	font-size: 18px !important;
	font-weight: 600 !important;
}
.btnmenu:hover {
	color: #fb950c !important;
}
.btnmenu:hover::before {
	opacity: 0 !important;
}
.bg-seccion-banner {
	height: 680.59px;
	background: rgb(106, 40, 159);
	background: radial-gradient(
		circle,
		rgba(106, 40, 159, 1) 42%,
		rgba(75, 11, 127, 1) 68%
	);
}
.bg-seccion-banner-premium {
	height: 680.59px;
	background: #272429;
	background-image: radial-gradient(at center center, #483f4e 42%, #272429 68%);
}
</style> 
<script>
//BANNER SUPERIOR COMPRAR AHORA
import ComprarAhoraMD from "../components/shared/comprarahora.vue";
import ComprarAhoraXS from "../components/shared/comprarAhoraXS.vue";
//CARRUSEL PUBLICITARIO PRINCIPAL
import CarouselPrincipalMD from "../components/shared/CarruselesPromocion/CarruselPrinsipalMD.vue";
import CarouselPrincipalXS from "../components/shared/CarruselesPromocion/CarruselPrincipalXS.vue";
//VIDEO PROMOCIONAL
import SeccionVideo from "../components/explora_free/SeccionVideo.vue";
import SeccionVideoXS from "../components/explora_free/SeccionVideoXS.vue";
//CARRUSEL CURSOS CONTINUAR REPRODUCIENDO
import CarruselContinuarReproduciendoMD from "../components/shared/CarruselesCursos/ContinuarReproduciendoPremium/CarruselCuntinuarReproduciendoMD.vue";
import CarruselContinuarReproduciendoSM from "../components/shared/CarruselesCursos/ContinuarReproduciendoPremium/CarruselContinuarReproduciendoSM.vue";
import CarruselContinuarReproduciendoXS from "../components/shared/CarruselesCursos/ContinuarReproduciendoPremium/CarruselContinuarReproduciendoXS.vue";

//CARRUSEL CURSOS FREE
import CarruselCursosFreeMD from "../components/shared/CarruselesCursos/CursosMembershipPremium/CarouselCardsPremiumMD.vue";
import CarruselCursosFreeSM from "../components/shared/CarruselesCursos/CursosMembershipPremium/CarouselCardsPremiumSM.vue";
import CarruselCursosFreeXS from "../components/shared/CarruselesCursos/CursosMembershipPremium/CarruselCardsPremiumXS.vue";
//BANNER PUBLICITARIO NIUFLIX PREMIUM
import NiufleexPremium from "../components/shared/Banners/NiufleexPremium.vue";
import NiufleexPremiumXS from "../components/shared/Banners/NiufleexPremiumXS.vue";
//CARRUSEL ESPECIALISTAS
import CarruselEspecialistas from "../components/shared/CarruselesCursos/Especialistas/CarouselCardsEspecialistasMD.vue";
import CarruselEspecialistasSM from "../components/shared/CarruselesCursos/Especialistas/CarouselCardsEspecialistasSM.vue";
import CarruselEspecialistasXS from "../components/shared/CarruselesCursos/Especialistas/CarruselEspecialistasXS.vue";
//CARRUSEL CURSOS PREMIUM
import CarruselPremiumMD from "../components/shared/CarruselesCursos/CursosPremium/CarruselPremiumMD.vue";
import CarruselPremiumSM from "../components/shared/CarruselesCursos/CursosPremium/CarruselPremiumSM.vue";
import CarruselPremiumXS from "../components/shared/CarruselesCursos/CursosPremium/CarruselPremiumXS.vue";

//BANNER PUBLICITARIO LIVES
import NiufleexLiveMD from "../components/shared/Banners/NiufleexLive.vue";
import NiufleexLiveXS from "../components/shared/Banners/NiufleexLiveXS.vue";
//CALIFICACION FIVE STAR
import FiveStar from "../components/shared/FiveStar.vue";

import { mapState } from "vuex";
export default {
	data: () => {
		return {
			closeSeccionVideo: false,
			cardsCursosTeams: {
				titulo: "Cursos FREE disponibles para ti",
				subtitulo:
					"Especialistas, Mentores y guías. Conoce a quienes te harán subir al próximo nivel.",
				link_general: "https://niufleex.com/",
				target_general: "_blank",
				interna_general: "0",
				boton_general: "ver todo el contenido",
				boton_general_xs: "ver todo",
				totCursos: 10,
				cantVistos: 3,
				items: [],
			},
			cardsCursosFree: [
				{
					titulo: "Cursos FREE disponibles para ti",
					subtitulo:
						"Especialistas, Mentores y guías. Conoce a quienes te harán subir al próximo nivel.",
					link_general: "https://niufleex.com/",
					target_general: "_blank",
					interna_general: "0",
					boton_general: "ver todo el contenido",
					boton_general_xs: "ver todo",
					totCursos: 10,
					cantVistos: 3,
					items: [],
				},
			],
			drawer: false,
			drawer2: false,
			group: null,
			group2: null,
			logos: {
				logo1: {
					src: "https://dsm01pap008files.storage.live.com/y4meHSrzhJvABIWJfxS8l97K6Hzc-C9ZN2VlrL0Pkyl0ElyynDnwq8Y9oyG6eD05t9A9iblEPd5GqJiNygQwK2RO2ru0LpSxBeeGpfJXCS2L6H7JZ33IJxUkbdAQ3ABgiiQnfqk4n10KJcsdT_2r9voeKiPO9M2WuOtyVjridPY7C5b0jWDCJzawVoYvk2Q2LPr?width=660&height=167&cropmode=none",
					link: "/explora/free",
					target: "_self",
				},
				logo2: {
					src: "https://niufleex.com/wp-content/uploads/2021/12/Sin-ti%CC%81tulo-1.png",
					link: "/explora/free",
					target: "_self",
				},
				avatar: {
					src: "",
				},
			},
			items: [
				{
					boton: "Explora Free",
					link: "/explora/free",
					target: "_self",
					interna: 0,
					subitems: [],
					membresia: [0, 1, 2],
					teams: 0,
				},
				{
					boton: "Explora Premium",
					link: "/newexplora",
					target: "_self",
					interna: 1,
					subitems: [],
					membresia: [2],
				},
				// {
				// 	boton: "Elite",
				// 	link: "/explora/free",
				// 	target: "_self",
				// 	interna: 1,
				// 	subitems: [],
				// 	membresia: [0, 1, 2],
				// 	teams: 1,
				// 	nameteam: 'elite',
				// },
				// {
				// 	boton: "Teoma Chile",
				// 	link: "/explora/free",
				// 	target: "_self",
				// 	interna: 1,
				// 	subitems: [],
				// 	membresia: [0, 1, 2],
				// 	teams: 1,
				// 	nameteam: 'teomachile',
				// },
				// {
				// 	boton: "Cursos",
				// 	link: "https://niufleex.com",
				// 	target: "_blank",
				// 	interna: 0,
				// 	subitems: [
				// 		{
				// 			boton: "Área de Conocimiento 1",
				// 			link: "https://niufleex.com",
				// 			target: "_blank",
				// 			interna: 0,
				// 		},
				// 		{
				// 			boton: "Área de Conocimiento 2",
				// 			link: "https://niufleex.com",
				// 			target: "_blank",
				// 			interna: 0,
				// 		},
				// 		{
				// 			boton: "Área de Conocimiento 3",
				// 			link: "https://niufleex.com",
				// 			target: "_blank",
				// 			interna: 0,
				// 		},
				// 	],
				// },
				// {
				// 	boton: "Especialistas",
				// 	link: "https://niufleex.com",
				// 	target: "_blank",
				// 	interna: 0,
				// 	subitems: [],
				// },
				{
					boton: "Live",
					link: "/live",
					target: "_self",
					interna: 1,
					subitems: [],
					membresia: [0, 1, 2],
				},
			],
		};
	},
	components: {
		ComprarAhoraMD,
		ComprarAhoraXS,
		CarouselPrincipalMD,
		CarouselPrincipalXS,
		SeccionVideo,
		SeccionVideoXS,
		CarruselContinuarReproduciendoMD,
		CarruselContinuarReproduciendoSM,
		CarruselContinuarReproduciendoXS,
		CarruselCursosFreeMD,
		CarruselCursosFreeSM,
		CarruselCursosFreeXS,
		NiufleexPremium,
		NiufleexPremiumXS,
		CarruselEspecialistas,
		CarruselEspecialistasSM,
		CarruselEspecialistasXS,
		CarruselPremiumMD,
		CarruselPremiumSM,
		CarruselPremiumXS,
		NiufleexLiveMD,
		NiufleexLiveXS,
		FiveStar,
	},
	created() {
		this.$vuetify.theme.dark = false;
	},
	updated() {
		if (this.$vuetify.theme.dark != false) {
			this.$vuetify.theme.dark = false;
		}
	},
	destroyed() {
		this.$vuetify.theme.dark = true;
	},
	async mounted() {
		try {
			this.cardsCursosFree = [
				...this.cardsCursosFree,
				...(await this.$http.get("/listacursos/premium")).data,
			];
			this.cardsCursosTeams = {
				...this.cardsCursosTeams,
				...(await this.$http.get("/listacursos/team")).data[0],
			};

			// eslint-disable-next-line no-empty
		} catch (error) {}
		// eslint-disable-next-line no-console
		console.log(this.cardsCursosFree);

		try {
			this.closeSeccionVideo = (
				await this.$http.get("/settings/presentation")
			).data[0].is_presentation;
			// eslint-disable-next-line no-console
			console.log(this.closeSeccionVideo);
			// eslint-disable-next-line no-empty
		} catch (error) {}
	},
	methods: {
		btnCloseSeccionVideo() {
			this.closeSeccionVideo = true;
		},
		bievenida() {
			return {
				nombre: `${this.firstname()} ${this.firstsurname()}`,
				genero: `${this.genero()}`,
			};
		},
		firstname() {
			try {
				let data = this.$storeNiu.getObjectValue("data");

				if (Object.prototype.hasOwnProperty.call(data, "firstname")) {
					return data.firstname;
				}
				return "";
				//eslint-disable-next-line
			} catch (error) {
				return "";
			}
		},
		firstsurname() {
			try {
				let data = this.$storeNiu.getObjectValue("data");

				if (Object.prototype.hasOwnProperty.call(data, "firstsurname")) {
					return data.firstsurname;
				}
				return "";
				//eslint-disable-next-line
			} catch (error) {
				return "";
			}
		},
		genero() {
			try {
				let data = this.$storeNiu.getObjectValue("data");
				// this.showButton();
				// this.encuesta();
				if (Object.prototype.hasOwnProperty.call(data, "genero")) {
					return data.genero;
				}
				return "";
				//eslint-disable-next-line
			} catch (error) {
				return "";
			}
		},
	},
	computed: {
		...mapState(["premium", "theme"]),
	},
};
</script>