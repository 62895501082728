<template>
  <v-card
    elevation="10"
    class="mx-auto rounded-lg gradient"
    max-width="300"
    style="
	max-width: 100%;
    width: 100%;
    min-height: 393.78px;
    margin-left: 10px !important;
    border-radius: 15px !important;
	box-shadow: 0px 10px 12px 0px rgb(0 0 0 / 15%) !important;
    "
    light
    v-if="path"
  >
    <v-img
      v-if="path"
      class="white--text rounded-lg fill-height align-end ultimoscursos"
      style="min-height: 393.78px;"
      :src="path"
    >
      <v-card-title style="padding: 0; top: 0; position: absolute">
        <v-chip class="ma-2 chipcontinuarpremium" label>
          {{ area }}
        </v-chip>
      </v-card-title>
      <h2
        class="h2Cards block-ellipsis"
        style="
          color: #ffffff;
          font-family: 'Poppins', Sans-serif;
          font-size: 17px;
          font-weight: 600;
          line-height: 1.2em;
          letter-spacing: -0.3px;
          margin-left: 12px;
          position: absolute;
          top: 190px;
        "
      >
        {{ nombre }} {{ resenia }}
      </h2>
      <h2
        style="
          color: #ffffff;
          font-family: Raleway, Sans-serif;
          font-size: 15px;
          font-weight: 500;
          line-height: 1em;
          margin-left: 12px;
          letter-spacing: 0px;
          position: absolute;
          top: 240px;
        "
      >
        Por: {{ especialista }}
      </h2>
      <v-card style="position: absolute;
    top: 250px; background-color:transparent !important;
    width: 100%;">
        <v-card-text class="text--primary" style="padding-left: 8px;">
          <v-row style="margin-bottom: 20px !important">
            <v-col cols="12" style="padding-bottom: 0; margin-left:5px;">
              <v-icon
                large
                left
                color="white"
                light
                class="cardicon v-icon-left2"
              >
                mdi-format-list-bulleted
              </v-icon>
              <span
                style="
                  font-family: 'Poppins', Sans-serif;
                  font-size: 14px;
                  font-weight: 400;
                  letter-spacing: 0.2px;
                  color: #ffffff;
                "
                >{{ sesiones }} Sesiones</span
              >
              <v-icon
                large
                left
                color="white"
                light
                class="cardicon v-icon-left2"
				style="margin-left: 20px;"
              >
                mdi-clock
              </v-icon>
              <span
                style="
                  font-family: 'Poppins', Sans-serif;
                  font-size: 14px;
                  font-weight: 400;
                  letter-spacing: 0.2px;
                  color: #ffffff;
                  padding-left: 5px;
                "
                >{{ minutos }} min.</span
              >
          <button
            @click="btnIrCurso_click"
            :class="{
              btnircurso: !premium,
              'btnircurso-premiumc': premium,
            }"
          >
            <span>{{ boton }}</span>
          </button>			  
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-img>
  </v-card>
</template>
<style>
.gradient .v-responsive__sizer{
    padding-bottom: 150% !important;
    background-color: transparent !important;
    background-image: linear-gradient(180deg, #25252533 27%, #000000 100%) !important;
    opacity: 1 !important;
    margin-right: -15px !important;
}
.chipcontinuarpremium {
  font-family: "Raleway", Sans-serif !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  letter-spacing: 0px !important;
  fill: #e6deb5 !important;
  color: #e6deb5 !important;
  background-color: #363535 !important;
  border-radius: 5px 5px 5px 5px !important;
  padding: 7px 10px 3px 10px !important;
  height: 23px !important;
}
.chipcontinuarpremium:hover {
  color: #541983 !important;
  background-color: #ffffff !important;
}
.card-gradiente {
  background: rgb(0, 0, 0);

  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.7) 40%,
    rgba(0, 0, 0, 0.5) 60%,
    rgba(0, 0, 0, 0.3) 85%,
    rgba(0, 0, 0, 0) 100%
  ) !important;
}
.cardicon {
  font-family: "Raleway", sans-serif !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  letter-spacing: 0.2px !important;
  margin-right: 20px;
}
.v-icon-left2 {
  margin-right: 5px !important;
}
.h2Cards {
  color: #525252;
  font-family: "Poppins", Sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 1.2em;
  letter-spacing: -0.3px;
}
.btnircurso {
  width: 100% !important;
  height: 60px !important;
  font-family: "Raleway", Sans-serif;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0px;
  fill: #e6deb5;
  color: #e6deb5;
  background-color: transparent !important;
  border-style: solid;
  border-width: 3px 3px 3px 3px;
  border-color: #e6deb5;
  border-radius: 3px 3px 3px 3px;
  line-height: 0px;
  padding: 20px 15px 17px 15px;
  margin-top: 15px;
}
.btnircurso:hover {
  color: #ffffff !important;
  background-color: #8208b1 !important;
}

.btnircurso-premiumc {
  transition: all 0.3s;
  width: 100% !important;
  height: 60px !important;
  font-family: "Raleway", Sans-serif !important;
  font-size: 17px !important;
  font-weight: 600 !important;
  letter-spacing: 0px !important;
  color: #e6deb5 !important;
  background-color: transparent !important;
  border-style: solid !important;
  border-width: 3px 3px 3px 3px !important;
  border-color: #e6deb5 ;
  border-radius: 3px 3px 3px 3px !important;
  padding: 20px 15px 17px 15px !important;
  margin-top: 20px !important;
  line-height: 0px !important;
}

.btnircurso-premiumc:hover {
  color: #000000 !important;
  background-color: #e6deb5 !important;
}
</style>
<script>
import { mapState } from "vuex";
export default {
  props: {
    accion: {
      type: String,
      default: "",
    },    
    area: {
      type: String,
      default: "Área de Conocimiento",
    },
    path: {
      type: [Number, String],
      default: "",
    },
    estudiantes: {
      type: [Number, String],
      default: "",
    },
    puntuacion: {
      type: [Number, String],
      default: "",
    },
    nombre: {
      type: [Number, String],
      default: "",
    },
    resenia: {
      type: [Number, String],
      default: "",
    },
    especialista: {
      type: [Number, String],
      default: "",
    },
    sesiones: {
      type: [Number, String],
      default: "",
    },
    minutos: {
      type: [Number, String],
      default: "",
    },
    titulo: {
      type: [Number, String],
      default: "",
    },
    subtitulo: {
      type: [Number, String],
      default: "",
    },
    link: {
      type: [Number, String],
      default: "",
    },
    target: {
      type: [Number, String],
      default: "",
    },
    interna: {
      type: [Number, String],
      default: "",
    },
    boton: {
      type: [Number, String],
      default: "",
    },
    video_id: {
      type: [Number, String],
      default: "",
    },
    trackCode: {
      type: [Number, String],
      default: "",
    },
  },
  mounted() {
    // eslint-disable-next-line no-console
    console.log(this.link);
  },
  methods: {
    btnIrCurso_click() {
      // let routeData = this.$router.resolve({
      // 	path: this.link,
      // });
      // if (this.interna == 1) {
      // 	window.open(routeData.href, this.target);
      // } else {
      // 	window.open(this.link, this.target);
      // }
      if(this.accion == "continuar")
        this.$router.push(`/play/${this.trackCode}#${this.video_id}`);
      else
        this.$router.push(this.link + "#title-curso");

      // eslint-disable-next-line no-console
      console.log(this.target, this.link, this.interna);
    },
  },
  computed: {
    ...mapState(["premium"]),
  },
};
</script>