
<template>
	<v-container
		fluid
		style="
			padding: 0;
			padding: 0px;
			position: fixed;
			top: 0;
			overflow-x: hidden !important;
			overflow-y: hidden !important;
			bottom: 0;
		"
		:style="{
			backgroundColor: theme.backCardModulos,
		}"
	>
		<section
			v-if="!premium"
			style="
				background-color: rgb(39, 36, 41);
				padding-top: 0;
				padding-bottom: 0;
				margin-top: 0px;
			"
		>
			<Comprarahora></Comprarahora>
		</section>
		<v-row
			style="margin-top: 12px; margin-left: 0px; margin-right: 0.5px"
			id="heighttrack"
			class="fill-height"
			v-resize="onResize"
		>
			<v-col
				lg="9"
				xl="9"
				cols="12"
				sm="12"
				md="9"
				style="padding: 0; min-height: 150px; position: relative"
				:style="{
					backgroundColor: theme.backPlayer,
				}"
				id="containt-video"
				class="responsive"
			>
				<div style="position: relative; margin: -1px">
					<div
						width="100%"
						id="videosesiones"
						ref="videosesiones"
						class="ma-0 pa-0 pt-md-0 pt-lg-0 pt-xl-0 pt-sm-2 pt-1"
						style="width: 100%; position: relative; z-index: 0; top: 0"
					></div>
					<div style="position: absolute; width: 1%; bottom: 0; left: 0">
						<v-btn light icon @click="reload"
							><v-icon>mdi-refresh</v-icon></v-btn
						>
					</div>
					<div
						v-show="divEndSesion"
						style="position: absolute; width: 100%; top: 0"
						width="100%"
						:height="`${heightFrame}px`"
					>
						<v-row justify="center">
							<v-col cols="10">
								<v-row justify="center">
									<v-col cols="2">
										<v-progress-circular
											:rotate="180"
											:size="100"
											:width="15"
											:value="segundos"
											color="purple"
											indeterminate
										>
											{{ segundos }}
										</v-progress-circular>
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<v-btn @click="reiniciarSiguienteSesion"> Detener </v-btn>
									</v-col>
									<v-col>
										<v-btn @click="siguienteSesion" light color="primary">
											Continuar con la siguiente sesión
										</v-btn>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</div>
					<div
						v-show="divBotonesPause && !divEndSesion"
						class="sobre-video"
						width="100%"
						:height="`${heightFrame}px`"
						@click="continuarReproduciendo"
					>
						<v-row
							class="fill-height"
							:style="
								$vuetify.breakpoint.name != 'xs' &&
								$vuetify.breakpoint.name != 'sm'
									? 'padding: 85px'
									: 'padding: 15px;'
							"
						>
							<v-col cols="12">
								<v-row
									justify="start"
									v-if="
										$vuetify.breakpoint.name != 'xs' &&
										$vuetify.breakpoint.name != 'sm'
									"
								>
									<v-col cols="10">
										<h1 class="play-title">{{ track.track_title }}</h1>
										<h2 class="play-subtitle">
											{{ sesionSeleccionada.video_title }}
										</h2>
									</v-col>
								</v-row>
								<v-row justify="start">
									<v-col cols="12">
										<button
											v-if="$router.currentRoute.name == 'play'"
											class="btn-reproductor"
											@click="volverFicha"
											style="display: block"
										>
											<span>
												<v-icon style="color: white">
													mdi-arrow-left-thick
												</v-icon>
												Volver a la ficha del curso
											</span>
										</button>
										<button
											v-else
											style="display: block"
											class="btn-volver"
											@click="volverFicha"
										>
											<span>
												<v-icon style="color: white">
													mdi-arrow-left-thick
												</v-icon>
												Volver al Carrusel
											</span>
										</button>
										<button
											class="btn-reproductor"
											@click="continuarReproduciendo"
										>
											<span>
												<v-icon style="color: white"> mdi-play </v-icon>
												Continuar Reproduciendo
											</span>
										</button>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</div>
				</div>
			</v-col>
			<!-- <v-btn absolute icon color="#FFF" v-if="panel">
				<v-icon> mdi-arrow-right-drop-circle-outline </v-icon>
			</v-btn>
			<v-btn absolute icon color="#FFF" v-else>
				<v-icon> mdi-arrow-right-drop-circle-outline </v-icon>
			</v-btn> -->

			<v-col
				lg="3"
				xl="3"
				cols="12"
				sm="12"
				md="3"
				class="navegacion fadein"
				id="navegacion-play"
				:style="{
					backgroundColor: `${theme.backPlayer} !important`,
				}"
			>
				<v-banner style="margin-top: 15px">
					<img
						src="https://dsm01pap008files.storage.live.com/y4meHSrzhJvABIWJfxS8l97K6Hzc-C9ZN2VlrL0Pkyl0ElyynDnwq8Y9oyG6eD05t9A9iblEPd5GqJiNygQwK2RO2ru0LpSxBeeGpfJXCS2L6H7JZ33IJxUkbdAQ3ABgiiQnfqk4n10KJcsdT_2r9voeKiPO9M2WuOtyVjridPY7C5b0jWDCJzawVoYvk2Q2LPr?width=660&height=167&cropmode=none"
						style="height: 23.59px; width: 125px"
					/>

					<img
						:src="
							premium
								? 'https://niufleex.com/wp-content/uploads/2020/11/N-premium.png'
								: 'https://niufleex.com/wp-content/uploads/2021/12/Sin-ti%CC%81tulo-1.png'
						"
						style="height: 41.72px; width: 46.98px; margin-left: 10px"
					/>
					<img
						:src="
							!dataUser
								? 'https://niufleex.com/wp-content/uploads/2022/01/user_blanco.png'
								: (dataUser.genero = 'hombre'
										? 'https://niufleex.com/wp-content/uploads/2022/01/businessman.png'
										: (dataUser.genero = 'mujer'
												? 'https://niufleex.com/wp-content/uploads/2022/01/businesswoman.png'
												: 'https://niufleex.com/wp-content/uploads/2022/01/user_blanco.png'))
						"
						style="
							height: 41.72px;
							width: 46.98px;
							position: absolute;
							right: 20px;
						"
					/>
				</v-banner>
				<Modulos
					:modulos="load_modulos"
					:sesionSeleccionada.sync="sesionSeleccionada"
				></Modulos>
			</v-col>
		</v-row>
	</v-container>
</template>
<style lang="scss" scoped >
.sobre-video {
	position: absolute;
	width: 100%;
	top: 0;
	height: 75%;
	background: rgb(0, 0, 0);
	background: linear-gradient(
		180deg,
		rgba(0, 0, 0, 1) 0%,
		rgba(0, 0, 0, 0.9416141456582633) 20%,
		rgba(0, 0, 0, 0.8715861344537815) 40%,
		rgba(0, 0, 0, 0.6250875350140056) 60%,
		rgba(0, 0, 0, 0.4009978991596639) 80%,
		rgba(255, 255, 255, 0) 100%
	);
}
.navegacion.fadein {
	-webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadein 2s; /* Firefox < 16 */
	-ms-animation: fadein 2s; /* Internet Explorer */
	-o-animation: fadein 2s; /* Opera < 12.1 */
	animation: fadein 2s;
}
.navegacion.fadeout {
	-webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadein 2s; /* Firefox < 16 */
	-ms-animation: fadein 2s; /* Internet Explorer */
	-o-animation: fadein 2s; /* Opera < 12.1 */
	animation: fadein 2s;
}
@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Firefox < 16 */
@-moz-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Internet Explorer */
@-ms-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Opera < 12.1 */
@-o-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
/** fadeout */

@keyframes fadeout {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

/* Firefox < 16 */
@-moz-keyframes fadeout {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

/* Internet Explorer */
@-ms-keyframes fadeout {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

/* Opera < 12.1 */
@-o-keyframes fadeout {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}
@media (max-width: 600px) {
	.contenedor {
		padding: 0;
		padding: 0px;
		position: absolute;
		top: 0;
		overflow-x: hidden !important;
		overflow-y: hidden !important;
		bottom: 0;
	}
	.navegacion {
		background-color: #4b0b7f;
		padding: 0;
		max-height: 100vh;
		overflow-y: scroll;
	}
}
@media (min-width: 1264px) {
	.contenedor {
		padding: 0;
		padding: 0px;
		position: absolute;
		top: 0;
		overflow-x: hidden !important;
		overflow-y: hidden !important;
		bottom: 0;
	}
	.navegacion {
		background-color: #4b0b7f;
		padding: 0;
		max-height: 100vh;
		overflow-y: scroll;
	}
}
.navegacion {
	background-color: #4b0b7f;
	padding: 0;
}
.responsive {
	padding: 56.25% 0 0 0;
	position: relative;
}

.v-banner__wrapper {
	border: none !important;
}
.ocultar {
	display: none;
}
.v-banner__text {
	vertical-align: middle;
	display: contents;
}
.navegacion {
	background-color: #4b0b7f;
	padding: 0;
	overflow-y: scroll;
	height: calc(100% - 120px);
}
.navegacion::-webkit-scrollbar {
	width: 7px;
	display: block;
}
.navegacion::-webkit-scrollbar-thumb {
	/* -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 90%); */
	/* border-radius: 10px; */
	background-color: #fb950c;
}
.navegacion::-webkit-scrollbar-track {
	background-color: white;
}
::-webkit-scrollbar {
	display: none;
}
@media (max-width: 960px) {
	.navegacion {
		height: calc(45%);
	}
}

.btn-reproductor {
	padding: 10px;
	margin: 15px;
}
.btn-volver {
	padding: 10px;
	margin: 15px;
}

.btn-reproductor > span,
.btn-volver > span {
	font-family: "DM Sans", Sans-serif;
	font-size: 18px;
	font-weight: 800;
	letter-spacing: 0px;
	color: #ffffff;
	background-color: #ffffff00;
	border-style: solid;
	border-width: 3px 3px 3px 3px;
	border-color: #ffffff;
	border-radius: 3px 3px 3px 3px;
	padding: 20px 25px 17px 25px;
}
.btn-reproductor > span:hover,
.btn-reproductor > span:active {
	color: #ffffff;
	background-color: #4b0b7f;
	border-color: #4b0b7f;
}
.btn-volver > span:hover,
.btn-volver > span:active {
	color: #ffffff;
	background-color: rgb(37, 72, 149);
	border-color: rgb(37, 72, 149);
}
@media (max-width: 600px) {
	.btn-reproductor {
		padding: 5px;
		margin: 10px;
		font-size: 12px;
		margin: 40px 10px 0;
	}
	.btn-volver {
		padding: 5px;
		margin: 15px 10px 0;
		font-size: 12px;
	}
}
.play-title {
	display: block;
	text-align: left;

	color: #ffffff;
	font-family: "Rubik", Sans-serif;
	font-size: 27px;
	font-weight: 300;
	text-transform: uppercase;
	line-height: 1em;
	letter-spacing: -0.4px;
	padding-left: 26px;
}
.play-subtitle {
	display: block;
	text-align: left;
	color: #ffffff;
	font-family: "DM Sans", Sans-serif;
	font-size: 18px;
	font-weight: 600;
	line-height: 1.3em;
	letter-spacing: 0px;
	padding-left: 26px;
}
</style>
<script>
/* eslint-disable no-console */
/* eslint-disable no-empty */
// eslint-disable-next-line no-unused-vars
import Modulos from "../components/reproductor/modulos.vue";
import Comprarahora from "../components/shared/comprarahora.vue";
import { mapState, mapMutations } from "vuex";
export default {
	name: "play",
	data() {
		return {
			panel: true,
			load_modulos: [],
			setIntervalCurrentTime: null,
			interval: null,
			segundos: 8,
			divBotonesPause: false,
			divEndSesion: false,
			tab_modulo: null,
			showButtonOpenList: false,
			showOpenList: false,
			vimeo: [],
			showVideo: false,
			showCarousel: true,
			screen: "",
			ordenVideoActivar: 1,
			videoplayer: "",
			test: [{ idvideo: 423440615 }, { idvideo: 418757076 }],
			idseleccionado: {},
			listVideo: 0,
			widthFrame: "600",
			model: null,
			loadCarrusel: null,
			sesion: null,
			isPremium: null,
			heightFrame: null,
			videos: null,
			moduloSeleccionado: null,
			modulo: { isPremium: 0 },
			errorAcceso: false,
			mensajeError: "",
			dialogModuloPremium: false,
			modalPreguntas: false,
			sesionSeleccionada: {
				video_seconds: 0,
			},
			modulos: [],
			track: {
				track_title: "",
			},
			heightTrack: null,
			items: [
				{ title: "Home", icon: "mdi-home-city" },
				{ title: "My Account", icon: "mdi-account" },
				{ title: "Users", icon: "mdi-account-group-outline" },
			],
		};
	},
	methods: {
		...mapMutations(["SET_RUTA"]),
		async methodsSetIntervalCurrentTime() {
			try {
				this.$setIntervalCurrentTime = setInterval(() => {
					let getCurrentTime = async () => {
						try {
							// console.group('setIntervalCurrentTime getCurrentTime')
							let time = 0;

							time = await this.videoplayer.getCurrentTime();

							let timestring = parseInt(time).toString();
							console.log("Paso", time, timestring);
							if (this.sesionSeleccionada.video_seconds != timestring) {
								let response = await this.$http.post("/videos/playvideo", {
									id_video: this.sesionSeleccionada.video_id,
									seconds_video: timestring,
									track_video: this.track.track_id,
								});
								console.log("response", response.data);
								if (response.data) {
									this.sesionSeleccionada.video_seconds = timestring;
								}
								//   console.log(this.$setIntervalCurrentTime)
							}
						} catch (error) {}
					};
					getCurrentTime();
				}, 5000);
			} catch (error) {}
		},
		activeEndPlayVideo() {
			this.divEndSesion = true;

			this.$intervalActiveEndPlayVideo = setInterval(() => {
				// eslint-disable-next-line no-console
				console.log("Paso");
				if (this.segundos === 0) {
					this.siguienteSesion();
				}
				this.segundos -= 1;
			}, 1000);
		},
		siguienteSesion() {
			let positionOrder = parseInt(this.sesionSeleccionada.video_orden);
			let siguienteModulo = false;
			let moduloSeleccionado = false;
			let hasSessionWelcome = false;
			let positionOrdenTemp = positionOrder;
			this.track.modulos.forEach((modulo) => {
				hasSessionWelcome = false;
				modulo.sesiones.forEach((sesion) => {
					if (sesion.video_orden == 0) {
						hasSessionWelcome = true;
						positionOrdenTemp++;
					}
					if (!hasSessionWelcome) {
						positionOrdenTemp = positionOrder;
					}
					// sesion.video_orden
					if (!moduloSeleccionado) {
						if (siguienteModulo) {
							this.sesionSeleccionada = { ...modulo.sesiones[0] };
							moduloSeleccionado = true;
						} else {
							if (sesion.video_id == this.sesionSeleccionada.video_id) {
								if (positionOrdenTemp + 1 <= modulo.sesiones.length) {
									this.sesionSeleccionada = {
										...modulo.sesiones[positionOrdenTemp],
									};
								} else {
									// eslint-disable-next-line no-console
									console.log("siguiente modulo");
									siguienteModulo = true;
								}
							}
						}
					}
				});
			});
			this.reiniciarSiguienteSesion();
		},
		reiniciarSiguienteSesion() {
			// eslint-disable-next-line no-console
			// console.log("reiniciarSiguienteSesion", this.$intervalActiveEndPlayVideo);
			this.$clearIntervalActiveEndPlayVideo(this);
			this.divEndSesion = false;
			this.segundos = 8;
		},
		changeSesionSeleccionada(params) {
			// eslint-disable-next-line no-console
			console.log(params);
		},
		async reload() {
			let seconds = Math.floor(parseInt(this.sesionSeleccionada.video_seconds));
			this.reiniciarSiguienteSesion();
			try {
				await this.videoplayer.destroy();
			} catch (error) {}

			this.videoplayer = new this.$Player("videosesiones", {
				id: this.sesionSeleccionada.video_idvideovimeo,
				width: document.querySelector("#containt-video").offsetWidth,
				height:
					(document.querySelector("#containt-video").offsetWidth * 9) / 16,
			});
			this.$nextTick(async () => {
				window.location.hash = this.sesionSeleccionada.video_id;
				window.player = this.videoplayer;
				await this.videoplayer.on("loaded", async () => {
					console.log("loaded reload");
					let tracks = this.videoplayer.getTextTracks();

					if (tracks && tracks.length > 0) {
						this.videoplayer.enableTextTrack("es-419");
					}
					let play = await this.videoplayer.play();
					// eslint-disable-next-line no-console
					console.log(play);
					this.videoplayer.setCurrentTime(seconds);

					this.videoplayer.on("timeupdate", async (timeup) => {
						if (parseInt(timeup.seconds) % 4 == 0) {
							try {
								// console.group('setIntervalCurrentTime getCurrentTime')
								let time = 0;

								time = await this.videoplayer.getCurrentTime();

								let timestring = parseInt(time).toString();

								if (this.sesionSeleccionada.video_seconds != timestring) {
									let ruta = null;

									switch (this.$router.currentRoute.name) {
										case "miruta":
											ruta = "1";
											break;
										case "track":
											ruta = "0";
											break;
										default:
											ruta = "-1";
											break;
									}
									let params = {
										id_video: this.sesionSeleccionada.video_id,
										seconds_video: timestring,
										track_video: this.track.track_id,
										seconds_exac: parseInt(timeup.duration).toString(),
										type_track_ruta: ruta,
									};
									let response = await this.$http.post(
										"/videos/playvideo",
										params
									);

									if (response.data) {
										let track_load = { ...this.track };
										track_load.modulos.forEach((modulo) => {
											modulo.sesiones.forEach((sesion) => {
												if (
													sesion.video_id == this.sesionSeleccionada.video_id
												) {
													sesion.video_seconds = parseInt(time);
													let sesion_load = { ...this.sesion };
													sesion_load.video_seconds = parseInt(time);
													this.sesionSeleccionada.video_seconds =
														sesion_load.video_seconds;
												}
											});
										});
									}
									//   console.log(this.$setIntervalCurrentTime)
								}
								// eslint-disable-next-line no-empty
							} catch (error) {}
						}
					});

					this.videoplayer.on("pause", () => {
						console.log("pause", this.divEndSesion, this.divBotonesPause);
						if (!this.divEndSesion) {
							this.divBotonesPause = true;
						}
					});

					this.videoplayer.on("play", () => {
						this.divBotonesPause = false;
						this.divEndSesion = false;
					});

					this.videoplayer.on("ended", () => {
						console.log("ended", this.divEndSesion, this.divBotonesPause);
						this.activeEndPlayVideo();
					});
				});
			});
		},
		onResize() {
			let div = document.querySelector("#videosesiones");

			// console.log(
			// 	"offsetHeight",
			// 	window.screen.width,
			// 	window.screen.height,
			// 	this.$vuetify.breakpoint.name
			// );

			if (
				this.$vuetify.breakpoint.name == "sm" &&
				window.screen.width / window.screen.height > 1
			) {
				this.widthFrame = (div.offsetWidth * 3) / 5;
				let heightFrame = (this.widthFrame * 16) / 9;
				this.heightFrame = heightFrame;

				try {
					div.querySelector("iframe").setAttribute("width", this.widthFrame);
				} catch (error) {}
				try {
					div.querySelector("iframe").setAttribute("height", heightFrame);
				} catch (error) {}
			} else {
				this.heightFrame = (div.offsetWidth * 9) / 16;
				this.widthFrame = div.offsetWidth;
				try {
					div.querySelector("iframe").setAttribute("width", this.widthFrame);
				} catch (error) {}
				try {
					div.querySelector("iframe").setAttribute("height", this.heightFrame);
				} catch (error) {}
			}
		},
		continuarReproduciendo() {
			this.videoplayer.play();
			this.divBotonesPause = false;
		},
		volverFicha() {
			// console.log(`/curso/${this.track.track_guid}`)
			this.$router.replace(`/curso/${this.track.track_guid}`);
		},
	},
	watch: {
		async sesionSeleccionada(news, old) {
			// async sesion (news, old) {
			// eslint-disable-next-line no-debugger

			// console.log(news, old);
			if (old && news.video_id != old.video_id) {
				// this.sesionSeleccionada = news;
				this.reload();
			}
		},
	},
	async mounted() {
		// this.validarPreguntaExploratoria()

		if (
			this.$router.currentRoute.name == "track" ||
			this.$router.currentRoute.name == "play" ||
			this.$router.currentRoute.name == "miruta"
		) {
			let response = await this.$http.post("/tracks/TracksNiufleexOne", {
				track_guid: this.$route.params.guid,
			});
			console.log("mounted", response);
			if (response && response.data) {
				// let load_modulos = [];

				this.load_modulos = response.data.modulos;
				this.track = response.data;
				// console.log(this.track);
				let hash = this.$route.hash.replace("#", "");
				let mod;

				if (hash.includes("mod")) {
					// eslint-disable-next-line no-console
					console.log(hash.includes("mod"));
					mod = hash.replace("mod-", "");
					let flag = true;
					this.track.modulos.forEach((modulo) => {
						if (mod == modulo.modulo_id) {
							window.sesiones = modulo.sesiones;
							modulo.sesiones.forEach((sesion) => {
								let calculo = parseInt(sesion.video_seconds);
								let duracion = sesion.video_duracion * 60 - 28;
								if (duracion - calculo > 0 && flag) {
									this.sesionSeleccionada = sesion;
									// eslint-disable-next-line no-console
									console.log(this.sesionSeleccionada);
									flag = false;
								}
							});
						}
					});
				} else {
					if (!Number.isNaN(Number.parseInt(hash))) {
						// eslint-disable-next-line no-console
						// console.log(this.$route.hash, this.track, this.track.modulos, hash);
						this.track.modulos.forEach((modulo) => {
							modulo.sesiones.forEach((sesion) => {
								if (hash == sesion.video_id) {
									this.sesionSeleccionada = sesion;
								}
							});
						});
					} else {
						this.sesionSeleccionada = { ...this.track.modulos[0].sesiones[0] };
					}
				}
				console.log(this.track);
				// console.log(this.sesion, this.sesionSeleccionada);
				this.heightTrack = document.querySelector("#heighttrack").offsetHeight;
				document.querySelector("#navegacion-play").style.height =
					document.querySelector("main").offsetHeight;
				document.querySelector("#heighttrack").style.height =
					document.querySelector("main").offsetHeight;

				// console.log(document.querySelector("main").offsetHeight);
				// console.log(this.sesionSeleccionada, this.sesion);
				// let seconds = Math.floor(this.sesionSeleccionada["video_seconds"] || 0);

				// this.videoplayer = new this.$Player("videosesiones", {
				// 	id: this.sesionSeleccionada.video_idvideovimeo,
				// 	width: document.querySelector("#containt-video").offsetWidth,
				// 	height:
				// 		(document.querySelector("#containt-video").offsetWidth * 9) / 16,
				// });

				// this.videoplayer.on("loaded", () => {
				// 	console.log("loaded mounted");
				// 	let tracks = this.videoplayer.getTextTracks();

				// 	if (tracks && tracks.length > 0) {
				// 		this.videoplayer.enableTextTrack("es-419");
				// 	}
				// 	let play = this.videoplayer.play();
				// 	// eslint-disable-next-line no-console
				// 	console.log(play);
				// 	if (seconds) {
				// 		this.videoplayer.setCurrentTime(seconds);
				// 	}
				// 	this.videoplayer.on("timeupdate", async (timeup) => {
				// 		if (parseInt(timeup.seconds) % 4 == 0) {
				// 			try {
				// 				// console.group('setIntervalCurrentTime getCurrentTime')
				// 				let time = 0;

				// 				time = await this.videoplayer.getCurrentTime();

				// 				let timestring = parseInt(time).toString();
				// 				// eslint-disable-next-line no-console
				// 				console.log(
				// 					"Paso",
				// 					time,
				// 					parseInt(parseInt(time) / 60),
				// 					parseInt(time) % 60,
				// 					this.sesionSeleccionada.video_seconds,
				// 					timestring,
				// 					timeup
				// 				);
				// 				let ruta = null;

				// 				switch (this.$router.currentRoute.name) {
				// 					case "miruta":
				// 						ruta = "1";
				// 						break;
				// 					case "track":
				// 						ruta = "0";
				// 						break;
				// 					case "play":
				// 						ruta = "0";
				// 						break;
				// 					default:
				// 						ruta = "-1";
				// 						break;
				// 				}
				// 				let params = {
				// 					id_video: this.sesionSeleccionada.video_id,
				// 					seconds_video: timestring,
				// 					track_video: this.track.track_id,
				// 					seconds_exac: parseInt(timeup.duration).toString(),
				// 					type_track_ruta: ruta,
				// 				};
				// 				console.log(params, this.$router.currentRoute.name);
				// 				if (this.sesionSeleccionada.video_seconds != timestring) {
				// 					let response = await this.$http.post(
				// 						"/videos/playvideo",
				// 						params
				// 					);
				// 					// eslint-disable-next-line no-console
				// 					console.log("response", response.data, timeup.seconds);
				// 					if (response.data) {
				// 						this.sesionSeleccionada.video_seconds = timestring;
				// 					}
				// 					//   console.log(this.$setIntervalCurrentTime)
				// 				}
				// 				// eslint-disable-next-line no-empty
				// 			} catch (error) {}
				// 		}
				// 	});
				// 	this.videoplayer.on("pause", () => {
				// 		console.log("pause", this.divEndSesion, this.divBotonesPause);
				// 		if (!this.divEndSesion) {
				// 			this.divBotonesPause = true;
				// 		}
				// 	});

				// 	this.videoplayer.on("ended", () => {
				// 		console.log("ended", this.divEndSesion, this.divBotonesPause);
				// 		this.activeEndPlayVideo();
				// 	});

				// 	console.log(this)

				// });

				// this.reload();
				if (this.track) {
					let response2 = await this.$http.get(
						`/Modulos/ModuloId/${this.track.modulos[0].modulo_id}`
					);

					this.modulo = response2.data;
					// // eslint-disable-next-line no-console
					// console.group('modulo')
					// // eslint-disable-next-line no-console
					// console.log(
					//   this.modulo,
					//   this.track.track_id,
					//   this.sesionSeleccionada.video_id
					// )
					// // eslint-disable-next-line no-console
					// console.groupEnd('modulo')
				} else {
					this.errorAcceso = true;
					this.mensajeError = "Error de acceso";
				}
			} else {
				this.errorAcceso = true;
				this.mensajeError = "Error de acceso";
			}
		} else {
			this.errorAcceso = true;
			this.mensajeError = "Error de acceso";
		}

		this.$nextTick(() => {
			this.reload();
		});
	},
	async created() {
		this.SET_RUTA(this.$router.currentRoute.name);
		console.log(this.ruta_pathname);
	},
	computed: {
		...mapState(["ruta_pathname", "premium", "theme"]),
		dataUser() {
			return this.$storeNiu.getObjectValue("data");
		},
	},
	components: {
		Modulos,
		Comprarahora,
	},
};
</script>