var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"padding":"0px","position":"fixed","top":"0","overflow-x":"hidden !important","overflow-y":"hidden !important","bottom":"0"},style:({
		backgroundColor: _vm.theme.backCardModulos,
	}),attrs:{"fluid":""}},[(!_vm.premium)?_c('section',{staticStyle:{"background-color":"rgb(39, 36, 41)","padding-top":"0","padding-bottom":"0","margin-top":"0px"}},[_c('Comprarahora')],1):_vm._e(),_c('v-row',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],staticClass:"fill-height",staticStyle:{"margin-top":"12px","margin-left":"0px","margin-right":"0.5px"},attrs:{"id":"heighttrack"}},[_c('v-col',{staticClass:"responsive",staticStyle:{"padding":"0","min-height":"150px","position":"relative"},style:({
				backgroundColor: _vm.theme.backPlayer,
			}),attrs:{"lg":"9","xl":"9","cols":"12","sm":"12","md":"9","id":"containt-video"}},[_c('div',{staticStyle:{"position":"relative","margin":"-1px"}},[_c('div',{ref:"videosesiones",staticClass:"ma-0 pa-0 pt-md-0 pt-lg-0 pt-xl-0 pt-sm-2 pt-1",staticStyle:{"width":"100%","position":"relative","z-index":"0","top":"0"},attrs:{"width":"100%","id":"videosesiones"}}),_c('div',{staticStyle:{"position":"absolute","width":"1%","bottom":"0","left":"0"}},[_c('v-btn',{attrs:{"light":"","icon":""},on:{"click":_vm.reload}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.divEndSesion),expression:"divEndSesion"}],staticStyle:{"position":"absolute","width":"100%","top":"0"},attrs:{"width":"100%","height":(_vm.heightFrame + "px")}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-progress-circular',{attrs:{"rotate":180,"size":100,"width":15,"value":_vm.segundos,"color":"purple","indeterminate":""}},[_vm._v(" "+_vm._s(_vm.segundos)+" ")])],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{on:{"click":_vm.reiniciarSiguienteSesion}},[_vm._v(" Detener ")])],1),_c('v-col',[_c('v-btn',{attrs:{"light":"","color":"primary"},on:{"click":_vm.siguienteSesion}},[_vm._v(" Continuar con la siguiente sesión ")])],1)],1)],1)],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.divBotonesPause && !_vm.divEndSesion),expression:"divBotonesPause && !divEndSesion"}],staticClass:"sobre-video",attrs:{"width":"100%","height":(_vm.heightFrame + "px")},on:{"click":_vm.continuarReproduciendo}},[_c('v-row',{staticClass:"fill-height",style:(_vm.$vuetify.breakpoint.name != 'xs' &&
							_vm.$vuetify.breakpoint.name != 'sm'
								? 'padding: 85px'
								: 'padding: 15px;')},[_c('v-col',{attrs:{"cols":"12"}},[(
									_vm.$vuetify.breakpoint.name != 'xs' &&
									_vm.$vuetify.breakpoint.name != 'sm'
								)?_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('h1',{staticClass:"play-title"},[_vm._v(_vm._s(_vm.track.track_title))]),_c('h2',{staticClass:"play-subtitle"},[_vm._v(" "+_vm._s(_vm.sesionSeleccionada.video_title)+" ")])])],1):_vm._e(),_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.$router.currentRoute.name == 'play')?_c('button',{staticClass:"btn-reproductor",staticStyle:{"display":"block"},on:{"click":_vm.volverFicha}},[_c('span',[_c('v-icon',{staticStyle:{"color":"white"}},[_vm._v(" mdi-arrow-left-thick ")]),_vm._v(" Volver a la ficha del curso ")],1)]):_c('button',{staticClass:"btn-volver",staticStyle:{"display":"block"},on:{"click":_vm.volverFicha}},[_c('span',[_c('v-icon',{staticStyle:{"color":"white"}},[_vm._v(" mdi-arrow-left-thick ")]),_vm._v(" Volver al Carrusel ")],1)]),_c('button',{staticClass:"btn-reproductor",on:{"click":_vm.continuarReproduciendo}},[_c('span',[_c('v-icon',{staticStyle:{"color":"white"}},[_vm._v(" mdi-play ")]),_vm._v(" Continuar Reproduciendo ")],1)])])],1)],1)],1)],1)])]),_c('v-col',{staticClass:"navegacion fadein",style:({
				backgroundColor: ((_vm.theme.backPlayer) + " !important"),
			}),attrs:{"lg":"3","xl":"3","cols":"12","sm":"12","md":"3","id":"navegacion-play"}},[_c('v-banner',{staticStyle:{"margin-top":"15px"}},[_c('img',{staticStyle:{"height":"23.59px","width":"125px"},attrs:{"src":"https://dsm01pap008files.storage.live.com/y4meHSrzhJvABIWJfxS8l97K6Hzc-C9ZN2VlrL0Pkyl0ElyynDnwq8Y9oyG6eD05t9A9iblEPd5GqJiNygQwK2RO2ru0LpSxBeeGpfJXCS2L6H7JZ33IJxUkbdAQ3ABgiiQnfqk4n10KJcsdT_2r9voeKiPO9M2WuOtyVjridPY7C5b0jWDCJzawVoYvk2Q2LPr?width=660&height=167&cropmode=none"}}),_c('img',{staticStyle:{"height":"41.72px","width":"46.98px","margin-left":"10px"},attrs:{"src":_vm.premium
							? 'https://niufleex.com/wp-content/uploads/2020/11/N-premium.png'
							: 'https://niufleex.com/wp-content/uploads/2021/12/Sin-ti%CC%81tulo-1.png'}}),_c('img',{staticStyle:{"height":"41.72px","width":"46.98px","position":"absolute","right":"20px"},attrs:{"src":!_vm.dataUser
							? 'https://niufleex.com/wp-content/uploads/2022/01/user_blanco.png'
							: (_vm.dataUser.genero = 'hombre'
									? 'https://niufleex.com/wp-content/uploads/2022/01/businessman.png'
									: (_vm.dataUser.genero = 'mujer'
											? 'https://niufleex.com/wp-content/uploads/2022/01/businesswoman.png'
											: 'https://niufleex.com/wp-content/uploads/2022/01/user_blanco.png'))}})]),_c('Modulos',{attrs:{"modulos":_vm.load_modulos,"sesionSeleccionada":_vm.sesionSeleccionada},on:{"update:sesionSeleccionada":function($event){_vm.sesionSeleccionada=$event},"update:sesion-seleccionada":function($event){_vm.sesionSeleccionada=$event}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }