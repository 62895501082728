<template>
	<v-container style="padding-top: 20px; height:700px;">
		<v-row>
			<v-col cols="12" style="padding-bottom: 10px">
				<h2
					style="
						color: #3e026e;
						font-family: 'Poppins', Sans-serif;
						font-size: 30px;
						font-weight: 600;
						line-height: 1em;
						letter-spacing: -0.7px;
					"
				>
					{{ cardsCursosFree.titulo }}
				</h2>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" style="padding-top: 0">
				<h2
					style="
						color: #525252;
						font-family: 'Raleway', Sans-serif;
						font-size: 18px;
						font-weight: 500;
						line-height: 1em;
						letter-spacing: 0px;
					"
				>
					{{ cardsCursosFree.subtitulo }}
				</h2>
			</v-col>
		</v-row>
		<v-row style="display: none">
			<v-col clos="12">
				<v-btn
					v-for="p in areas.length"
					:key="p"
					style="margin-left: 0; margin-right: 20px"
					@click="filtroCards2(areas[p - 1], p)"
					:id="'btn-' + p"
					class="btnfiltro1"
					>{{ areas[p - 1] }}
				</v-btn>
				<v-btn
					@click="filtroCards()"
					id="btnTodasCars2Active"
					style="color: #ffffff; background-color: #fb950c"
					class="btnfiltro1"
					>Todas las Areas
				</v-btn>
				<v-btn
					@click="filtroCards()"
					id="btnTodasCars2NoActive"
					style="color: black; background-color: #f3f3f3"
					class="btnfiltro1"
					>Todas las Areas
				</v-btn>
			</v-col>
		</v-row>
		<v-row style="margin-top: 50px;">
			<v-col>
				<v-carousel
					hide-delimiters
					style="box-shadow: 0px 0px; min-height: 750px;height: 750px;"
					class="csscards2MDFree"
					@change="changeSlide"
				>
					<v-carousel-item
						v-for="i in Math.ceil(itemsfilters.length / 4)"
						:key="i"
					>
						<v-layout row>
							<v-flex sm3 pl-2 pr-2 class="rounded">
								<Cards2
									v-if="i * 4 - 4 < itemsfilters.length"
									:path="itemsfilters[i * 4 - 4].src"
									:estudiantes="itemsfilters[i * 4 - 4].estudiantes"
									:puntuacion="itemsfilters[i * 4 - 4].puntuacion"
									:nombre="itemsfilters[i * 4 - 4].nombre"
									:resenia="itemsfilters[i * 4 - 4].resenia"
									:especialista="itemsfilters[i * 4 - 4].especialista"
									:sesiones="itemsfilters[i * 4 - 4].sesiones"
									:minutos="itemsfilters[i * 4 - 4].minutos"
									:link="itemsfilters[i * 4 - 4].link"
									:target="itemsfilters[i * 4 - 4].target"
									:interna="itemsfilters[i * 4 - 4].interna"
									:boton="itemsfilters[i * 4 - 4].boton"
								></Cards2>
							</v-flex>
							<v-flex sm3 pl-2 pr-2>
								<Cards2
									v-if="i * 4 - 3 < itemsfilters.length"
									:path="itemsfilters[i * 4 - 3].src"
									:estudiantes="itemsfilters[i * 4 - 3].estudiantes"
									:puntuacion="itemsfilters[i * 4 - 3].puntuacion"
									:nombre="itemsfilters[i * 4 - 3].nombre"
									:resenia="itemsfilters[i * 4 - 3].resenia"
									:especialista="itemsfilters[i * 4 - 3].especialista"
									:sesiones="itemsfilters[i * 4 - 3].sesiones"
									:minutos="itemsfilters[i * 4 - 3].minutos"
									:link="itemsfilters[i * 4 - 3].link"
									:target="itemsfilters[i * 4 - 3].target"
									:interna="itemsfilters[i * 4 - 3].interna"
									:boton="itemsfilters[i * 4 - 3].boton"
								></Cards2>
							</v-flex>
							<v-flex sm3 pl-2 pr-2>
								<Cards2
									v-if="i * 4 - 2 < itemsfilters.length"
									:path="itemsfilters[i * 4 - 2].src"
									:estudiantes="itemsfilters[i * 4 - 2].estudiantes"
									:puntuacion="itemsfilters[i * 4 - 2].puntuacion"
									:nombre="itemsfilters[i * 4 - 2].nombre"
									:resenia="itemsfilters[i * 4 - 2].resenia"
									:especialista="itemsfilters[i * 4 - 2].especialista"
									:sesiones="itemsfilters[i * 4 - 2].sesiones"
									:minutos="itemsfilters[i * 4 - 2].minutos"
									:link="itemsfilters[i * 4 - 2].link"
									:target="itemsfilters[i * 4 - 2].target"
									:interna="itemsfilters[i * 4 - 2].interna"
									:boton="itemsfilters[i * 4 - 2].boton"
								></Cards2>
							</v-flex>
							<v-flex sm3 pl-2 pr-2>
								<Cards2
									v-if="i * 4 - 1 < itemsfilters.length"
									:path="itemsfilters[i * 4 - 1].src"
									:estudiantes="itemsfilters[i * 4 - 1].estudiantes"
									:puntuacion="itemsfilters[i * 4 - 1].puntuacion"
									:nombre="itemsfilters[i * 4 - 1].nombre"
									:resenia="itemsfilters[i * 4 - 1].resenia"
									:especialista="itemsfilters[i * 4 - 1].especialista"
									:sesiones="itemsfilters[i * 4 - 1].sesiones"
									:minutos="itemsfilters[i * 4 - 1].minutos"
									:link="itemsfilters[i * 4 - 1].link"
									:target="itemsfilters[i * 4 - 1].target"
									:interna="itemsfilters[i * 4 - 1].interna"
									:boton="itemsfilters[i * 4 - 1].boton"
								></Cards2>
							</v-flex>
						</v-layout>
					</v-carousel-item>
				</v-carousel>

				<p
					style="
						margin-top: -280px;
						text-align: right;
						margin-right: 25%;
						color: #525252;
						font-family: 'Poppins', Sans-serif;
						font-size: 18px;
						font-weight: 600;
						line-height: 1.2em;
						letter-spacing: -0.7px;
					"
				>
					{{ slide }} de {{ Math.ceil(itemsfilters.length / 4) }} Cursos
				</p>

				<!-- <v-btn
					style="margin-top: -85px !important; margin-left: 10px !important"
					class="btntodocontenido"
					@click="btnVerContenido_click"
					>{{ this.cardsCursosFree.boton_general }}</v-btn
				> -->
			</v-col>
		</v-row>
	</v-container>
</template>
<style>
.btnfiltro1 {
	font-family: "Raleway", Sans-serif !important;
	font-size: 13px !important;
	font-weight: 600 !important;
	letter-spacing: 0px !important;
	fill: #a2a0a0;
	color: #a2a0a0;
	background-color: #f3f3f3;
	border-style: solid !important;
	border-width: 1px 1px 1px 1px !important;
	border-color: #a2a0a0 !important;
	border-radius: 30px 30px 30px 30px !important;
	padding: 17px 14px 17px 15px !important;
	margin-left: 50px;
}
.btnfiltro1:hover {
	color: #ffffff !important;
	background-color: #fb950c !important;
}
.btnfiltro1active {
	color: #ffffff !important;
	background-color: #fb950c !important;

	font-family: "Raleway", Sans-serif !important;
	font-size: 13px !important;
	font-weight: 600 !important;
	letter-spacing: 0px !important;
	border-style: solid !important;
	border-width: 1px 1px 1px 1px !important;
	border-color: #a2a0a0 !important;
	border-radius: 30px 30px 30px 30px !important;
	padding: 17px 14px 17px 15px !important;
	margin-left: 50px;
}

.btntodocontenido {
	background-color: #fac903 !important;
	font-family: "Raleway", Sans-serif !important;
	font-size: 18px !important;
	font-weight: 800 !important;
	letter-spacing: 0px !important;
	fill: #541983 !important;
	color: #541983 !important;
	border-radius: 5px 5px 5px 5px !important;
	padding: 20px 40px 17px 40px !important;
	height: 55px !important;
}
.btntodocontenido:hover {
	color: #ffffff !important;
	background-color: #8208b1 !important;
}
.csscards2MDFree .v-window__prev {
	margin-top: 100px;
	margin-left: 80%;
	background-color: #f3f3f3 !important;
}
.csscards2MDFree .v-window__next {
	margin-top: 100px;
	margin-right: 8% !important;
	background-color: #f3f3f3 !important;	
}
.csscards2MDFree .v-window__prev .v-btn {
	width: 53px !important;
	height: 53px !important;
	background-color: #f3f3f3 !important;
	border: solid #6a289f !important;
	color: #6a289f !important;
}
.csscards2MDFree .v-window__next .v-btn {
	width: 53px !important;
	height: 53px !important;
	background-color: #f3f3f3 !important;
	border: solid #6a289f !important;
	color: #6a289f !important;
}
.icon.notranslate.mdi.mdi-chevron-left {
	font-size: 36px !important;
	color: #6a289f !important;
	border: 0;
	z-index: 6;
}
.csscards2MDFree .v-responsive__content .layout {
	padding-right: 35px;
	padding-bottom: 20px;
}
.csscards2MDFree .v-carousel__item {
	height: auto !important;
	padding-right: 10px !important;
}
.csscards2MDFree .v-window__next .v-btn span i{
	color:#6a289f !important;
	font-size: 36px !important;
}
.csscards2MDFree .v-window__prev .v-btn span i{
	color:#6a289f !important;
	font-size: 36px !important;
}
</style>
<script>
import Cards2 from "./CardCarruselFree.vue";
export default {
	data() {
		return {
			indice: 0,
			areas: [],
			itemsfilters: [],
			clase: "",
			// nombremetodo: "",
			titulo: "",
			subtitulo: "",

			cardsCursosPremiumHerramientas: {
				titulo: "Cursos PREMIUM sobre Herramientas",
				subtitulo:
					"Especialistas, Mentores y guías. Conoce a quienes te harán subir al próximo nivel.",
				link_general: "https://niufleex.com/",
				target_general: "_blank",
				interna_general: "0",
				boton_general: "ver todo el contenido",
				boton_general_xs: "ver todo",
				totCursos: 10,
				cantVistos: 3,
				items: [
					{
						src: "https://niufleex.com/wp-content/uploads/2022/01/s3.jpg",
						estudiantes: "1.456",
						puntuacion: "4.7",
						nombre: "MÓDULO 1",
						resenia: "La Neta del Planeta es la meta",
						especialista: "Valentina Góngora",
						sesiones: "2",
						minutos: "137",
						area: "EMPRENDIMIENTO Y NEGOCIOS",
						link: "https://niufleex.com/",
						target: "_blank",
						interna: "0",
						boton: "ir al curso",
					},
					{
						src: "https://niufleex.com/wp-content/uploads/2021/12/2.PORTADA-VENTAS-POR-WHATSAPP.png",
						estudiantes: "2.584",
						puntuacion: "3.8",
						nombre: "MÓDULO 4",
						resenia: "El Arte de dejar los vistos",
						especialista: "Valentina Góngora",
						sesiones: "2",
						minutos: "137",
						area: "LIDERAZGO",
						link: "https://niufleex.com/",
						target: "_blank",
						interna: "0",
						boton: "ir al curso",
					},
					{
						src: "https://niufleex.com/wp-content/uploads/2021/12/CLIENTES-POTENCIALES.jpg",
						estudiantes: "842",
						puntuacion: "4.9",
						nombre: "MÓDULO 9",
						resenia: "Funcionalidades de WhatsApp Business",
						especialista: "Valentina Góngora",
						sesiones: "2",
						minutos: "137",
						area: "LIDERAZGO",
						link: "https://niufleex.com/",
						target: "_blank",
						interna: "0",
						boton: "ir al curso",
					},
					{
						src: "https://niufleex.com/wp-content/uploads/2022/01/s3.jpg",
						estudiantes: "1.456",
						puntuacion: "4.7",
						nombre: "MÓDULO 20",
						resenia: "La Neta del Planeta es la meta",
						especialista: "Valentina Góngora",
						sesiones: "2",
						minutos: "137",
						area: "LIDERAZGO",
						link: "https://niufleex.com/",
						target: "_blank",
						interna: "0",
						boton: "ir al curso",
					},
					{
						src: "https://niufleex.com/wp-content/uploads/2021/12/2.PORTADA-VENTAS-POR-WHATSAPP.png",
						estudiantes: "2.584",
						puntuacion: "3.8",
						nombre: "MÓDULO 21",
						resenia: "El Arte de dejar los vistos",
						especialista: "Valentina Góngora",
						sesiones: "2",
						minutos: "137",
						area: "LIDERAZGO",
						link: "https://niufleex.com/",
						target: "_blank",
						interna: "0",
						boton: "ir al curso",
					},
					{
						src: "https://niufleex.com/wp-content/uploads/2021/12/CLIENTES-POTENCIALES.jpg",
						estudiantes: "842",
						puntuacion: "4.9",
						nombre: "MÓDULO 22",
						resenia: "Funcionalidades de WhatsApp Business",
						especialista: "Valentina Góngora",
						sesiones: "2",
						minutos: "137",
						area: "CONOCIMIENTO TÉCNICO",
						link: "https://niufleex.com/",
						target: "_blank",
						interna: "0",
						boton: "ir al curso",
					},
				],
			},
			cardsCursosPremiumNegocios: {
				titulo: "Cursos PREMIUM sobre Negocios",
				subtitulo:
					"Especialistas, Mentores y guías. Conoce a quienes te harán subir al próximo nivel.",
				link_general: "https://niufleex.com/",
				target_general: "_blank",
				interna_general: "0",
				boton_general: "ver todo el contenido",
				boton_general_xs: "ver todo",
				totCursos: 10,
				cantVistos: 3,
				items: [
					{
						src: "https://niufleex.com/wp-content/uploads/2022/01/s3.jpg",
						estudiantes: "1.456",
						puntuacion: "4.7",
						nombre: "MÓDULO 1",
						resenia: "La Neta del Planeta es la meta",
						especialista: "Valentina Góngora",
						sesiones: "2",
						minutos: "137",
						area: "EMPRENDIMIENTO Y NEGOCIOS",
						link: "https://niufleex.com/",
						target: "_blank",
						interna: "0",
						boton: "ir al curso",
					},
					{
						src: "https://niufleex.com/wp-content/uploads/2021/12/2.PORTADA-VENTAS-POR-WHATSAPP.png",
						estudiantes: "2.584",
						puntuacion: "3.8",
						nombre: "MÓDULO 4",
						resenia: "El Arte de dejar los vistos",
						especialista: "Valentina Góngora",
						sesiones: "2",
						minutos: "137",
						area: "LIDERAZGO",
						link: "https://niufleex.com/",
						target: "_blank",
						interna: "0",
						boton: "ir al curso",
					},
					{
						src: "https://niufleex.com/wp-content/uploads/2021/12/CLIENTES-POTENCIALES.jpg",
						estudiantes: "842",
						puntuacion: "4.9",
						nombre: "MÓDULO 9",
						resenia: "Funcionalidades de WhatsApp Business",
						especialista: "Valentina Góngora",
						sesiones: "2",
						minutos: "137",
						area: "LIDERAZGO",
						link: "https://niufleex.com/",
						target: "_blank",
						interna: "0",
						boton: "ir al curso",
					},
					{
						src: "https://niufleex.com/wp-content/uploads/2022/01/s3.jpg",
						estudiantes: "1.456",
						puntuacion: "4.7",
						nombre: "MÓDULO 20",
						resenia: "La Neta del Planeta es la meta",
						especialista: "Valentina Góngora",
						sesiones: "2",
						minutos: "137",
						area: "LIDERAZGO",
						link: "https://niufleex.com/",
						target: "_blank",
						interna: "0",
						boton: "ir al curso",
					},
					{
						src: "https://niufleex.com/wp-content/uploads/2021/12/2.PORTADA-VENTAS-POR-WHATSAPP.png",
						estudiantes: "2.584",
						puntuacion: "3.8",
						nombre: "MÓDULO 21",
						resenia: "El Arte de dejar los vistos",
						especialista: "Valentina Góngora",
						sesiones: "2",
						minutos: "137",
						area: "LIDERAZGO",
						link: "https://niufleex.com/",
						target: "_blank",
						interna: "0",
						boton: "ir al curso",
					},
					{
						src: "https://niufleex.com/wp-content/uploads/2021/12/CLIENTES-POTENCIALES.jpg",
						estudiantes: "842",
						puntuacion: "4.9",
						nombre: "MÓDULO 22",
						resenia: "Funcionalidades de WhatsApp Business",
						especialista: "Valentina Góngora",
						sesiones: "2",
						minutos: "137",
						area: "CONOCIMIENTO TÉCNICO",
						link: "https://niufleex.com/",
						target: "_blank",
						interna: "0",
						boton: "ir al curso",
					},
				],
			},
			slide:0
		};
	},
	components: {
		Cards2,
	},
	props: {
		nombremetodo: {
			type: [Number, String],
			default: "",
		},
		cardsCursosFree: {
			type: Object,
			default: () => {
				return {
					titulo: "Cursos FREE disponibles para ti",
					subtitulo:
						"Especialistas, Mentores y guías. Conoce a quienes te harán subir al próximo nivel.",
					link_general: "https://niufleex.com/",
					target_general: "_blank",
					interna_general: "0",
					boton_general: "ver todo el contenido",
					boton_general_xs: "ver todo",
					totCursos: 10,
					cantVistos: 3,
					items: [
						{
							src: "https://niufleex.com/wp-content/uploads/2022/01/s3.jpg",
							estudiantes: "1.456",
							puntuacion: "4.7",
							nombre: "MÓDULO 1",
							resenia: "La Neta del Planeta es la meta",
							especialista: "Valentina Góngora",
							sesiones: "2",
							minutos: "137",
							area: "EMPRENDIMIENTO Y NEGOCIOS",
							link: "https://niufleex.com/",
							target: "_blank",
							interna: "0",
							boton: "ir al curso",
						},
						{
							src: "https://niufleex.com/wp-content/uploads/2021/12/2.PORTADA-VENTAS-POR-WHATSAPP.png",
							estudiantes: "2.584",
							puntuacion: "3.8",
							nombre: "MÓDULO 4",
							resenia: "El Arte de dejar los vistos",
							especialista: "Valentina Góngora",
							sesiones: "2",
							minutos: "137",
							area: "LIDERAZGO",
							link: "https://niufleex.com/",
							target: "_blank",
							interna: "0",
							boton: "ir al curso",
						},
						{
							src: "https://niufleex.com/wp-content/uploads/2021/12/CLIENTES-POTENCIALES.jpg",
							estudiantes: "842",
							puntuacion: "4.9",
							nombre: "MÓDULO 9",
							resenia: "Funcionalidades de WhatsApp Business",
							especialista: "Valentina Góngora",
							sesiones: "2",
							minutos: "137",
							area: "LIDERAZGO",
							link: "https://niufleex.com/",
							target: "_blank",
							interna: "0",
							boton: "ir al curso",
						},
						{
							src: "https://niufleex.com/wp-content/uploads/2022/01/s3.jpg",
							estudiantes: "1.456",
							puntuacion: "4.7",
							nombre: "MÓDULO 20",
							resenia: "La Neta del Planeta es la meta",
							especialista: "Valentina Góngora",
							sesiones: "2",
							minutos: "137",
							area: "LIDERAZGO",
							link: "https://niufleex.com/",
							target: "_blank",
							interna: "0",
							boton: "ir al curso",
						},
						{
							src: "https://niufleex.com/wp-content/uploads/2021/12/2.PORTADA-VENTAS-POR-WHATSAPP.png",
							estudiantes: "2.584",
							puntuacion: "3.8",
							nombre: "MÓDULO 21",
							resenia: "El Arte de dejar los vistos",
							especialista: "Valentina Góngora",
							sesiones: "2",
							minutos: "137",
							area: "LIDERAZGO",
							link: "https://niufleex.com/",
							target: "_blank",
							interna: "0",
							boton: "ir al curso",
						},
						{
							src: "https://niufleex.com/wp-content/uploads/2021/12/CLIENTES-POTENCIALES.jpg",
							estudiantes: "842",
							puntuacion: "4.9",
							nombre: "MÓDULO 22",
							resenia: "Funcionalidades de WhatsApp Business",
							especialista: "Valentina Góngora",
							sesiones: "2",
							minutos: "137",
							area: "CONOCIMIENTO TÉCNICO",
							link: "https://niufleex.com/",
							target: "_blank",
							interna: "0",
							boton: "ir al curso",
						},
					],
				};
			},
		},
		// titulo: {
		// 	type: [Number, String],
		// 	default: "",
		// },
		// subtitulo: {
		// 	type: [Number, String],
		// 	default: "",
		// },
	},

	methods: {
		changeSlide(event) {
			// eslint-disable-next-line no-console
			console.log(event);
			this.slide=event+1
		},
		contador(i) {
			if (this.indice < i) parseInt(this.indice++);
			else this.indice = 0;
		},
		filtroCards2(nombre, p) {
			this.itemsfilters.splice(0, 100);
			if (nombre == "Todas las Areas") {
				this.cardsCursosFree.items.forEach((element) => {
					this.itemsfilters.push(element);
				});
			} else {
				this.cardsCursosFree.items.forEach((element) => {
					if (element.area == nombre) {
						this.itemsfilters.push(element);
					}
				});
			}
			for (let index = 1; index < this.areas.length + 1; index++) {
				document.getElementById("btn-" + index).style.backgroundColor =
					"#ffffff";
				document.getElementById("btn-" + index).style.color = "black";
			}
			document.getElementById("btn-" + p).style.backgroundColor = "#fb950c";
			document.getElementById("btn-" + p).style.color = "white";
			document.getElementById("btnTodasCars2Active").style.display = "none";
			document.getElementById("btnTodasCars2NoActive").style.display =
				"inline-flex";
		},
		filtroCards() {
			this.itemsfilters.splice(0, 100);

			this.cardsCursosFree.items.forEach((element) => {
				this.itemsfilters.push(element);
			});

			for (let index = 1; index < this.areas.length + 1; index++) {
				document.getElementById("btn-" + index).style.backgroundColor =
					"#ffffff";
				document.getElementById("btn-" + index).style.color = "black";
			}

			document.getElementById("btnTodasCars2Active").style.display =
				"inline-flex";
			document.getElementById("btnTodasCars2NoActive").style.display = "none";
		},
		btnVerContenido_click() {
			let routeData = this.$router.resolve({
				path: this.cardsCursosFree.link_general,
			});
			if (this.cardsCursosFree.interna_general == 1) {
				window.open(routeData.href, this.cardsCursosFree.target_general);
			} else {
				window.open(
					this.cardsCursosFree.link_general,
					this.cardsCursosFree.target_general
				);
			}
		},
	},
	async mounted() {
		/*
    for (let index = 0; index < this.cardsCursosFree.items.length; index++) {
      this.areas.push(this.cardsCursosFree.items[index].area);
    }
   
    this.areas = [...new Set(this.areas)];*/
		switch (this.nombremetodo) {
			case "cardsCursosFree":
				for (
					let index = 0;
					index < this.cardsCursosFree.items.length;
					index++
				) {
					this.itemsfilters.push(this.cardsCursosFree.items[index]);
				}

				// eslint-disable-next-line no-console
				console.log(this.cardsCursosFree)
				// document.getElementById("btnTodasCars2NoActive").style.display = "none";

				break;
			case "cardsCursosPremiumNegocios":
				for (
					let index = 0;
					index < this.cardsCursosPremiumNegocios.items.length;
					index++
				) {
					this.itemsfilters.push(this.cardsCursosPremiumNegocios.items[index]);
					this.titulo = this.cardsCursosPremiumNegocios.titulo;
					this.subtitulo = this.cardsCursosPremiumNegocios.subtitulo;
				}
				document.getElementById("btnTodasCars2NoActive").style.display = "none";
				break;
			case "cardsCursosPremiumHerramientas":
				for (
					let index = 0;
					index < this.cardsCursosPremiumHerramientas.items.length;
					index++
				) {
					this.itemsfilters.push(
						this.cardsCursosPremiumHerramientas.items[index]
					);
				}
				document.getElementById("btnTodasCars2NoActive").style.display = "none";
				this.titulo = this.cardsCursosPremiumHerramientas.titulo;
				this.subtitulo = this.cardsCursosPremiumHerramientas.subtitulo;
				break;
			default:
				break;
		}
	},
};
</script>