<template>
	<div>
		<v-card
			class="mx-auto"
			style="margin-top: 15px"
			:style="{
				backgroundColor: theme.backCardModulos,
			}"
			v-for="(item, i) in modulos"
			:key="i"
		>
			<v-card-title style="padding-bottom: 0px; heigh: 45px">
				<p>
					<span
						style="
							color: #fb950c;

							font-family: Poppins, Sans-serif;
							font-size: 14px;
							font-weight: 600;
							line-height: 1.2em;
							letter-spacing: -0.3px;
							word-break: break-word;
						"
						>Módulo {{ item.modulo_orden }}:</span
					><span
						style="
							text-align: left;
							color: #ffffff;
							font-family: Poppins, Sans-serif;
							font-size: 14px;
							font-weight: 600;
							line-height: 1.2em;
							letter-spacing: -0.3px;
							word-break: break-word;
						"
					>
						{{ item.modulo_title }}</span
					>
				</p>
			</v-card-title>
			<div v-for="(sesionitem, j) in item.sesiones" :key="j">
				<v-card-subtitle
					class="session"
					:class="{
						active: hash() == sesionitem.video_id,
					}"
					@click="btnAccion_click(sesionitem)"
					:id="sesionitem.video_id"
				>
					<i
						class="fas fa-play hijos"
						style="color: white; font-size: 14px"
					></i>
					<strong
						style="
							align-self: center !important;
							font-family: Raleway, Sans-serif !important;
							font-size: 15px !important;
							font-weight: 600 !important;
						"
					>
						<span
							style="
								padding-left: 5px !important;
								color: #fb950c;
								font-family: Raleway, Sans-serif !important;
							"
						>
							Sesión {{ sesionitem.video_orden }}:</span
						>
						<span
							class="hijos"
							style="
								padding-left: 5px !important;
								color: white;
								font-family: Raleway, Sans-serif !important;
								word-break: break-word;
							"
							>{{ sesionitem.video_title }}</span
						>
					</strong>
				</v-card-subtitle>
				<v-card-text>
					<v-row>
						<v-col
							cols="12"
							style="vertical-align: middle; display: inline-flex"
						>
							<a
								href="#"
								v-if="
									!(
										sesionitem.contenido_complementario &&
										sesionitem.contenido_complementario.length > 0
									)
								"
							>
								<span
									class="far fa-arrow-alt-circle-down icon-recursos inactive"
									title="NO SE INCLUYEN RECURSOS"
								>
									<span class="recursos inactive"> &nbsp; RECURSOS</span>
								</span>
							</a>
							<v-menu v-else transition="slide-x-transition" bottom right>
								<template v-slot:activator="{ on, attrs }">
									<v-btn plain v-bind="attrs" v-on="on">
										<span
											class="
												far
												fa-arrow-alt-circle-down
												icon-recursos
												inactive
											"
										>
											<span class="recursos"> &nbsp; RECURSOS</span>
										</span>
									</v-btn>
								</template>

								<v-list>
									<v-list-item
										v-for="(
											resource, index
										) in sesionitem.contenido_complementario"
										:key="'resource' + index"
									>
										<v-list-item-title>
											<v-btn :to="resource.contenido">
												<v-icon v-text="resource.icon"></v-icon>
												{{ resource.name }}
											</v-btn>
											<!-- <v-btn
												v-else-if="resource.interna == 0"
												:href="resource.contenido"
												:target="resource.target"
											>
												<v-icon v-text="resource.icon"></v-icon>
												{{ resource.name }}
											</v-btn> -->
										</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>

							<h2
								v-if="sesionitem.tiempo"
								style="
									color: #908d9a;
									font-family: Raleway, Sans-serif;
									font-size: 16px;
									font-weight: 700;
									line-height: 1em;
									letter-spacing: 0px;
									margin-top: 10px;
									margin-left: 25px;
								"
							>
								{{ sesionitem.tiempo }}
							</h2>
							<h2
								v-if="sesionitem.avance"
								style="
									color: #908d9a;
									font-family: Raleway, Sans-serif;
									font-size: 16px;
									font-weight: 700;
									line-height: 1em;
									letter-spacing: 0px;
									margin-top: 10px;
									margin-left: 25px;
								"
							>
								{{ sesionitem.avance }}
							</h2>
						</v-col>
					</v-row>
				</v-card-text>
			</div>
		</v-card>
	</div>
</template>
<style scoped>
.session {
	color: #fb950c !important;
	cursor: pointer;
}
.session:hover .hijos,
.session.active .hijos {
	color: #fb950c !important;
	cursor: pointer;
}
</style>
<script>
import { mapState } from "vuex";
export default {
	props: {
		modulos: {
			type: Array,
			default: [],
		},
	},
	data: () => ({
		modulosold: [
			{
				nombre: "Nombre del Módulo 1",
				sesiones: [
					{
						nombre:
							"Nombre de la Sesión 1. Espacio para nombres largos Del Modulo 1",
						link: "https://niufleex.com",
						target: "_blank",
						interna: 0,
						Popflex: "Popflex",
						tiempo: "5m 30s",
						avance: "30% avance",
					},
					{
						nombre:
							"Nombre de la Sesión 2. Espacio para nombres largos Del Modulo 1",
						link: "https://niufleex.com",
						target: "_blank",
						interna: 0,
						Popflex: "Popflex",
						tiempo: "5m 30s",
						avance: "30% avance",
					},
					{
						nombre:
							"Nombre de la Sesión 3. Espacio para nombres largos Del Modulo 1",
						link: "https://niufleex.com",
						target: "_blank",
						interna: 0,
						Popflex: "Popflex",
						tiempo: "5m 30s",
						avance: "30% avance",
					},
					{
						nombre:
							"Nombre de la Sesión 4. Espacio para nombres largos Del Modulo 1",
						link: "https://niufleex.com",
						target: "_blank",
						interna: 0,
						Popflex: "Popflex",
						tiempo: "5m 30s",
						avance: "30% avance",
					},
					{
						nombre:
							"Nombre de la Sesión 5. Espacio para nombres largos Del Modulo 1",
						link: "https://niufleex.com",
						target: "_blank",
						interna: 0,
						Popflex: "Popflex",
						tiempo: "5m 30s",
						avance: "30% avance",
					},
				],
			},
			{
				nombre: "Nombre del Módulo 2",
				sesiones: [
					{
						nombre:
							"Nombre de la Sesión 1. Espacio para nombres largos Del Modulo 2",
						link: "https://niufleex.com",
						target: "_blank",
						interna: 0,
						Popflex: "Popflex",
						tiempo: "5m 30s",
						avance: "30% avance",
					},
					{
						nombre:
							"Nombre de la Sesión 2. Espacio para nombres largos Del Modulo 2",
						link: "https://niufleex.com",
						target: "_blank",
						interna: 0,
						Popflex: "Popflex",
						tiempo: "5m 30s",
						avance: "30% avance",
					},
					{
						nombre:
							"Nombre de la Sesión 3. Espacio para nombres largos Del Modulo 2",
						link: "https://niufleex.com",
						target: "_blank",
						interna: 0,
						Popflex: "Popflex",
						tiempo: "5m 30s",
						avance: "30% avance",
					},
					{
						nombre:
							"Nombre de la Sesión 4. Espacio para nombres largos Del Modulo 2",
						link: "https://niufleex.com",
						target: "_blank",
						interna: 0,
						Popflex: "Popflex",
						tiempo: "5m 30s",
						avance: "30% avance",
					},
					{
						nombre:
							"Nombre de la Sesión 5. Espacio para nombres largos Del Modulo 2",
						link: "https://niufleex.com",
						target: "_blank",
						interna: 0,
						Popflex: "Popflex",
						tiempo: "5m 30s",
						avance: "30% avance",
					},
				],
			},
			{
				nombre: "Nombre del Módulo 3",
				sesiones: [
					{
						nombre:
							"Nombre de la Sesión 1. Espacio para nombres largos Del Modulo 3",
						link: "https://niufleex.com",
						target: "_blank",
						interna: 0,
						Popflex: "Popflex",
						tiempo: "5m 30s",
						avance: "30% avance",
					},
					{
						nombre:
							"Nombre de la Sesión 2. Espacio para nombres largos Del Modulo 3",
						link: "https://niufleex.com",
						target: "_blank",
						interna: 0,
						Popflex: "Popflex",
						tiempo: "5m 30s",
						avance: "30% avance",
					},
					{
						nombre:
							"Nombre de la Sesión 3. Espacio para nombres largos Del Modulo 3",
						link: "https://niufleex.com",
						target: "_blank",
						interna: 0,
						Popflex: "Popflex",
						tiempo: "5m 30s",
						avance: "30% avance",
					},
					{
						nombre:
							"Nombre de la Sesión 4. Espacio para nombres largos Del Modulo 3",
						link: "https://niufleex.com",
						target: "_blank",
						interna: 0,
						Popflex: "Popflex",
						tiempo: "5m 30s",
						avance: "30% avance",
					},
					{
						nombre:
							"Nombre de la Sesión 5. Espacio para nombres largos Del Modulo 3",
						link: "https://niufleex.com",
						target: "_blank",
						interna: 0,
						Popflex: "Popflex",
						tiempo: "5m 30s",
						avance: "30% avance",
					},
				],
			},
			{
				nombre: "Nombre del Módulo 4",
				sesiones: [
					{
						nombre:
							"Nombre de la Sesión 1. Espacio para nombres largos Del Modulo 4",
						link: "https://niufleex.com",
						target: "_blank",
						interna: 0,
						Popflex: "Popflex",
						tiempo: "5m 30s",
						avance: "30% avance",
					},
					{
						nombre:
							"Nombre de la Sesión 2. Espacio para nombres largos Del Modulo 4",
						link: "https://niufleex.com",
						target: "_blank",
						interna: 0,
						Popflex: "Popflex",
						tiempo: "5m 30s",
						avance: "30% avance",
					},
					{
						nombre:
							"Nombre de la Sesión 3. Espacio para nombres largos Del Modulo 4",
						link: "https://niufleex.com",
						target: "_blank",
						interna: 0,
						Popflex: "Popflex",
						tiempo: "5m 30s",
						avance: "30% avance",
					},
					{
						nombre:
							"Nombre de la Sesión 4. Espacio para nombres largos Del Modulo 4",
						link: "https://niufleex.com",
						target: "_blank",
						interna: 0,
						Popflex: "Popflex",
						tiempo: "5m 30s",
						avance: "30% avance",
					},
					{
						nombre:
							"Nombre de la Sesión 5. Espacio para nombres largos Del Modulo 4",
						link: "https://niufleex.com",
						target: "_blank",
						interna: 0,
						Popflex: "Popflex",
						tiempo: "5m 30s",
						avance: "30% avance",
					},
				],
			},
			{
				nombre: "Nombre del Módulo 5",
				sesiones: [
					{
						nombre:
							"Nombre de la Sesión 1. Espacio para nombres largos Del Modulo 5",
						link: "https://niufleex.com",
						target: "_blank",
						interna: 0,
						Popflex: "Popflex",
						tiempo: "5m 30s",
						avance: "30% avance",
					},
					{
						nombre:
							"Nombre de la Sesión 2. Espacio para nombres largos Del Modulo 5",
						link: "https://niufleex.com",
						target: "_blank",
						interna: 0,
						Popflex: "Popflex",
						tiempo: "5m 30s",
						avance: "30% avance",
					},
					{
						nombre:
							"Nombre de la Sesión 3. Espacio para nombres largos Del Modulo 5",
						link: "https://niufleex.com",
						target: "_blank",
						interna: 0,
						Popflex: "Popflex",
						tiempo: "5m 30s",
						avance: "30% avance",
					},
					{
						nombre:
							"Nombre de la Sesión 4. Espacio para nombres largos Del Modulo 5",
						link: "https://niufleex.com",
						target: "_blank",
						interna: 0,
						Popflex: "Popflex",
						tiempo: "5m 30s",
						avance: "30% avance",
					},
					{
						nombre:
							"Nombre de la Sesión 5. Espacio para nombres largos Del Modulo 5",
						link: "https://niufleex.com",
						target: "_blank",
						interna: 0,
						Popflex: "Popflex",
						tiempo: "5m 30s",
						avance: "30% avance",
					},
				],
			},
		],
	}),
	created() {
		// eslint-disable-next-line no-console
		// console.log(this.$route.hash, this.$router);
	},
	computed: {
		...mapState(["theme"]),
	},
	methods: {
		hash() {
			return this.$route.hash;
		},
		// eslint-disable-next-line no-unused-vars
		btnAccion_click(video) {
			this.$emit("update:sesionSeleccionada", video);
			// console.log(video)
			window.location.hash = video.video_id;

			let sesiones = document.querySelectorAll(".session.active");
			// eslint-disable-next-line no-console
			console.log(sesiones);

			for (let index = 0; index < sesiones.length; index++) {
				const element = sesiones[index];
				element.classList.remove("active");
			}
			document.getElementById(video.video_id).classList.add("active");
			// eslint-disable-next-line no-console
			console.log(document.getElementById(video.video_id));
			// let routeData = this.$router.resolve({
			// 	path: this.sesionitem.link,
			// });
			// if (this.sesionitem.interna == 1) {
			// 	window.open(routeData.href, this.sesionitem.target);
			// } else {
			// 	window.open(
			// 		this.sesionitem.link,
			// 		this.sesionitem.target
			// 	);
			// }
		},
	},
};
</script>