import { render, staticRenderFns } from "./CarruselCuntinuarReproduciendoMD.vue?vue&type=template&id=1d57d06e&"
import script from "./CarruselCuntinuarReproduciendoMD.vue?vue&type=script&lang=js&"
export * from "./CarruselCuntinuarReproduciendoMD.vue?vue&type=script&lang=js&"
import style0 from "./CarruselCuntinuarReproduciendoMD.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCarousel,VCarouselItem,VCol,VCombobox,VContainer,VFlex,VIcon,VLayout,VRow})
