<template>
  <v-container>
    <v-row>
      <v-col cols="12" style="padding-bottom: 10px">
        <h2
          style="
            font-family: 'Poppins', Sans-serif;
            font-size: 30px;
            font-weight: 600;
            line-height: 1em;
            letter-spacing: -0.7px;
            text-align: center;
          "
          :style="{
            color: theme.title,
          }"
        >
          {{ cardsEspecialistas.titulo }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" style="padding-top: 0">
        <h2
          style="
            color: #525252;
            font-family: 'Raleway', Sans-serif;
            font-size: 18px;
            font-weight: 500;
            line-height: 1em;
            letter-spacing: 0px;
            text-align: center;
          "
        >
          {{ cardsEspecialistas.subtitulo }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-carousel
          hide-delimiters
          style="
            box-shadow: 0px 0px;
            min-height: 600px !important;
            height: 600px !important;
          "
          class="csscardsEspecialistasxs"
        >
          <v-carousel-item
            v-for="(i, indice) in cardsEspecialistas.items.length"
            :key="i"
          >
            <v-layout row>
              <v-flex
                v-for="j in 1"
                :key="j"
                pl-2
                pr-2
                style="padding-left: 10px !important"
              >
                <CardsEspecialistas
                  :nombre="cardsEspecialistas.items[indice].nombre"
                  :facebook="cardsEspecialistas.items[indice].facebook"
                  :instagram="cardsEspecialistas.items[indice].instagram"
                  :especialidad="cardsEspecialistas.items[indice].especialidad"
                  :resenia="cardsEspecialistas.items[indice].resenia"
                  :avatar="cardsEspecialistas.items[indice].avatar"
                  :link="cardsEspecialistas.items[indice].link"
                  :target="cardsEspecialistas.items[indice].target"
                  :interna="cardsEspecialistas.items[indice].interna"
                  :boton="cardsEspecialistas.items[indice].boton"
                  :tiktok="cardsEspecialistas.items[indice].tiktok"
                  :youtube="cardsEspecialistas.items[indice].youtube"
                ></CardsEspecialistas>
              </v-flex>
              <!--<p
                style="
                  margin-top: -155px;
                  text-align: right;
                  margin-right: 30%;
                  color: #525252;
                  font-family: 'Poppins', Sans-serif;
                  font-size: 18px;
                  font-weight: 600;
                  line-height: 1.2em;
                  letter-spacing: -0.7px;
                "
              >
                {{ slide }} de {{ Math.ceil(cardsEspecialistas.length / 4) }}
              </p>-->
            </v-layout>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
  </v-container>
</template>
<style>
.btntodocontenido {
  background-color: #fac903 !important;
  font-family: "Raleway", Sans-serif !important;
  font-size: 18px !important;
  font-weight: 800 !important;
  letter-spacing: 0px !important;
  fill: #541983 !important;
  color: #541983 !important;
  background-color: #fac903 !important;
  border-radius: 5px 5px 5px 5px !important;
  padding: 20px 40px 17px 40px !important;
  height: 55px !important;
}
.btntodocontenido:hover {
  color: #ffffff !important;
  background-color: #8208b1 !important;
}
.csscardsEspecialistasxs .v-window__prev {
  margin-top: 190px !important;
  margin-left: 30% !important;
}
.csscardsEspecialistasxs .v-window__next {
  margin-top: 190px !important;
  margin-right: 30% !important;
}
.csscardsEspecialistasxs .v-window__prev .v-btn {
  font-size: 17px !important;
  padding: 15px !important;
  border-width: 3px 3px 3px 3px !important;
  border-radius: 5px 5px 5px 5px !important;
  color: #272429 !important;
  border-color: #272429 !important;
  height: 53px;
  width: 53px;
  background-color: #f3f3f3;
  border: solid;
}
.csscardsEspecialistasxs .v-window__next .v-btn {
  font-size: 17px !important;
  padding: 15px !important;
  border-width: 3px 3px 3px 3px !important;
  border-radius: 5px 5px 5px 5px !important;
  color: #272429 !important;
  border-color: #272429 !important;
  height: 53px;
  width: 53px;
  background-color: #f3f3f3;
  border: solid;
}
.csscardsEspecialistasxs .v-window__next .v-btn span i {
  color: #272429 !important;
	font-size: 36px !important;  
}
.csscardsEspecialistasxs .v-window__prev .v-btn span i {
  color: #272429 !important;
	font-size: 36px !important;  
}
.csscardsEspecialistasxs .v-responsive__content .layout {
  padding-top: 20px;
}
.csscardsEspecialistasxs .v-carousel__item {
  padding-right: 10px !important;
}
</style>
<script>
import { mapState } from "vuex";
import CardsEspecialistas from "./CardsEspecialistas.vue";
export default {
  data() {
    return {
      indice: 0,
      cardsEspecialistas: {
        titulo: "Nuestros especialistas",
        subtitulo:
          "Especialistas, Mentores y guías. Conoce a quienes te harán subir al próximo nivel.",
        totEspecialistas: 5,
        cant: 3,
        items: [],
      },
      slide: 0,
    };
  },
  methods: {
    changeSlide(event) {
      // eslint-disable-next-line no-console
      this.slide = event + 1;
    },
    contador(i) {
      if (this.indice < i) parseInt(this.indice++);
      else this.indice = 0;
    },
    filtroCards2(nombre, p) {
      this.itemsfilters.splice(0, 100);
      if (nombre == "Todas las Areas") {
        this.cardsEspecialistas.items.forEach((element) => {
          this.itemsfilters.push(element);
        });
      } else {
        this.cardsEspecialistas.items.forEach((element) => {
          if (element.especialidad == nombre) {
            this.itemsfilters.push(element);
          }
        });
      }
      for (let index = 1; index < this.areas2.length + 1; index++) {
        document.getElementById("btnes-" + index).style.backgroundColor =
          "#ffffff";
        document.getElementById("btnes-" + index).style.color = "black";
      }
      document.getElementById("btnes-" + p).style.backgroundColor = "#fb950c";
      document.getElementById("btnes-" + p).style.color = "white";
      document.getElementById("btnTodasEspActiveES").style.display = "none";
      document.getElementById("btnTodasEspNoActiveES").style.display =
        "inline-flex";
    },
    filtroCards() {
      this.itemsfilters.splice(0, 100);

      this.cardsEspecialistas.items.forEach((element) => {
        this.itemsfilters.push(element);
      });

      for (let index = 1; index < this.areas2.length + 1; index++) {
        document.getElementById("btnes-" + index).style.backgroundColor =
          "#ffffff";
        document.getElementById("btnes-" + index).style.color = "black";
      }

      document.getElementById("btnTodasEspActiveES").style.display =
        "inline-flex";
      document.getElementById("btnTodasEspNoActiveES").style.display = "none";
    },
  },
  async mounted() {
    try {
      let result = (await this.$http.get("/especialistas/list")).data;
      this.cardsEspecialistas.items = result;
      // eslint-disable-next-line no-empty
    } catch (error) {}
    for (let index = 0; index < this.cardsEspecialistas.items.length; index++) {
      this.areas2.push(this.cardsEspecialistas.items[index].especialidad);
    }
    this.areas2 = [...new Set(this.areas2)];
    for (let index = 0; index < this.cardsEspecialistas.items.length; index++) {
      this.itemsfilters.push(this.cardsEspecialistas.items[index]);
    }

    document.getElementById("btnTodasEspNoActiveES").style.display = "none";
  },
  computed: {
    ...mapState(["theme"]),
  },
  components: {
    CardsEspecialistas,
  },
};
</script>