<template>
	<v-container
		light
		style="padding-top: 100px"
		v-if="cardsContRep.items.length > 0"
	>
		<v-row justify="center">
			<v-col cols="3" style="text-align: center; padding-right: 0px">
				<v-icon
					style="
						width: 53px !important;
						margin-top: 50px;
						height: 53px !important;
						margin-bottom: 20px !important;
						border-radius: 50% !important;
					"
					:style="{
						backgroundColor: premium
							? '#272429 !important'
							: 'rgb(106 40 159) !important',
						color: premium
							? '#E6DEB5 !important'
							: 'rgb(251, 149, 12) !important',
						border: premium
							? 'solid #E6DEB5 !important'
							: 'solid #fb950c !important',
					}"
					>mdi-bookmark</v-icon
				>
				<h2
					style="
						color: #ffffff !important;
						font-family: 'Poppins', Sans-serif !important;
						font-size: 30px !important;
						font-weight: 600 !important;
						line-height: 1em !important;
						margin-bottom: 20px !important;
						letter-spacing: -0.7px !important;
					"
				>
					Continuar reproduciendo
				</h2>
				<p
					style="
					color: #E6DEB5;
					font-family: Raleway, Sans-serif;
					font-size: 17px;
					font-weight: 500;
					line-height: 1em;
					letter-spacing: 0px;
					"
				>
					Ordenar por
				</p>
				<v-combobox
					v-model="select"
					:items="itemscombo"
					light
					outlined
					dense
					
				></v-combobox>
				<v-btn style="background-color: #E6DEB5;
    color: #3E3E3E; font-weight:700" @click="orderby">VER</v-btn>
			</v-col>
			<v-col cols="9">
				<v-flex>
					<v-carousel
						light
						hide-delimiters
						style="box-shadow: 0px 0px"
						class="csscontinuarpremiummd"
						@change="changeSlide"
						height="600px"
					>
						<v-carousel-item
							light
							v-for="i in Math.ceil(cardsContRep.items.length / 3)"
							:key="i"
							height="600px"
						>
							<v-layout row style="padding: 0 10px; height: 600px">
								<v-flex
									sm4
									pl-2
									pr-2
									mt-5
									v-if="
										i * 3 - 3 < cardsContRep.items.length &&
										cardsContRep.items[i * 3 - 3].src
									"
								>
									<Cards1
										v-if="i * 3 - 3 < cardsContRep.items.length"
										accion="continuar"
										boton="continuar"
										:path="cardsContRep.items[i * 3 - 3].src"
										:estudiantes="cardsContRep.items[i * 3 - 3].estudiantes"
										:puntuacion="cardsContRep.items[i * 3 - 3].puntuacion"
										:nombre="cardsContRep.items[i * 3 - 3].nombre"
										:resenia="cardsContRep.items[i * 3 - 3].resenia"
										:especialista="cardsContRep.items[i * 3 - 3].especialista"
										:sesiones="cardsContRep.items[i * 3 - 3].sesiones"
										:minutos="cardsContRep.items[i * 3 - 3].minutos"
										:video_type_track_ruta="
											cardsContRep.items[i * 3 - 3].video_type_track_ruta
										"
										:trackCode="cardsContRep.items[i * 3 - 3].trackCode"
										:video_id="cardsContRep.items[i * 3 - 3].video_id"
										:area="cardsContRep.items[i * 3 - 3].area"
									></Cards1>
								</v-flex>
								<v-flex
									sm4
									pl-2
									pr-2
									mt-5
									v-if="
										i * 3 - 2 < cardsContRep.items.length &&
										cardsContRep.items[i * 3 - 2].src
									"
								>
									<Cards1
										v-if="i * 3 - 2 < cardsContRep.items.length"
										accion="continuar"
										boton="continuar"
										:path="cardsContRep.items[i * 3 - 2].src"
										:estudiantes="cardsContRep.items[i * 3 - 2].estudiantes"
										:puntuacion="cardsContRep.items[i * 3 - 2].puntuacion"
										:nombre="cardsContRep.items[i * 3 - 2].nombre"
										:resenia="cardsContRep.items[i * 3 - 2].resenia"
										:especialista="cardsContRep.items[i * 3 - 2].especialista"
										:sesiones="cardsContRep.items[i * 3 - 2].sesiones"
										:minutos="cardsContRep.items[i * 3 - 2].minutos"
										:video_type_track_ruta="
											cardsContRep.items[i * 3 - 2].video_type_track_ruta
										"
										:trackCode="cardsContRep.items[i * 3 - 2].trackCode"
										:video_id="cardsContRep.items[i * 3 - 2].video_id"
										:area="cardsContRep.items[i * 3 - 2].area"
									></Cards1>
								</v-flex>
								<v-flex
									sm4
									pl-2
									pr-2
									mt-5
									v-if="
										i * 3 - 1 < cardsContRep.items.length &&
										cardsContRep.items[i * 3 - 1].src
									"
								>
									<Cards1
										v-if="i * 3 - 1 < cardsContRep.items.length"
										accion="continuar"
										boton="continuar"
										:path="cardsContRep.items[i * 3 - 1].src"
										:estudiantes="cardsContRep.items[i * 3 - 1].estudiantes"
										:puntuacion="cardsContRep.items[i * 3 - 1].puntuacion"
										:nombre="cardsContRep.items[i * 3 - 1].nombre"
										:resenia="cardsContRep.items[i * 3 - 1].resenia"
										:especialista="cardsContRep.items[i * 3 - 1].especialista"
										:sesiones="cardsContRep.items[i * 3 - 1].sesiones"
										:minutos="cardsContRep.items[i * 3 - 1].minutos"
										:video_type_track_ruta="
											cardsContRep.items[i * 3 - 1].video_type_track_ruta
										"
										:trackCode="cardsContRep.items[i * 3 - 1].trackCode"
										:video_id="cardsContRep.items[i * 3 - 1].video_id"
										:area="cardsContRep.items[i * 3 - 1].area"
									></Cards1>
								</v-flex>
							</v-layout>
						</v-carousel-item>
					</v-carousel>
					<p
						style="
							margin-top: -125px;
							text-align: right;
							margin-right: 30%;
							color: #E6DEB5 !important;
							font-family: 'Poppins', Sans-serif;
							font-size: 18px;
							font-weight: 600;
							line-height: 1.2em;
							letter-spacing: -0.7px;
						"
					>
						{{ slide }} de {{ Math.ceil(cardsContRep.items.length / 3) }} Módulos pendientes
					</p>
				</v-flex>
			</v-col>
		</v-row>
	</v-container>
</template>
<style>
.v-autocomplete.v-input > .v-input__control > .v-input__slot {
	cursor: text;
	background-color: white;
}
.csscontinuarpremiummd .v-window__prev {
	margin-top: 180px !important;
	margin-left: 75% !important;	
}
.csscontinuarpremiummd .v-window__next {
	margin-top: 180px !important;
	margin-right: 8% !important;
}
.csscontinuarpremiummd .v-window__prev .v-btn {
	font-size: 17px !important;
    padding: 15px !important;
    border-width: 3px 3px 3px 3px !important;
    border-radius: 5px 5px 5px 5px !important;
    color: #E6DEB5 !important;
    border-color: #E6DEB5 !important;
    height: 53px;
    width: 53px;
	background-color: #272429;
    border: solid;
}
.csscontinuarpremiummd .v-window__next .v-btn {
	font-size: 17px !important;
    padding: 15px !important;
    border-width: 3px 3px 3px 3px !important;
    border-radius: 5px 5px 5px 5px !important;
    color: #E6DEB5 !important;
    border-color: #E6DEB5 !important;
    height: 53px;
    width: 53px;
	background-color: #272429;
    border: solid;
}
.csscontinuarpremiummd .v-window__next .v-btn span i{
	color:#E6DEB5 !important;
	font-size: 36px !important;	
}
.csscontinuarpremiummd .v-window__prev .v-btn span i{
	color:#E6DEB5 !important;
	font-size: 36px !important;	
}
.csscontinuarpremiummd .v-responsive__content .layout {
	padding-right: 35px !important;
}
</style>
<script>
import Cards1 from "./CardsContinuarReproduciendo.vue";
// import apiVimeo from "../../../../services/apiVimeo";
import { mapState } from "vuex";
export default {
	data() {
		return {
			sortContinuar: true,
			slide: 0,
			indice: 0,
			select: "Descendente",
			itemscombo: ["Ascendente", "Descendente"],
			cardsContRep: {
				totCurso: "10",
				cantPendientes: "3",
				items: [],
			},
			cardsContRepNew: {
				totCurso: "10",
				cantPendientes: "3",
				items: [
					{
						src: "https://niufleex.com/wp-content/uploads/2022/01/s3.jpg",
						estudiantes: "1.456",
						puntuacion: "4.7",
						nombre: "MÓDULO 1",
						resenia: "La Neta del Planeta es la meta",
						especialista: "Valentina Góngora",
						sesiones: "2",
						minutos: "137",
					},
					{
						src: "https://niufleex.com/wp-content/uploads/2021/12/2.PORTADA-VENTAS-POR-WHATSAPP.png",
						estudiantes: "2.584",
						puntuacion: "3.8",
						nombre: "MÓDULO 4",
						resenia: "El Arte de dejar los vistos",
						especialista: "Valentina Góngora",
						sesiones: "2",
						minutos: "137",
					},
					{
						src: "https://niufleex.com/wp-content/uploads/2021/12/CLIENTES-POTENCIALES.jpg",
						estudiantes: "842",
						puntuacion: "4.9",
						nombre: "MÓDULO 9",
						resenia: "Funcionalidades de WhatsApp Business",
						especialista: "Valentina Góngora",
						sesiones: "2",
						minutos: "137",
					},
					{
						src: "https://niufleex.com/wp-content/uploads/2022/01/s3.jpg",
						estudiantes: "1.456",
						puntuacion: "4.7",
						nombre: "MÓDULO 20",
						resenia: "La Neta del Planeta es la meta",
						especialista: "Valentina Góngora",
						sesiones: "2",
						minutos: "137",
					},
					{
						src: "https://niufleex.com/wp-content/uploads/2021/12/2.PORTADA-VENTAS-POR-WHATSAPP.png",
						estudiantes: "2.584",
						puntuacion: "3.8",
						nombre: "MÓDULO 21",
						resenia: "El Arte de dejar los vistos",
						especialista: "Valentina Góngora",
						sesiones: "2",
						minutos: "137",
					},
					{
						src: "https://niufleex.com/wp-content/uploads/2021/12/2.PORTADA-VENTAS-POR-WHATSAPP.png",
						estudiantes: "2.584",
						puntuacion: "3.8",
						nombre: "MÓDULO 22",
						resenia: "El Arte de dejar los vistos",
						especialista: "Valentina Góngora",
						sesiones: "2",
						minutos: "137",
					},
					{
						src: "https://niufleex.com/wp-content/uploads/2021/12/2.PORTADA-VENTAS-POR-WHATSAPP.png",
						estudiantes: "2.584",
						puntuacion: "3.8",
						nombre: "MÓDULO 23",
						resenia: "El Arte de dejar los vistos",
						especialista: "Valentina Góngora",
						sesiones: "2",
						minutos: "137",
					},
				],
			},
		};
	},
	components: {
		Cards1,
	},
	async mounted() {
		try {
			this.cardsContRep = {
				...this.cardsContRep,
				...(await this.$http.get(`/videos/newlastviews`)).data,
			};
			// eslint-disable-next-line no-empty
		} catch (error) {}
		// for (const element of this.cardsContRep.items) {
		// 	let uno = (await apiVimeo(element.idvideo)).data;
		// 	element.src = uno
		// 		.find((ele) => ele.active == true)
		// 		.sizes.find((ele) => ele.width == 1280).link;
		// }
		// eslint-disable-next-line no-console
		console.log("newlastviews", this.cardsContRep);
		window.cars = this.cardsContRep;
	},
	computed: {
		...mapState(["premium", "theme"]),
	},
	methods: {
		orderby() {
			// eslint-disable-next-line no-console
			console.log(this.select);
			this.sortContinuar = this.select == "Ascendente";
			if (this.sortContinuar) {
				this.cardsContRep.items.sort((a, b) => {
					return Date.parse(a.videos_modified) - Date.parse(b.videos_modified);
				});
				this.sortContinuar = false;
			} else {
				this.cardsContRep.items.sort((a, b) => {
					return Date.parse(b.videos_modified) - Date.parse(a.videos_modified);
				});
				this.sortContinuar = true;
			}
		},
		changeSlide(event) {
			// eslint-disable-next-line no-console
			console.log(event);
			this.slide = event + 1;
		},
		contador() {
			parseInt(this.indice++);
		},
		sort() {
			this.cardsContRep.items = [
				...this.cardsContRep.items.sort(function (a, b) {
					return a.videos_modified - b.videos_modified;
				}),
			];
		},
	},
};
</script>