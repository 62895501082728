<template>
	<v-container>
		<v-row>
			<v-col cols="12" style="margin-top: 50px"></v-col>
		</v-row>
		<v-row style="margin-top: -330px !important">
			<v-col cols="2">
				<v-img
					height="81.98"
					width="81.98"
					class="mx-auto"
					:src="this.cardsPremium.src"
				></v-img>
			</v-col>

			<v-col cols="10" style="padding-left: 0; margin-left: -30px">
				<h2
					style="
						color: #242424;
						font-family: 'Poppins', Sans-serif;
						font-size: 30px;
						font-weight: 600;
						line-height: 1em;
						letter-spacing: -0.7px;
						margin-top: 20px;
					"
				>
					{{ this.cardsPremium.titulo }}
				</h2>
				<h2
					style="
						color: #525252;
						font-family: 'Raleway', Sans-serif;
						font-size: 18px;
						font-weight: 500;
						line-height: 1em;
						letter-spacing: 0px;
					"
				>
					{{ this.cardsPremium.subtitulo }}
				</h2>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-flex>
					<v-carousel
						hide-delimiters
						style="box-shadow: 0px 0px"
						class="csscardsPremiumMD"
						@change="slideChange"
					>
						<v-carousel-item
							v-for="i in Math.ceil(cardsPremium.items.length / 3)"
							:key="i"
						>
							<v-layout row>
								<v-flex sm4 pl-2 pr-2 pt-4>
									<Cards1
										v-if="i * 3 - 3 < cardsPremium.items.length"
										:path="cardsPremium.items[i * 3 - 3].src"
										:nombre="cardsPremium.items[i * 3 - 3].nombre"
										:area="cardsPremium.items[i * 3 - 3].area"
										:especialista="cardsPremium.items[i * 3 - 3].especialista"
										:sesiones="cardsPremium.items[i * 3 - 3].sesiones"
										:minutos="cardsPremium.items[i * 3 - 3].minutos"
										:link="cardsPremium.items[i * 3 - 3].link"
										:target="cardsPremium.items[i * 3 - 3].target"
										:interna="cardsPremium.items[i * 3 - 3].interna"
										:boton="cardsPremium.items[i * 3 - 3].boton"
										:resenia="cardsPremium.items[i * 3 - 3].resenia"
									></Cards1>
								</v-flex>
								<v-flex sm4 pl-2 pr-2 pt-4>
									<Cards1
										v-if="i * 3 - 2 < cardsPremium.items.length"
										:path="cardsPremium.items[i * 3 - 2].src"
										:nombre="cardsPremium.items[i * 3 - 2].nombre"
										:area="cardsPremium.items[i * 3 - 2].area"
										:especialista="cardsPremium.items[i * 3 - 2].especialista"
										:sesiones="cardsPremium.items[i * 3 - 2].sesiones"
										:minutos="cardsPremium.items[i * 3 - 2].minutos"
										:link="cardsPremium.items[i * 3 - 2].link"
										:target="cardsPremium.items[i * 3 - 2].target"
										:interna="cardsPremium.items[i * 3 - 2].interna"
										:boton="cardsPremium.items[i * 3 - 2].boton"
										:resenia="cardsPremium.items[i * 3 - 2].resenia"
									></Cards1>
								</v-flex>
								<v-flex sm4 pl-2 pr-2 pt-4>
									<Cards1
										v-if="i * 3 - 1 < cardsPremium.items.length"
										:path="cardsPremium.items[i * 3 - 1].src"
										:nombre="cardsPremium.items[i * 3 - 1].nombre"
										:area="cardsPremium.items[i * 3 - 1].area"
										:especialista="cardsPremium.items[i * 3 - 1].especialista"
										:sesiones="cardsPremium.items[i * 3 - 1].sesiones"
										:minutos="cardsPremium.items[i * 3 - 1].minutos"
										:link="cardsPremium.items[i * 3 - 1].link"
										:target="cardsPremium.items[i * 3 - 1].target"
										:interna="cardsPremium.items[i * 3 - 1].interna"
										:boton="cardsPremium.items[i * 3 - 1].boton"
										:resenia="cardsPremium.items[i * 3 - 1].resenia"
									></Cards1>
								</v-flex>
							</v-layout>
						</v-carousel-item>
					</v-carousel>
				</v-flex>
				<p
					style="
						margin-top: -62px;
						text-align: right;
						color: rgb(230, 222, 181);
						font-family: Poppins, sans-serif;
						font-size: 18px;
						font-weight: 600;
						line-height: 1.2em;
						margin-left: 64%;
						letter-spacing: -0.7px !important;
						position: absolute;
					"
				>
					{{ slide }} / {{ Math.ceil(cardsPremium.items.length / 3) }}
				</p>
				<!-- <v-btn
          @click="btnCaruselPremium_click"
          style="margin-top: -100px !important; margin-left: 10px !important"
          class="btnPremiumMD"
          >{{ this.cardsPremium.boton }}</v-btn
        > -->
			</v-col>
		</v-row>
	</v-container>
</template>
<style>
.btnPremiumMD {
	font-family: "Raleway", Sans-serif !important;
	font-size: 18px !important;
	font-weight: 800 !important;
	letter-spacing: 0px !important;
	fill: #323232 !important;
	color: #323232 !important;
	background-color: #e6deb5 !important;
	border-radius: 5px 5px 5px 5px !important;
	padding: 20px 40px 17px 40px !important;
	width: 328.91px !important;
	height: 55px !important;
}
.btnPremiumMD:hover {
	color: #ffffff !important;
	background-color: #8208b1 !important;
}
.v-autocomplete.v-input > .v-input__control > .v-input__slot {
	cursor: text;
	background-color: white;
}
.csscardsPremiumMD .v-window__prev {
	margin-top: 190px !important;
	margin-left: 80% !important;
}
.csscardsPremiumMD .v-window__next {
	margin-top: 190px !important;
	margin-right: 7% !important;
	width: 53px !important;
}
.csscardsPremiumMD .v-window__prev .v-btn {
	border-radius: 5px 5px 5px 5px;
	width: 53px !important;
	height: 53px !important;
	background-color: #272429 !important;
	border: solid #e6deb5 !important;
	color: #e6deb5 !important;
}
.csscardsPremiumMD .v-window__next .v-btn {
	border-radius: 5px 5px 5px 5px;
	width: 53px !important;
	height: 53px !important;
	background-color: #272429 !important;
	border: solid #e6deb5 !important;
	color: #e6deb5 !important;
}
.csscardsPremiumMD .v-window__next .v-btn span i{
	color:#e6deb5 !important;
	font-size: 36px !important;	
}
.csscardsPremiumMD .v-window__prev .v-btn span i{
	color:#e6deb5 !important;
	font-size: 36px !important;	
}
.csscardsPremiumMD .v-responsive__content .layout {
	padding-right: 35px !important;
}
</style>
<script>
import Cards1 from "./CardsCarruselPremium.vue";
export default {
	data() {
		return {
			slide: 0,
			indice: 0,
			cardsPremium: {
				src: "http://niufleex.com/wp-content/uploads/2021/04/isotipo.svg",
				titulo: "Últimos Cursos lanzados. Membresía PREMIUM",
				subtitulo:
					"Especialistas, Mentores y guías. Conoce a quienes te harán subir al próximo nivel.",
				link: "https://niufleex.com/",
				target: "_blank",
				interna: "0",
				boton: "Cursos Membresía PREMIUM",
				totCursos: 8,
				cursosVistos: 1,
				items: [],
			},
			itemsExample: [
				{
					src: "https://niufleex.com/wp-content/uploads/2022/01/foto-perfil.jpg",
					estudiantes: "1.456",
					puntuacion: "4.7",
					nombre: "CURSO 10 Cosas a la vez y no perder energía",
					area: "Area de Conocimiento",
					especialista: "Valentina Góngora",
					sesiones: "2",
					minutos: "137",
					link: "https://niufleex.com/",
					target: "_blank",
					interna: 0,
					boton: "¿qué vas a aprender?",
				},
				{
					src: "https://niufleex.com/wp-content/uploads/2021/07/foto-guevara-2.jpg",
					estudiantes: "2.584",
					puntuacion: "3.8",
					nombre: "CURSO Network Marketing para Networkers",
					area: "Area de Conocimiento",
					especialista: "Valentina Góngora",
					sesiones: "2",
					minutos: "137",
					link: "https://niufleex.com/",
					target: "_blank",
					interna: 0,
					boton: "¿qué vas a aprender?",
				},
				{
					src: "https://niufleex.com/wp-content/uploads/2022/01/Juanjo05.jpg",
					estudiantes: "842",
					puntuacion: "4.9",
					nombre: "CURSO Fumarse una cola sin quemarse los dedis",
					area: "Area de Conocimiento",
					especialista: "Valentina Góngora",
					sesiones: "2",
					minutos: "137",
					link: "https://niufleex.com/",
					target: "_blank",
					interna: 0,
					boton: "¿qué vas a aprender?",
				},
				{
					src: "https://niufleex.com/wp-content/uploads/2022/01/foto-perfil.jpg",
					estudiantes: "1.456",
					puntuacion: "4.7",
					nombre: "CURSO 10 Cosas a la vez y no perder energía 2",
					resenia: "La Neta del Planeta es la meta",
					especialista: "Valentina Góngora",
					sesiones: "2",
					minutos: "137",
					link: "https://niufleex.com/",
					target: "_blank",
					interna: 0,
					boton: "¿qué vas a aprender?",
				},
				{
					src: "https://niufleex.com/wp-content/uploads/2021/07/foto-guevara-2.jpg",
					estudiantes: "2.584",
					puntuacion: "3.8",
					nombre: "CURSO Network Marketing para Networkers 2",
					resenia: "El Arte de dejar los vistos",
					especialista: "Valentina Góngora",
					sesiones: "2",
					minutos: "137",
					link: "https://niufleex.com/",
					target: "_blank",
					interna: 0,
					boton: "¿qué vas a aprender?",
				},
				{
					src: "https://niufleex.com/wp-content/uploads/2022/01/Juanjo05.jpg",
					estudiantes: "2.584",
					puntuacion: "3.8",
					nombre: "CURSO Fumarse una cola sin quemarse los dedis 2",
					resenia: "El Arte de dejar los vistos",
					especialista: "Valentina Góngora",
					sesiones: "2",
					minutos: "137",
					link: "https://niufleex.com/",
					target: "_blank",
					interna: 0,
					boton: "¿qué vas a aprender?",
				},
				{
					src: "https://niufleex.com/wp-content/uploads/2022/01/foto-perfil.jpg",
					estudiantes: "2.584",
					puntuacion: "3.8",
					nombre: "MÓDULO 23",
					resenia: "El Arte de dejar los vistos",
					especialista: "Valentina Góngora",
					sesiones: "2",
					minutos: "137",
					link: "https://niufleex.com/",
					target: "_blank",
					interna: 0,
					boton: "¿qué vas a aprender?",
				},
			],
		};
	},
	async mounted() {
		try {
			this.cardsPremium = {
				...this.cardsPremium,
				...(await this.$http.get("/listacursos/ultimoscursos")).data[0],
			};

			// eslint-disable-next-line no-empty
		} catch (error) {}
		// eslint-disable-next-line no-console
		console.log(this.cardsPremium);
	},
	components: {
		Cards1,
	},
	methods: {
		slideChange(event) {
			this.slide = event + 1;
		},
		contador() {
			parseInt(this.indice++);
		},
		btnCaruselPremium_click() {
			let routeData = this.$router.resolve({
				path: this.cardsPremium.link,
			});
			if (this.cardsPremium.interna == 1) {
				window.open(routeData.href, this.cardsPremium.target);
			} else {
				window.open(this.cardsPremium.link, this.cardsPremium.target);
			}
		},
	},
};
</script>