<template>
	<v-card
		elevation="0"
		class="mx-auto"
		max-width="400"
		style="
			text-align: center;
			border-radius: 5px;
			width: 100%;
			max-width: 100%;
			min-height: 400px;
			margin-left: 10px !important;
		"
		light
	>
		<v-avatar style="width: 88px; height: 88px; margin-top: 30px">
			<img :src="avatar" alt="John" />
		</v-avatar>
		<v-card-title style="margin-top: 10px; padding-top: 0">
			<h3 style="width: 100%">
				<span
					style="
						color: #525252;
						font-family: 'Poppins', Sans-serif;
						font-size: 20px;
						font-weight: 600;
						display: block;
					"
					>{{ nombre }}</span
				>
			</h3>
			<h3 style="width: 100%; margin-top: -10px;">
				<span
					style="
						color: #fb950c;
						font-family: 'Poppins', Sans-serif;
						font-size: 15px;
						font-weight: 500;
						margin: 0px 0px 0px 0px;
						align-self: center;
						text-align: center;
						order: 2;
					"
				>
					{{ especialidad }}</span
				>
			</h3>
		</v-card-title>

		<v-card-subtitle style="margin-bottom: 0; padding-bottom: 0">
			<p
				style="
					font-family: 'Raleway', Sans-serif;
					font-size: 15px;
					margin: 10px 0px 0px 0px;
					align-self: center;
					text-align: center;
					order: 3;
				"
			>
				{{ resenia }}
			</p>
		</v-card-subtitle>
		<v-card-text
			align="center"
			justify="center"
			style="position: absolute; bottom: 45px; padding: 0"
		>
			<v-btn-toggle v-model="toggle_exclusive" multiple style="    height: 40px;">
				<a
					v-if="facebook"
					:href="'https://www.facebook.com/' + facebook"
					target="_blank"
					elevation="0"
					style="
						border: none;
						padding: 5px;
						min-width: 0;
						text-decoration: none;
					"
				>
					<v-icon color="#FB950C" style="font-size: 30px">mdi-facebook</v-icon>
				</a>
				<a
					v-if="instagram"
					:href="'https://www.instagram.com/' + instagram"
					target="_blank"
					elevation="0"
					style="
						border: none;
						padding: 5px;
						min-width: 0;
						text-decoration: none;
					"
				>
					<v-icon color="#FB950C" style="font-size: 30px">mdi-instagram</v-icon>
				</a>
				<a
					v-if="youtube"
					:href="youtube"
					target="_blank"
					elevation="0"
					style="
						border: none;
						padding: 5px;
						min-width: 0;
						text-decoration: none;
					"
				>
					<v-icon color="#FB950C" style="font-size: 30px">mdi-youtube</v-icon>
				</a>
				<a
					v-if="tiktok"
					:href="'https://www.tiktok.com/@' + tiktok"
					target="_blank"
					elevation="0"
					style="
						border: none;
						padding: 5px;
						min-width: 0;
						text-decoration: none;
					"
				>
					<tiktok
						:color1="'#FB950C'"
						:color2="'#FB950C'"
						:color3="'#FB950C'"
					></tiktok>
				</a>
			</v-btn-toggle>
		</v-card-text>
		<v-card-actions
			class="text-center"
			style="width: 100%; position: absolute; bottom: -16px"
		>
			<v-btn
				v-if="link"
				@click="btnEspecialistas_click"
				class="btnespecialista"
				style="width: 100%"
			>
				{{ this.boton }}
			</v-btn>
			<v-chip v-else class="labelespecialista">Especialista</v-chip>
		</v-card-actions>
	</v-card>
</template>
<style>
.cardicon {
	font-family: "Raleway", sans-serif !important;
	font-size: 14px !important;
	font-weight: 600 !important;
	letter-spacing: 0.2px !important;
}
.v-icon-left2 {
	margin-right: 5px !important;
}
.h2Cards {
	color: #525252;
	font-family: "Poppins", Sans-serif;
	font-size: 17px;
	font-weight: 600;
	line-height: 1.2em;
	letter-spacing: -0.3px;
}
.btnespecialista,
.labelespecialista {
	text-align: center;
	font-family: "Raleway", Sans-serif !important;
	font-size: 15px !important;
	font-weight: 600 !important;
	letter-spacing: 0px !important;
	fill: #ffffff !important;
	color: #ffffff !important;
	background-color: #fb950c !important;
	border-radius: 5px 5px 5px 5px !important;
	padding: 12px 15px 9px 15px !important;
	width: 188px !important;
	bottom: 8px !important;
	font-family: "Raleway", Sans-serif;
	font-size: 15px;
	font-weight: 600;
	letter-spacing: 0px;
	fill: #ffffff;
	color: #ffffff;
	background-color: #fb950c;
	border-radius: 5px 5px 5px 5px;
	padding: 12px 15px 9px 15px;
	margin: 0 auto;
}
.btnespecialista:hover {
	color: #ffffff;
	background-color: #8208b1 !important;
}
.labelespecialista > span {
	text-align: center;
	margin: 0 auto;
}
</style>
<script>
import tiktok from "../../iconssvg/tiktok.vue";
export default {
	components: {
		tiktok,
	},
	data() {
		return {
			toggle_exclusive: null,
		};
	},
	props: {
		youtube: {
			type: [Number, String],
			default: "",
		},
		tiktok: {
			type: [Number, String],
			default: "",
		},
		facebook: {
			type: [Number, String],
			default: "",
		},
		instagram: {
			type: [Number, String],
			default: "",
		},
		nombre: {
			type: [Number, String],
			default: "",
		},
		especialidad: {
			type: [Number, String],
			default: "",
		},
		resenia: {
			type: [Number, String],
			default: "",
		},
		avatar: {
			type: [Number, String],
			default: "",
		},
		link: {
			type: [Number, String],
			default: "",
		},
		target: {
			type: [Number, String],
			default: "",
		},
		interna: {
			type: [Number, String],
			default: "",
		},
		boton: {
			type: [Number, String],
			default: "",
		},
	},
	methods: {
		btnEspecialistas_click() {
			let routeData = this.$router.resolve({
				path: this.link,
			});
			if (this.interna == 1) {
				window.open(routeData.href, this.target);
			} else {
				window.open(this.link, this.target);
			}
		},
	},
};
</script>